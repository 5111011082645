import { faFolder } from "@fortawesome/free-solid-svg-icons";

import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";

import Panel, { PanelSection } from "components/Panel/Panel";
import TreeExplorer from "components/TreeExplorer/TreeExplorer";

interface Props {
    items: React.ComponentProps<typeof TreeExplorer>["data"];
    selectedIds?: React.ComponentProps<typeof TreeExplorer>["selectedNodes"];
    panelTitle?: string;
    elementClickHandler: React.ComponentProps<
        typeof TreeExplorer
    >["elementClickHandler"];
    pathRoot?: React.ComponentProps<typeof TreeExplorer>["pathRoot"];
}

const CollectionsExplorerPanel = ({
    items,
    selectedIds,
    panelTitle,
    elementClickHandler,
    pathRoot = "/collections",
}: Props) => {
    const { isDesktopScreen } = useScreenSizes();
    return (
        <Panel
            styleOverrides={{
                flex: 1,
                borderRadius: isDesktopScreen ? undefined : "0px !important",
                overflowY: "auto",
            }}
        >
            <PanelSection
                title={panelTitle ?? "Collections"}
                icon={faFolder}
                colorOverride={variables["text-colors-color-text-gray"]}
            >
                <TreeExplorer
                    data={items ?? []}
                    selectedNodes={selectedIds ?? []}
                    pathRoot={pathRoot}
                    elementClickHandler={elementClickHandler}
                />
            </PanelSection>
        </Panel>
    );
};

export default CollectionsExplorerPanel;
