import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useQueryClient } from "@tanstack/react-query";

import { useCurriculumsQuery } from "hooks/api/useCurriculumQuery";
import useGradesQuery from "hooks/api/useGradeQuery";
import {
    useUserCurrentLayerMutation,
    useUserCurrentLayerQuery,
    useUserSettingsQuery,
} from "hooks/api/useUserSettingsQuery";
import useUserQuery from "hooks/api/useUserQuery";
import { useAppContext } from "contexts/AppContext";
import { tracker } from "utils/analytics";
import { isAppMode, isShareMode } from "utils/newtools";

import WelcomeSlides from "./WelcomeSlides/WelcomeSlides";
import Loading from "components/Loading/Loading";
import RadioListSelector from "components/RadioListSelector/RadioListSelector";

const Onboarder = () => {
    const { config } = useAppContext();
    const { status: userSettingsStatus } = useUserSettingsQuery();
    const { data: currentLayers, status: currentLayersStatus } =
        useUserCurrentLayerQuery(isShareMode);
    const userCurrentLayerMutation = useUserCurrentLayerMutation();
    const { data: curriculums, status: curriculumsStatus } =
        useCurriculumsQuery();
    const { data: userData } = useUserQuery();
    const queryClient = useQueryClient();
    const [curriculumId, setCurriculumId] = useState<number | undefined>();
    const { data: grades, status: gradesStatus } = useGradesQuery(curriculumId);
    const [gradeId, setGradeId] = useState<number | undefined>();
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);

    const hasCompletedWelcome = config.completedTours.includes("WELCOME");
    useEffect(() => {
        if (isOnboardingComplete) return;
        if (
            !hasCompletedWelcome ||
            (currentLayersStatus === "success" &&
                Object.keys(currentLayers).length === 0)
        ) {
            setIsDialogVisible(true);
        }
    }, [
        config,
        currentLayers,
        currentLayersStatus,
        hasCompletedWelcome,
        isDialogVisible,
        isOnboardingComplete,
    ]);
    if (!isAppMode || isOnboardingComplete) return <></>;

    let renderComponent;
    if (userSettingsStatus === "success" && !hasCompletedWelcome)
        renderComponent = <WelcomeSlides />;
    else if (currentLayersStatus === "success") {
        if (curriculumsStatus !== "success") return <></>;
        if (!curriculumId && !currentLayers.curriculumId) {
            renderComponent = (
                <RadioListSelector
                    title="Select your curriculum"
                    itemList={curriculums}
                    itemId={curriculumId}
                    setItemId={(value) => {
                        tracker("set default curriculum");
                        setCurriculumId(value);
                        queryClient.setQueryData(
                            ["user-current-layers", userData?.me.id],
                            { ...currentLayers, curriculumId: value },
                        );
                    }}
                />
            );
        } else {
            const foundCurriculum = curriculums.find(
                (el) => el.id === curriculumId,
            );

            if (
                foundCurriculum?.structure.includes("GRADE") &&
                !gradeId &&
                !currentLayers?.gradeId
            ) {
                // Need to request Grade as well

                if (gradesStatus !== "success") renderComponent = <Loading />;
                else
                    renderComponent = (
                        <RadioListSelector
                            title="Select your grade"
                            itemList={grades}
                            itemId={gradeId}
                            setItemId={(value) => {
                                tracker("set default grade");
                                setGradeId(value);
                                queryClient.setQueryData(
                                    ["user-current-layers", userData?.me.id],
                                    { ...currentLayers, gradeId: value },
                                );
                            }}
                        />
                    );
            } else {
                const isGradeTopicQueryStructure =
                    JSON.stringify(foundCurriculum?.structure) ===
                    '["GRADE","TOPIC","QUERY"]';
                const navState = {
                    curriculumId: curriculumId
                        ? curriculumId.toString()
                        : currentLayers.curriculumId,
                    ...(gradeId
                        ? { gradeId: gradeId.toString() }
                        : currentLayers?.gradeId
                          ? { gradeId: currentLayers?.gradeId }
                          : null),
                    ...(isGradeTopicQueryStructure
                        ? { topicListId: gradeId?.toString() }
                        : null),
                };

                userCurrentLayerMutation.mutate(navState);
                tracker("completed onboarding");
                setIsDialogVisible(false);
                setIsOnboardingComplete(true);
                return <></>;
            }
        }
    } else {
        return <></>;
    }

    return (
        <Dialog
            open={isDialogVisible}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        minWidth: "395px",
                        maxWidth: "100%", // Set your width here
                        margin: 0,
                        borderRadius: "15px",
                    },
                },
            }}
        >
            {renderComponent}
        </Dialog>
    );
};

export default Onboarder;
