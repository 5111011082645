import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { AnyElement, Collection, ContainerElement, Folder, Quiz } from "types";
import { isAppMode } from "utils/newtools";

import FolderListViewSkeleton from "./components/ContainerElementListViewSkeleton";
import ListItem from "./components/ListItem/ListItem";
import EmptyFolderMessage from "components/EmptyFolderMessage/EmptyFolderMessage";
import Translate from "components/Translate/Translate";

interface Props {
    elements: AnyElement[];
    containerElements: ContainerElement[];
    collections: Collection[];
    quizzes: Quiz[];
    isFetched: boolean;
    sortBy: Folder["sortBy"];
    elementClickHandler: (element: AnyElement) => void;
    disableContextMenu?: boolean;
}

const ContainerElementListView = ({
    elements,
    containerElements,
    collections,
    quizzes,
    isFetched,
    sortBy,
    elementClickHandler,
    disableContextMenu,
}: Props) => {
    const { isMobileScreen } = useScreenSizes();
    const isEmptyFolder = elements && elements.length === 0;
    return !isFetched ? (
        <FolderListViewSkeleton />
    ) : isEmptyFolder ? (
        <EmptyFolderMessage />
    ) : (
        <TableContainer sx={{ overflowX: isMobileScreen ? "auto" : "hidden" }}>
            <Table
                sx={{
                    ".MuiTableCell-root": {
                        padding: "7px 10px",
                        borderColor:
                            variables["border-colors-color-border-light"],
                    },
                }}
            >
                <TableHead
                    sx={{ ".MuiTableCell-root": { borderBottomWidth: "3px" } }}
                >
                    <TableRow>
                        <TableCell align="center" sx={{ width: "40px" }} />
                        <TableCell>
                            <Typography
                                variant="text-size-Small"
                                color="$text-colors-color-text-gray.main"
                            >
                                <Translate text="Title" />
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                variant="text-size-Small"
                                color="$text-colors-color-text-gray.main"
                            >
                                <Translate text="Type" />
                            </Typography>
                        </TableCell>
                        {isAppMode && (
                            <>
                                <TableCell>
                                    <Typography
                                        variant="text-size-Small"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="Name" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="text-size-Small"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="Date" />
                                    </Typography>
                                </TableCell>
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortBy === "TYPE" ? (
                        <>
                            {containerElements.map((containerElement) => (
                                <ListItem
                                    key={containerElement.id}
                                    element={containerElement}
                                    elementClickHandler={elementClickHandler}
                                    disableContextMenu={disableContextMenu}
                                />
                            ))}
                            {collections.map((collection) => (
                                <ListItem
                                    key={collection.id}
                                    element={collection}
                                    elementClickHandler={elementClickHandler}
                                    disableContextMenu={disableContextMenu}
                                />
                            ))}
                            {quizzes.map((quiz) => (
                                <ListItem
                                    key={quiz.id}
                                    element={quiz}
                                    elementClickHandler={elementClickHandler}
                                    disableContextMenu={disableContextMenu}
                                />
                            ))}
                        </>
                    ) : (
                        elements.map((row: AnyElement) => (
                            <ListItem
                                key={row.id}
                                element={row}
                                elementClickHandler={elementClickHandler}
                                disableContextMenu={disableContextMenu}
                            />
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ContainerElementListView;
