import backgroundPattern from "./background-pattern.webp";

const BackgroundPattern = ({
    colourState,
    height,
}: {
    colourState: "ai" | "neutral" | "right" | "wrong";
    height?: string;
}) => {
    const backgroundColor = {
        ai: "linear-gradient(180deg, #BA29B5 0%, #9B1DE8 100%)",
        neutral: "linear-gradient(153deg, #558FDD 0%, #1C539D 100%)",
        right: "green",
        wrong: "red",
    }[colourState];
    return (
        <div
            style={{
                background: backgroundColor,
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: height ?? "180px",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${backgroundPattern})`,
                    backgroundSize: "10%",
                    opacity: 0.25,
                }}
            ></div>
        </div>
    );
};

export default BackgroundPattern;
