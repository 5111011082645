@use "../../styles/variables.module";

.title {
    color: variables.$text-colors-color-text-dark;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-light-off;
    padding: 10px 20px;
}
