import { useNavigate, useParams } from "react-router-dom";
import { has } from "lodash";
import { faGraduationCap, faListUl } from "@fortawesome/free-solid-svg-icons";
import { Divider } from "@mui/material";

import {
    headerContainer,
    logoContainer,
    buttonsContainer,
} from "./Header.module.scss";
import { useAppContext } from "contexts/AppContext";
import { useUserCurrentLayerQuery } from "hooks/api/useUserSettingsQuery";
import useCurriculumParams from "hooks/useCurriculumParams";
import useScreenSizes from "hooks/useScreenSizes";
import { EmbedLibrary } from "types";
import { tracker } from "utils/analytics";
import { isAppMode, isShareMode } from "utils/newtools";

import UserMenu from "./UserMenu/UserMenu";
import Logo from "components/Logo/Logo";
import HeaderSearchInput from "components/Search/components/SearchInput/HeaderSearchInput";
import TransparentButton from "components/TransparentButton/TransparentButton";

const CurriculumNavigatorHeaderButton = () => {
    const navigate = useNavigate();
    const { data: userCurrentLayer } = useUserCurrentLayerQuery();
    const curriculumId = userCurrentLayer?.curriculumId;
    const gradeId = userCurrentLayer?.gradeId;
    return (
        <TransparentButton
            faIcon={faGraduationCap}
            text={"Curriculum"}
            clickHandler={() => {
                tracker("Curriculum button clicked");
                navigate(
                    `/curriculum/${curriculumId + (gradeId ? "/grade/" + gradeId : "")}`,
                );
            }}
        />
    );
};

const CollectionsHeaderButton = () => {
    const navigate = useNavigate();
    return (
        <TransparentButton
            faIcon={faListUl}
            text={"Collections"}
            clickHandler={() => {
                tracker("Collections button clicked");
                navigate("/collections");
            }}
        />
    );
};

const Header = () => {
    const { isMobileScreen } = useScreenSizes();
    const { shareType } = useParams();
    const { queryId } = useCurriculumParams();
    const { embedDetails } = useAppContext();
    const navigate = useNavigate();
    if (isShareMode && !embedDetails && location.pathname?.includes("embed"))
        return <></>;
    const isEmbedMode = shareType === "embed" || (isShareMode && !shareType); // consortium has old tokens missing shareType but are embeds
    const isMainHeaderEnabled =
        !isEmbedMode ||
        (!embedDetails?.topicId &&
            !embedDetails?.queryId &&
            !embedDetails?.ids) ||
        !location.pathname?.includes("embed");
    const isSearchEnabled =
        isAppMode ||
        (location.pathname?.includes("embed") &&
            has(embedDetails, ["details", "curriculumId"]));
    const handleLogoClick = () => {
        tracker("Home logo clicked");
        if (isShareMode) {
            if (!embedDetails) return; // TODO collection share needs handling
            if (!embedDetails?.topicId && !embedDetails?.queryId) {
                const libraryEmbedDetails = embedDetails as EmbedLibrary;
                navigate(
                    `/library/embed/curriculum/${libraryEmbedDetails.details.curriculumId}`,
                );
            } else if (embedDetails.topicId && queryId) {
                navigate(
                    `/curriculum-share-viewer/${
                        embedDetails?.shareMode +
                        "/topic/" +
                        embedDetails.topicId
                    }`,
                );
            }
        } else {
            navigate("/");
        }
    };
    return (
        <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
            {isMainHeaderEnabled && (
                <div className={headerContainer}>
                    <div className={logoContainer} onClick={handleLogoClick}>
                        <Logo />
                    </div>
                    <div className={buttonsContainer}>
                        {isAppMode && !isMobileScreen && (
                            <>
                                <CurriculumNavigatorHeaderButton />
                                <Divider orientation="vertical" />
                                <CollectionsHeaderButton />
                                <Divider orientation="vertical" />
                            </>
                        )}
                        {isSearchEnabled && <HeaderSearchInput />}
                        {isAppMode && <UserMenu />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;
