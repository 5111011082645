import { Typography } from "@mui/material";

import * as styles from "./QuizEditorDetails.module.scss";
import { useQuizEditorContext } from "../../state/QuizEditorContext";
import { tracker } from "utils/analytics";

import NumberButton from "./components/NumberButton";
import Translate from "components/Translate/Translate";

const NumberOfQuestionsField = () => {
    const { numberOfQuestions, setNumberOfQuestions, isGenerating } =
        useQuizEditorContext();
    return (
        <div className={styles.numberOfQuestions}>
            <Typography
                variant="text-size-Base-bold"
                color="$text-colors-color-text-dark.main"
            >
                <Translate text="Number of questions" />
            </Typography>
            <div className={styles.numberButtons}>
                {new Array(10).fill(null).map((_value, index) => (
                    <NumberButton
                        key={index}
                        value={index + 1}
                        isSelected={index + 1 === numberOfQuestions}
                        clickHandler={() => {
                            const newNumberOfQuestions = index + 1;
                            tracker("Quiz Number of Questions Changed", {
                                newNumberOfQuestions,
                            });
                            setNumberOfQuestions(newNumberOfQuestions);
                        }}
                        disabled={isGenerating}
                    />
                ))}
            </div>
        </div>
    );
};

export default NumberOfQuestionsField;
