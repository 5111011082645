import { useContext, useState } from "react";
import { Dialog } from "@mui/material";

import MessagingContext from "contexts/MessagingContext";
import { useZendeskContext } from "contexts/ZendeskContext";
import { useFullWorkspacesQuery } from "hooks/api/folders";
import { useCreateResourcesCollectionMutation } from "hooks/api/useResourcesCollectionQuery";
import { Collection } from "types";
import { tracker } from "utils/analytics";

import ShareButtonUI from "./ShareButtonUI/ShareButtonUI";
import ShareModal from "./ShareModal/ShareModal";

interface Props {
    hasText: boolean;
    selectedIds: string[];
    queryTitle: string;
}

const ShareQueryButton = ({ hasText, selectedIds, queryTitle }: Props) => {
    const { openAlertMessage } = useContext(MessagingContext);
    const { showZendesk } = useZendeskContext();
    const [createdCollection, setCreatedCollection] = useState<Collection>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { data: fullWorkspacesData } = useFullWorkspacesQuery();
    const userPrivateFolder = fullWorkspacesData?.find(
        (folder) => folder.access === "PRIVATE",
    );
    const createResourcesCollectionMutation =
        useCreateResourcesCollectionMutation();
    const shareHandler = (e: React.MouseEvent) => {
        e.stopPropagation();
        tracker("Share button clicked");
        createResourcesCollectionMutation.mutateAsync(
            {
                ids: selectedIds,
                title: queryTitle,
                parentId: userPrivateFolder?.id,
            },
            {
                onSuccess: (result) => {
                    setCreatedCollection(result.data.collection);
                    setIsModalVisible(true);
                },
                onError: () => {
                    openAlertMessage({
                        message: "Failed to create collection.",
                        open: true,
                        type: "error",
                    });
                    console.error(
                        "Failed to create collection from resources.",
                    );
                },
            },
        );
    };
    return (
        <>
            <ShareButtonUI
                clickHandler={shareHandler}
                hasText={hasText}
                disabled={
                    !selectedIds.length ||
                    createResourcesCollectionMutation.status === "loading"
                }
                isProcessing={
                    createResourcesCollectionMutation.status === "loading"
                }
                isNewDesign
            />
            {createdCollection && (
                <Dialog
                    open={isModalVisible}
                    onClose={() => {
                        setIsModalVisible(false);
                        showZendesk();
                        tracker("Share modal closed");
                    }}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                maxWidth: "100%", // Set your width here
                                margin: 0,
                                borderRadius: "15px",
                            },
                        },
                    }}
                >
                    <ShareModal
                        embedUrl={createdCollection?.embedLink}
                        linkUrl={createdCollection?.shareLink}
                        closeHandler={(e) => {
                            setIsModalVisible(false);
                            showZendesk();
                            tracker("Share modal closed");
                            e.stopPropagation();
                        }}
                        inCollectionEditor
                    />
                </Dialog>
            )}
        </>
    );
};

export default ShareQueryButton;
