import { ChangeEventHandler, CSSProperties } from "react";
import { InputLabel, TextField } from "@mui/material";

import { inputLabel } from "./InputField.module.scss";
import variables from "styles/_variables.module.scss";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface InputProps {
    label?: TranslatableText;
    handleChange?:
        | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
        | undefined;
    fullWidth: boolean;
    name: string;
    value: string | null;
    type: string;
    disabled?: boolean;
    required?: boolean;
    minLength?: number;
    testId?: string;
    containerStyle?: CSSProperties;
}

const InputField = ({ handleChange, ...props }: InputProps) => {
    const changehandler = handleChange; // renaming to lower case to prevent triggering warning
    return (
        <div style={props.containerStyle}>
            {props.label && (
                <InputLabel className={inputLabel}>
                    <Translate text={props.label} />
                </InputLabel>
            )}
            <TextField
                {...props}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                onChange={changehandler}
                label=""
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        background:
                            variables["border-colors-color-border-light"],
                    },
                    background:
                        variables["background-colors-color-background-white"],
                }}
                inputProps={{
                    "data-testid": props.testId,
                    style: {
                        height: 20,
                        padding: 10,
                        fontSize: 14,
                    },
                    minLength: props?.minLength,
                }}
            />
        </div>
    );
};

export default InputField;
