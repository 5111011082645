import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";

import { openContainer, openList } from "./SearchInput.module.scss";
import { tracker } from "utils/analytics";

import SearchInput from "./SearchInput";

interface Props {
    closeHandler: () => void;
}

const FullscreenSearchInput = ({ closeHandler }: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const navigateToSearchWithTerm = (term: string) => {
        if (term) {
            tracker("Performed Search", { searchTerm: term });
            const allParams = Object.fromEntries([...searchParams]);
            if (searchParams.get("queryResult")) delete allParams.queryResult;
            navigate(
                {
                    pathname: "/search",
                    search: createSearchParams({
                        ...allParams,
                        searchTerm: term,
                        subjectFilters: [],
                    }).toString(),
                },
                {
                    state: { searchTerm: term },
                },
            );
            closeHandler();
        }
    };
    return (
        <div className={openContainer} onClick={closeHandler}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={openList} onClick={(e) => e.stopPropagation()}>
                    <SearchInput
                        setSearchTerm={navigateToSearchWithTerm}
                        closeSearch={closeHandler}
                    />
                </div>
            </div>
        </div>
    );
};

export default FullscreenSearchInput;
