import { useContext } from "react";
import dayjs from "dayjs";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { faCircleInfo, faEye, faTag } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./CollectionDetailsPanel.module.scss";
import { CollectionContext } from "../state/CollectionContext";
import { useResultModalContext } from "contexts/ResultModalContext";

import TagList from "./TagList/TagList";
import LabeledSwitch from "components/InputFields/LabeledSwitch/LabeledSwitch";
import Panel, { PanelSection } from "components/Panel/Panel";
import Translate from "components/Translate/Translate";

const CollectionDetailsPanel = () => {
    const { setIsResultModalEnabled } = useResultModalContext();
    const { collection, updateCollection } = useContext(CollectionContext);
    return (
        <Panel
            styleOverrides={{
                flex: 1,
                gap: "30px",
                overflowY: "auto",
            }}
        >
            <PanelSection title="Info" icon={faCircleInfo}>
                <div className={styles.info}>
                    <div>
                        <Typography
                            variant="text-size-Small"
                            color="$text-colors-color-text-gray.main"
                        >
                            <Translate text="Date created" />:{" "}
                        </Typography>
                        <Typography
                            variant="text-size-xSmall"
                            color="$text-colors-color-text-dark.main"
                        >
                            {dayjs(collection.createdDate).format("DD/MM/YY")}
                        </Typography>
                    </div>
                    {"lastModifiedDate" in collection && (
                        <div>
                            <Typography
                                variant="text-size-Small"
                                color="$text-colors-color-text-gray.main"
                            >
                                <Translate text="Date modified" />:{" "}
                            </Typography>
                            <Typography
                                variant="text-size-xSmall"
                                color="$text-colors-color-text-dark.main"
                            >
                                {dayjs(collection.lastModifiedDate).format(
                                    "DD/MM/YY",
                                )}
                            </Typography>
                        </div>
                    )}
                </div>
            </PanelSection>
            <PanelSection title="Labels/Tags" icon={faTag}>
                <TagList />
            </PanelSection>
            <PanelSection title="Appearance" icon={faEye}>
                <div className={styles.appearance}>
                    <LabeledSwitch
                        label="Open resources in the viewer"
                        keyname="resultModal"
                        value={collection.resultModal}
                        updateHandler={() => {
                            updateCollection(
                                "resultModal",
                                !collection.resultModal,
                            );
                            setIsResultModalEnabled(!collection.resultModal);
                        }}
                        tooltip={{
                            text: "If enabled, all resources will be opened within the Wizenoze viewer.",
                            url: new URL(
                                "https://wizenoze.zendesk.com/hc/en-gb/articles/16170659663506-How-to-add-and-remove-content-to-a-collection-",
                            ),
                            placement: "bottom",
                        }}
                    />
                    <FormControl>
                        <Select
                            value={collection.view}
                            onChange={(e) => {
                                updateCollection("view", e.target.value);
                            }}
                            MenuProps={{
                                style: { zIndex: 35001 },
                            }}
                        >
                            <MenuItem value="LIST">List view</MenuItem>
                            <MenuItem value="GRID">Grid view</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </PanelSection>
        </Panel>
    );
};

export default CollectionDetailsPanel;
