@use "../../styles/variables.module";

.headerContainer {
    padding: 10px 40px;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: variables.$background-colors-color-background-light-off;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @media (max-width: variables.$bp-md) {
        padding: 10px;
        height: unset;
    }
}
