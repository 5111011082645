import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppContext } from "contexts/AppContext";
import { useShareElementQuery } from "hooks/api/useShareQuery";
import { Quiz } from "types";

import Loading from "components/Loading/Loading";
import ShareLoadFailed from "components/ShareLoadFailed/ShareLoadFailed";
import QuizTaker from "features/QuizTaker/QuizTaker";

const QuizShareTaker = () => {
    const { config, updateConfig } = useAppContext();
    const { quizId, hash } = useParams();
    const { data: quizData, isFetched: quizIsFetched } = useShareElementQuery({
        id: quizId,
        hash: hash,
    });
    useEffect(() => {
        if (
            !!quizData &&
            quizData.language &&
            config.language !== quizData.language.toLowerCase()
        ) {
            updateConfig("language", quizData.language.toLowerCase());
        }
    }, [quizData, config.language, updateConfig]);
    if (!quizIsFetched) return <Loading />;
    return !quizData ? (
        <ShareLoadFailed />
    ) : (
        <QuizTaker
            quizData={quizData as Quiz}
            isOpen
            closeHandler={() => {
                return null;
            }}
            elementId={quizId}
            elementHash={hash}
            isQuizShare
        />
    );
};

export default QuizShareTaker;
