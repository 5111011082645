import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";

import * as styles from "./CreateLessonPlanTile.module.scss";
import lessonPlanIcon from "./lesson-plan-icon.webp";
import { tracker } from "utils/analytics";

import NotifyMeButton from "../NotifyMeButton/NotifyMeButton";
import Translate from "components/Translate/Translate";
import DashboardTile from "features/DashboardPage/components/DashboardTile/DashboardTile";

const CreateLessonPlanTile = () => {
    const clickHandler = () => {
        tracker("Create a lesson plan tile clicked");
    };
    return (
        <DashboardTile
            headerTitle={{
                text: "Create a lesson plan",
            }}
            headerIcon={{
                icon: faWandMagicSparkles,
                color: "linear-gradient(180deg, #BA29B5 0%, #9B1DE8 100%)",
                backgroundColor: "#ffe3fe",
            }}
            footer={
                <div className={styles.footer}>
                    <Typography
                        variant="text-size-Base"
                        color="$text-colors-color-text-white.main"
                    >
                        <Translate text="Coming soon!" />
                    </Typography>
                    <NotifyMeButton trackerEvent="Create a lesson plan notify me clicked" />
                </div>
            }
            clickHandler={clickHandler}
            styleOverrides={{
                height: "323px",
                justifyContent: "flex-start",
                background: "linear-gradient(180deg, #44ADE8 0%, #9B1DE8 100%)",
                border: "1px solid #9b1dE8)",
            }}
        >
            <div className={styles.container}>
                <img
                    src={lessonPlanIcon}
                    alt="Lesson Plan Icon"
                    style={{ maxWidth: "100%", maxHeight: "120px" }}
                />
            </div>
        </DashboardTile>
    );
};

export default CreateLessonPlanTile;
