import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppContext } from "contexts/AppContext";
import { useShareElementQuery } from "hooks/api/useShareQuery";

import Loading from "components/Loading/Loading";
import ShareLoadFailed from "components/ShareLoadFailed/ShareLoadFailed";
import QuizAvailablePanel from "features/CollectionShareViewer/QuizAvailablePanel/QuizAvailablePanel";

const QuizShareViewer = () => {
    const { config, updateConfig } = useAppContext();
    const { quizId, hash } = useParams();
    const { data: quizData, isFetched: quizIsFetched } = useShareElementQuery({
        id: quizId,
        hash: hash,
    });
    const openQuizHandler = () => {
        window.open(
            new URL(window.location.href).origin + `/quiz/${quizId}/${hash}`,
            "_blank",
        );
    };
    useEffect(() => {
        if (
            !!quizData &&
            quizData.language &&
            config.language !== quizData.language.toLowerCase()
        ) {
            updateConfig("language", quizData.language.toLowerCase());
        }
    }, [quizData, config.language, updateConfig]);
    if (!quizIsFetched) return <Loading />;
    return (
        <div
            style={{
                padding: "30px 10px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}
        >
            {!quizData ? (
                <ShareLoadFailed />
            ) : (
                <QuizAvailablePanel
                    quizTitle={quizData?.title}
                    openHandler={openQuizHandler}
                />
            )}
        </div>
    );
};

export default QuizShareViewer;
