import Grid from "@mui/material/Unstable_Grid2";

import useScreenSizes from "hooks/useScreenSizes";

import { ContentElementCardSkeleton } from "components/ContentElementCard/ContentElementCard";

const ContainerElementGridViewSkeleton = () => {
    const { isMobileScreen } = useScreenSizes();
    return (
        <Grid container spacing={"20px"}>
            <>
                {new Array(isMobileScreen ? 1 : 3)
                    .fill(null)
                    .map((_, index) => (
                        <Grid key={"g" + index} md={4} xs={12}>
                            <ContentElementCardSkeleton />
                        </Grid>
                    ))}
            </>
        </Grid>
    );
};

export default ContainerElementGridViewSkeleton;
