@use "../../../../styles/variables.module";

.questionTypes {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 20px;
}

.numberOfQuestions {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 10px;
}

.numberButtons {
    display: flex;
    background: variables.$background-colors-color-background-light-blue;
    border-radius: 5px;
}

.generateButtonContainer {
    margin: auto -21px -21px -21px;
    padding: 20px;
    width: calc(100% + 42px);
    background: variables.$background-colors-color-background-light;
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 0px 0px 15px 15px;
}

.generateButton {
    padding: 16px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 5px;
}
