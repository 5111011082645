import { useEffect } from "react";
import { Typography } from "@mui/material";

import { useResultModalContext } from "contexts/ResultModalContext";
import { Collection, CollectionItem, CollectionResourceResult } from "types";
import { convertItemTypeToResultType } from "utils/typeConvertors";

import CollectionGridView from "./components/CollectionGridView/CollectionGridView";
import CollectionListView from "./components/CollectionListView/CollectionListView";
import Loading from "components/Loading/Loading";
import Translate from "components/Translate/Translate";

interface Props {
    items: CollectionItem[];
    isFetched?: boolean;
    viewAs: Collection["view"];
    selectedItems?: CollectionItem[];
    addItemHandler?: (item: CollectionItem) => void;
    removeItemHandler?: (item: CollectionItem) => void;
    collectionResultsModalEnabled: boolean;
}

const CollectionViewer = ({
    items,
    isFetched,
    viewAs,
    selectedItems,
    addItemHandler,
    removeItemHandler,
    collectionResultsModalEnabled,
}: Props) => {
    const { setIsResultModalEnabled, resetIsResultModalEnabled } =
        useResultModalContext();
    const results: CollectionResourceResult[] = items.map((item) =>
        convertItemTypeToResultType(item),
    );
    useEffect(() => {
        setIsResultModalEnabled(collectionResultsModalEnabled);
        return () => {
            resetIsResultModalEnabled();
        };
    }, [
        collectionResultsModalEnabled,
        resetIsResultModalEnabled,
        setIsResultModalEnabled,
    ]);
    const addItemByResultId = (resultId: string) => {
        if (!addItemHandler) return;
        const foundItem = items.find((item) => item.indexId === resultId);
        if (foundItem) addItemHandler(foundItem);
    };
    const removeItemByResultId = (resultId: string) => {
        if (!removeItemHandler) return;
        const foundItem = items.find((item) => item.indexId === resultId);
        if (foundItem) removeItemHandler(foundItem);
    };
    return !isFetched ? (
        <Loading />
    ) : items && items.length === 0 ? (
        <Typography variant="text-size-Base">
            <Translate text="The collection is currently empty." />
        </Typography>
    ) : viewAs === "LIST" ? (
        <CollectionListView
            results={results}
            selectedItems={selectedItems}
            addItemHandler={addItemByResultId}
            removeItemHandler={removeItemByResultId}
        />
    ) : (
        <CollectionGridView results={results} />
    );
};

export default CollectionViewer;
