import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

import styles from "./SettingsTab.module.scss";
import { AppConfig, useAppContext } from "contexts/AppContext";
import { useMessagingContext } from "contexts/MessagingContext";
import { useResultModalContext } from "contexts/ResultModalContext";
import useUserQuery from "hooks/api/useUserQuery";
import usePreventNavigation from "hooks/usePreventNavigation";

import DialogFooter from "components/DialogBox/DialogFooter/DialogFooter";
import LabeledNumber from "components/InputFields/LabeledNumber/LabeledNumber";
import LabeledSwitch from "components/InputFields/LabeledSwitch/LabeledSwitch";
import Translate, { TranslatableText } from "components/Translate/Translate";

type Settings = Omit<AppConfig, "pinnedOnly" | "completedTours">;

const Title = ({ text }: { text: TranslatableText }) => (
    <div className={styles.title}>
        <Typography
            variant="text-size-Base-bold"
            color="$text-colors-color-text-dark.main"
        >
            <Translate text={text} />
        </Typography>
    </div>
);

const SettingsTab = ({
    setUnsavedChanges,
    hasUnsavedChanges,
}: {
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    hasUnsavedChanges: boolean;
}) => {
    const { openAlertMessage } = useMessagingContext();
    const { config, updateAllConfig } = useAppContext();
    const { setIsResultModalEnabled } = useResultModalContext();
    const settingsData = useMemo(() => {
        return {
            language: config.language,
            numberOfResults: config.numberOfResults,
            embeddable: config.embeddable,
            showDrafts: config.showDrafts,
            resultModal: config.resultModal,
        };
    }, [config]);
    const [formData, setFormData] = useState<Settings>(settingsData);

    const { data: user } = useUserQuery();
    const userRoles = user?.me?.roles;
    const languages = [
        { value: "en", label: "English" },
        { value: "nl", label: "Nederlands" },
    ];
    const checkAccess =
        !userRoles ||
        userRoles.includes("INTEGRATOR") ||
        userRoles.includes("ALL_CURRICULA_ACCESS");
    const allCurriculaAccess = !!userRoles?.includes("ALL_CURRICULA_ACCESS");

    const handleSelectChange = (e: SelectChangeEvent<"en" | "nl">) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const newConfig = { ...config, ...formData };
        updateAllConfig(newConfig);
        openAlertMessage({
            message: "Your changes have been saved.",
            open: true,
            type: "success",
        });
    };

    const handleChange = (keyName: keyof Settings, value: boolean | number) => {
        setFormData({ ...formData, [keyName]: value });
        if (keyName === "resultModal") {
            setIsResultModalEnabled(!formData?.resultModal);
        }
    };

    const resetForm = () => {
        setFormData(settingsData);
    };

    useEffect(() => {
        setUnsavedChanges(!isEqual(formData, settingsData));
    }, [formData, settingsData, setUnsavedChanges]);
    usePreventNavigation({
        blocked: !isEqual(settingsData, formData),
        messageBody: {
            title: "Changes are not saved",
            body: "The changes you made will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
            type: "Yes, leave the page",
        },
    });
    return (
        <form onSubmit={handleSubmit} className={styles.container}>
            <Title text="General" />
            <div className={styles.select}>
                <Translate text="Language" />
                <Select
                    value={formData?.language || "en"}
                    data-testid="language"
                    className={styles.selectInput}
                    IconComponent={(props) => (
                        <FontAwesomeIcon
                            {...props}
                            className={styles.icon}
                            icon={faAngleDown}
                        />
                    )}
                    sx={{
                        "& .MuiSelect-select": {
                            paddingLeft: "20px",
                        },
                    }}
                    onChange={handleSelectChange}
                    required
                    name="language"
                >
                    {languages.map((lang) => (
                        <MenuItem key={lang.value} value={lang.value}>
                            {lang.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>

            <Title text="Curricula" />
            <div className={styles.section}>
                <LabeledNumber
                    label={"Number of resources (1-5)"}
                    keyname={"numberOfResults"}
                    value={formData?.numberOfResults}
                    minValue={1}
                    maxValue={5}
                    updateHandler={handleChange}
                    isHidden={!checkAccess}
                />
                <LabeledSwitch
                    label={"Open resources within the Wizenoze viewer"}
                    keyname={"resultModal"}
                    value={formData?.resultModal}
                    updateHandler={handleChange}
                    isHidden={!checkAccess}
                />
                <LabeledSwitch
                    label={"Only resources available in the Wizenoze viewer"}
                    keyname={"embeddable"}
                    value={formData?.embeddable}
                    updateHandler={handleChange}
                    disabled={!formData?.resultModal}
                    isHidden={!checkAccess}
                />
            </div>
            {allCurriculaAccess && (
                <>
                    <Title text="Curation" />
                    <div className={styles.section}>
                        <LabeledSwitch
                            label={"Show drafts"}
                            keyname={"showDrafts"}
                            value={formData?.showDrafts}
                            updateHandler={handleChange}
                            isHidden={!allCurriculaAccess}
                        />
                    </div>
                </>
            )}
            <div className={styles.footer}>
                <DialogFooter
                    secondaryButton={{
                        clickHandler: resetForm,
                        label: "Cancel",
                        disabled: !hasUnsavedChanges,
                    }}
                    primaryButton={{
                        label: "Save changes",
                        disabled: !hasUnsavedChanges,
                    }}
                />
            </div>
        </form>
    );
};

export default SettingsTab;
