import {
    faArrowUpFromBracket,
    faMagicWandSparkles,
    faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import leftStepSVG from "./left-step.svg";

import Translate from "components/Translate/Translate";

export const CreationStepsGraphic = () => {
    const IconAndLabel = ({ icon, label }) => (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
            }}
        >
            <FontAwesomeIcon icon={icon} fontSize={20} />
            <Typography
                variant="text-size-Large-bold"
                style={{
                    fontSize: "20px",
                }}
                color="text-colors-color-text-white.main"
            >
                <Translate text={label} />
            </Typography>
        </div>
    );

    return (
        <div
            style={{
                position: "relative",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <img
                src={leftStepSVG}
                alt="Quiz Create Steps"
                style={{
                    maxWidth: "100%",
                }}
            />
            <div
                id="steps-text"
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    maxHeight: "100%",
                    zIndex: 0,
                    color: "white",
                }}
            >
                <IconAndLabel icon={faMagicWandSparkles} label="Create" />
                <IconAndLabel icon={faArrowUpFromBracket} label="Save" />
                <IconAndLabel icon={faPaperPlane} label="Share" />
            </div>
        </div>
    );
};
