import { Fragment } from "react";
import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./ContainerElementGridView.module.scss";
import variables from "styles/_variables.module.scss";
import { AnyElement, Collection, ContainerElement, Folder, Quiz } from "types";

import ContainerElementGridViewSkeleton from "./components/ContainerElementGridViewSkeleton";
import ContainerElementCard from "components/ContainerElementCard/ContainerElementCard";
import ContentElementCard from "components/ContentElementCard/ContentElementCard";
import EmptyFolderMessage from "components/EmptyFolderMessage/EmptyFolderMessage";
import Translate from "components/Translate/Translate";

interface Props {
    elements: AnyElement[];
    containerElements: ContainerElement[];
    collections: Collection[];
    quizzes: Quiz[];
    isFetched: boolean;
    sortBy: Folder["sortBy"];
    elementClickHandler: (element: AnyElement) => void;
    disableContextMenu?: boolean;
}

const ContainerElementGridView = ({
    elements,
    containerElements,
    collections,
    quizzes,
    isFetched,
    sortBy,
    elementClickHandler,
    disableContextMenu,
}: Props) => {
    const isEmptyFolder = elements && elements.length === 0;
    return !isFetched ? (
        <ContainerElementGridViewSkeleton />
    ) : isEmptyFolder ? (
        <EmptyFolderMessage />
    ) : (
        <Grid container spacing={"20px"}>
            {sortBy === "TYPE" ? (
                <>
                    {containerElements.length > 0 && (
                        <Grid xs={12}>
                            <div className={styles.type}>
                                <Typography
                                    variant="text-size-Base-bold"
                                    color="$text-colors-color-text-dark.main"
                                >
                                    <Translate text="Folders" />
                                </Typography>
                            </div>
                            <Grid container spacing={"20px"} padding={"0px"}>
                                {containerElements.map((containerElement) => (
                                    <Grid
                                        key={containerElement.id}
                                        md={4}
                                        xs={12}
                                    >
                                        <ContainerElementCard
                                            containerElement={containerElement}
                                            clickHandler={() =>
                                                elementClickHandler(
                                                    containerElement,
                                                )
                                            }
                                            disableContextMenu={
                                                disableContextMenu
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    {collections.length > 0 && (
                        <Grid xs={12}>
                            <div className={styles.type}>
                                <Typography
                                    variant="text-size-Base-bold"
                                    color="$text-colors-color-text-dark.main"
                                >
                                    <Translate text="Collections" />
                                </Typography>
                            </div>
                            <Grid container spacing={"20px"} padding={"0px"}>
                                {collections.map((collection) => (
                                    <Grid key={collection.id} md={4} xs={12}>
                                        <ContentElementCard
                                            contentElement={collection}
                                            clickHandler={() =>
                                                elementClickHandler(collection)
                                            }
                                            disableContextMenu={
                                                disableContextMenu
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    {quizzes.length > 0 && (
                        <Grid xs={12}>
                            <div className={styles.type}>
                                <Typography
                                    variant="text-size-Base-bold"
                                    color="$text-colors-color-text-dark.main"
                                >
                                    <Translate text="Quizzes" />
                                </Typography>
                            </div>
                            <Grid container spacing={"20px"} padding={"0px"}>
                                {quizzes.map((quiz) => (
                                    <Grid key={quiz.id} md={4} xs={12}>
                                        <ContentElementCard
                                            contentElement={quiz}
                                            clickHandler={() =>
                                                elementClickHandler(quiz)
                                            }
                                            disableContextMenu={
                                                disableContextMenu
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    {elements.map((element, index) => (
                        <Fragment key={"fragment" + element.id}>
                            {index !== 0 && index % 3 === 0 && (
                                <Grid xs={12} key={"divider" + index}>
                                    <Divider
                                        orientation="horizontal"
                                        sx={{
                                            height: "0px",
                                            borderColor:
                                                variables[
                                                    "border-colors-color-border-light"
                                                ],
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid
                                key={element.type + element.id}
                                md={4}
                                xs={12}
                            >
                                {element.type === "WORKSPACE" ||
                                element.type === "FOLDER" ? (
                                    <ContainerElementCard
                                        containerElement={element}
                                        clickHandler={() =>
                                            elementClickHandler(element)
                                        }
                                        disableContextMenu={disableContextMenu}
                                    />
                                ) : element.type === "COLLECTION" ||
                                  element.type === "QUIZ" ? (
                                    <ContentElementCard
                                        contentElement={element}
                                        clickHandler={() =>
                                            elementClickHandler(element)
                                        }
                                        disableContextMenu={disableContextMenu}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Fragment>
                    ))}
                </>
            )}
        </Grid>
    );
};

export default ContainerElementGridView;
