import { useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./SearchTile.module.scss";
import searchBackground from "./search-card-bckg.webp";
import searchIcon from "./search-icon.webp";
import { tracker } from "utils/analytics";

import FullscreenSearchInput from "components/Search/components/SearchInput/FullscreenSearchInput";
import DashboardTile from "features/DashboardPage/components/DashboardTile/DashboardTile";

const SearchTile = () => {
    const [isOpen, setIsOpen] = useState(false);
    const clickHandler = () => {
        tracker("Search tile clicked");
        setIsOpen(true);
    };
    const closeSearch = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        tracker("Search bar closed");
        e?.stopPropagation();
        setIsOpen(false);
    };
    return (
        <DashboardTile
            headerTitle={{
                text: "Search",
            }}
            headerIcon={{
                icon: faMagnifyingGlass,
                color: "linear-gradient(180deg, #69BFAC 0%, #368271 100%)",
                backgroundColor: "#e1f2ee",
            }}
            footer="Find teacher-validated resources"
            clickHandler={clickHandler}
            styleOverrides={{
                justifyContent: "flex-start",
                background: `url(${searchBackground}) lightgray 50% / cover no-repeat`,
            }}
        >
            <>
                <div className={styles.container}>
                    <img
                        src={searchIcon}
                        alt="Search Icon"
                        style={{ maxWidth: "100%", maxHeight: "120px" }}
                    />
                </div>
                {isOpen && <FullscreenSearchInput closeHandler={closeSearch} />}
            </>
        </DashboardTile>
    );
};

export default SearchTile;
