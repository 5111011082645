import { useNavigate } from "react-router-dom";
import {
    faArrowRight,
    faLayerGroup,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./CollectionsTile.module.scss";
import { useCollectionsPanelQuery } from "hooks/api/collections";
import { useFullWorkspacesQuery } from "hooks/api/folders";
import useScreenSizes from "hooks/useScreenSizes";
import { ContentElement } from "types";
import { tracker } from "utils/analytics";

import CollectionsPanelSkeleton from "./components/CollectionsTileSkeleton/CollectionsTileSkeleton";
import NoContentElementsMessage from "./components/NoContentElementsMessage/NoContentElementsMessage";
import ContentElementCard from "components/ContentElementCard/ContentElementCard";
import Translate from "components/Translate/Translate";
import DashboardTile from "features/DashboardPage/components/DashboardTile/DashboardTile";

const CollectionsTile = () => {
    const { isDesktopScreen, isTabletScreen } = useScreenSizes();
    const navigate = useNavigate();
    const { data: collectionsPanelData, isFetched: collectionsPanelIsFetched } =
        useCollectionsPanelQuery();
    const noContentElements = !collectionsPanelData?.results?.length;
    const clickHandler = () => {
        tracker("Collections tile clicked");
        navigate("/collections");
    };
    const elementClickHandler = (element: ContentElement) => {
        tracker("Element clicked", element);
        navigate(`/collections/${element.pathIds.join("/")}`);
    };
    const { data: fullWorkspacesData } = useFullWorkspacesQuery();
    const userPrivateWorkspace = fullWorkspacesData?.find(
        (workspace) => workspace.access === "PRIVATE",
    );
    const createCollectionClickHandler = () => {
        tracker("Create a collection button clicked");
        navigate("/create-collection", {
            state: { collectionsParams: [userPrivateWorkspace?.id] },
        });
    };
    return (
        <DashboardTile
            headerTitle={{
                text: "Collections",
                color: "$text-colors-color-text-dark.main",
            }}
            headerIcon={{
                icon: faLayerGroup,
                color: "linear-gradient(180deg, #619EF3 0%, #1F63C0 100%)",
                backgroundColor: "#bfd9fb",
            }}
            footer={
                <div
                    className={styles.footer}
                    style={{
                        justifyContent: noContentElements
                            ? "center"
                            : "space-between",
                    }}
                >
                    {collectionsPanelIsFetched && (
                        <>
                            <Button
                                variant="contained"
                                className={styles.footerButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    createCollectionClickHandler();
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                <Typography variant="text-size-xSmall-bold">
                                    <Translate text="Create a collection" />
                                </Typography>
                            </Button>
                            {!noContentElements && (
                                <div className={styles.footerText}>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        fontSize={12}
                                    />
                                    <Typography
                                        variant={"text-size-xSmall-bold"}
                                    >
                                        <Translate text="View all" />
                                    </Typography>
                                </div>
                            )}
                        </>
                    )}
                </div>
            }
            clickHandler={clickHandler}
        >
            {!collectionsPanelIsFetched ? (
                <Grid
                    container
                    spacing={"20px"}
                    style={{ alignSelf: "stretch" }}
                >
                    <CollectionsPanelSkeleton />
                </Grid>
            ) : noContentElements ? (
                <NoContentElementsMessage />
            ) : (
                <Grid
                    container
                    spacing={"20px"}
                    style={{ alignSelf: "stretch" }}
                >
                    {collectionsPanelData?.results
                        .slice(0, isDesktopScreen ? 3 : isTabletScreen ? 2 : 1)
                        .map((element) => (
                            <Grid
                                key={"element" + element.id}
                                lg={4}
                                md={6}
                                xs={12}
                            >
                                <ContentElementCard
                                    contentElement={element}
                                    clickHandler={(
                                        e?: React.MouseEvent<
                                            HTMLDivElement,
                                            MouseEvent
                                        >,
                                    ) => {
                                        e?.stopPropagation();
                                        elementClickHandler(element);
                                    }}
                                    disableContextMenu
                                />
                            </Grid>
                        ))}
                </Grid>
            )}
        </DashboardTile>
    );
};

export default CollectionsTile;
