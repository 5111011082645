import _ from "lodash";

import { Collection, ContainerElement, Folder, Quiz } from "types";

import ContainerElementGridView from "components/ContainerElementViewer/components/ContainerElementGridView/ContainerElementGridView";
import ContainerElementListView from "components/ContainerElementViewer/components/ContainerElementListView/ContainerElementListView";

const ContainerElementViewer = ({
    viewAs,
    ...props
}: Omit<
    React.ComponentProps<typeof ContainerElementListView>,
    "containerElements" | "collections" | "quizzes"
> & {
    viewAs: Folder["view"];
}) => {
    const partition: {
        containerElements: ContainerElement[];
        collections: Collection[];
        quizzes: Quiz[];
    } = {
        containerElements: [],
        collections: [],
        quizzes: [],
    };
    props.elements.forEach((element) => {
        if (element.type === "WORKSPACE" || element.type === "FOLDER") {
            partition.containerElements.push(element);
        } else if (element.type === "COLLECTION") {
            partition.collections.push(element);
        } else if (element.type === "QUIZ") {
            partition.quizzes.push(element);
        } else {
            throw new Error(
                "Element type doesnt exist in partition: " + element.type,
            );
        }
    });
    return viewAs === "LIST" ? (
        <ContainerElementListView
            {...props}
            containerElements={partition.containerElements}
            collections={partition.collections}
            quizzes={partition.quizzes}
        />
    ) : (
        <ContainerElementGridView
            {...props}
            containerElements={partition.containerElements}
            collections={partition.collections}
            quizzes={partition.quizzes}
        />
    );
};

export default ContainerElementViewer;
