@use "../../../../styles/variables.module";

.container {
    min-height: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    background: variables.$background-colors-color-background-white;
    border-radius: 15px;
    border: 1px solid variables.$border-colors-color-border-primary;
    cursor: pointer;
    overflow: hidden;
}

.header {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
}

.iconCircle {
    padding: 2px 8px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 50%;
}

.icon {
    width: 28px;
    height: 28px;
}

.body {
    padding: 0px 15px;
}

.footer {
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
