import { useState } from "react";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./QuizShareButtonWrapper.module.scss";
import useScreenSizes from "hooks/useScreenSizes";

import ShareElementDialog from "components/ContextMenu/dialogs/ShareElementDialog";
import Translate from "components/Translate/Translate";
import { useQuizEditorContext } from "features/QuizEditor/state/QuizEditorContext";

const QuizShareButtonWrapper = () => {
    const { isDesktopScreen } = useScreenSizes();
    const { quizData } = useQuizEditorContext();
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    return (
        <>
            <Button
                variant="outlined"
                className={styles.shareButton}
                onClick={() => setIsShareModalOpen(true)}
            >
                <FontAwesomeIcon icon={faShare} fontSize={12} />
                {isDesktopScreen && (
                    <Typography variant="text-size-xSmall-bold">
                        <Translate text="Share" />
                    </Typography>
                )}
            </Button>
            <ShareElementDialog
                element={quizData}
                isVisible={isShareModalOpen}
                setIsVisible={setIsShareModalOpen}
            />
        </>
    );
};

export default QuizShareButtonWrapper;
