import {
    createContext,
    ReactNode,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { useAppContext } from "contexts/AppContext";
import { useMessagingContext } from "contexts/MessagingContext";
import {
    useCreateQuizMutation,
    useDeleteQuizMutation,
    useGenerateQuestionsMutation,
    useQuizQuery,
    useSaveQuizMutation,
} from "hooks/api/quizzes";
import usePreventNavigation from "hooks/usePreventNavigation";
import { Question, Quiz, SuggestedSource } from "types";
import { tracker } from "utils/analytics";

import Loading from "components/Loading/Loading";
import CollectionNavBar from "components/NavBars/CollectionNavBar";
import { translate } from "components/Translate/Translate";

type QuizEditorContextType = {
    quizData: Quiz;
    title: string;
    setTitle: (_value: string) => void;
    topic: string;
    setTopic: (_value: string) => void;
    suggestedSource: SuggestedSource;
    setSuggestedSource: (_value: SuggestedSource | null) => void;
    multipleChoice: boolean;
    setMultipleChoice: (_value: boolean) => void;
    trueOrFalse: boolean;
    setTrueOrFalse: (_value: boolean) => void;
    numberOfQuestions: number;
    setNumberOfQuestions: (_value: number) => void;
    questions: Array<Question>;
    setQuestions: (_value: Array<Question>) => void;
    generateQuestionsEnabled: boolean;
    isGenerating: boolean;
    generateQuestions: () => void;
    isSaving: boolean;
    saveHandler: () => void;
    deleteHandler: () => void;
    showQuizDetails: boolean;
    setShowQuizDetails: (_value: boolean) => void;
    isDirty: boolean;
    setIsDirty: (_value: boolean) => void;
    questionsInEditMode: Array<string>;
    setQuestionsInEditMode: (_value: Array<string>) => void;
    closeEditorHandler: () => void;
} | null;

const QuizEditorContext = createContext<QuizEditorContextType>(null);

export const useQuizEditorContext = () => {
    const context = useContext(QuizEditorContext);
    if (!context) {
        throw new Error(
            "useQuizEditorContext must be used within a QuizEditorContextProvider",
        );
    }
    return context;
};

export const QuizEditorContextProvider = ({
    closeEditor,
    children,
}: {
    closeEditor?: () => void;
    children: ReactNode;
}) => {
    const {
        config: { language },
    } = useAppContext();
    const navigate = useNavigate();
    const { state } = useLocation();
    const queryClient = useQueryClient();
    const { openMessageModal, openAlertMessage } = useMessagingContext();
    const collectionId = state?.collectionId;
    const collectionTitle = state?.collectionTitle;
    const collectionsParams = state?.collectionsParams;
    // Source Values
    const { quizId } = useParams();
    const parentId = collectionsParams?.at(-1);
    const firstFolderId =
        collectionsParams?.length > 1 ? collectionsParams[1] : undefined;
    const workspaceId =
        collectionsParams?.length > 1 ? collectionsParams[0] : undefined;
    const { data: savedQuizData, status: quizStatus } = useQuizQuery(quizId);
    const [isEditorInitialised, setIsEditorInitialised] = useState(
        !quizId || quizStatus === "success",
    );

    // Quiz Settings
    const [title, setTitle] = useState(
        savedQuizData?.title
            ? savedQuizData?.title
            : collectionTitle
              ? `Quiz: ${collectionTitle}`
              : translate(language, "Insert Quiz Title"),
    );
    const [topic, setTopic] = useState(savedQuizData?.topic ?? "");
    const [suggestedSource, setSuggestedSource] =
        useState<SuggestedSource | null>(
            savedQuizData?.suggestedSources &&
                savedQuizData.suggestedSources.length > 0
                ? savedQuizData.suggestedSources[0]
                : null,
        );
    const [multipleChoice, setMultipleChoice] = useState(true);
    const [trueOrFalse, setTrueOrFalse] = useState(true);
    const [numberOfQuestions, setNumberOfQuestions] = useState(5);
    const [questions, setQuestions] = useState(savedQuizData?.questions ?? []);

    const [isDirty, setIsDirty] = useState(false);
    const [showQuizDetails, setShowQuizDetails] = useState(false);
    const [questionsInEditMode, setQuestionsInEditMode] = useState<
        Array<string>
    >([]);

    // Mutations
    const createQuizMutation = useCreateQuizMutation();
    const saveQuizMutation = useSaveQuizMutation();
    const deleteQuizMutation = useDeleteQuizMutation();
    const generateQuestionsMutation = useGenerateQuestionsMutation();

    usePreventNavigation({
        blocked: isDirty,
        messageBody: {
            title: "Quiz is not saved",
            body: "Your work will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
            type: "Yes, leave the page",
        },
    });

    useEffect(() => {
        if (isEditorInitialised || !savedQuizData) return;
        setTitle(savedQuizData.title);
        setTopic(savedQuizData.topic);
        setQuestions(savedQuizData.questions);
        setSuggestedSource(
            savedQuizData?.suggestedSources &&
                savedQuizData.suggestedSources.length > 0
                ? savedQuizData.suggestedSources[0]
                : null,
        );
        setIsEditorInitialised(true);
    }, [isEditorInitialised, savedQuizData]);
    if (!isEditorInitialised || (!quizId && !parentId)) return <Loading />;

    const generateQuestions = () => {
        tracker("Quiz Generate Questions Clicked");
        generateQuestionsMutation.mutate(
            {
                title,
                topic,
                multipleChoice,
                trueOrFalse,
                numberOfQuestions,
                collectionId: collectionId ? parseInt(collectionId) : undefined,
                suggestedSourcesIds: suggestedSource
                    ? [suggestedSource.id]
                    : [],
            },
            {
                onSuccess: ({ data: { questions: newQuestions } }) => {
                    setQuestions([...newQuestions, ...questions]);
                    setIsDirty(true);
                },
            },
        );
    };

    const saveHandler = async () => {
        tracker("Quiz Save Button Clicked");
        if (questionsInEditMode.length > 0) {
            openMessageModal({
                isModalVisible: true,
                title: "Questions are not saved",
                body: "Save all individual questions first.",
                type: "OK",
                primaryActionHandler: () => undefined,
            });
        } else {
            const quiz = {
                title,
                topic,
                questions,
                suggestedSources: suggestedSource ? [suggestedSource] : [],
                language,
            };

            if (!quizId) {
                createQuizMutation.mutate(
                    {
                        parentId,
                        quiz,
                    },
                    {
                        onSuccess: ({ data }) => {
                            if (firstFolderId) {
                                queryClient.invalidateQueries({
                                    queryKey: ["folder", firstFolderId],
                                });
                            } else if (workspaceId) {
                                queryClient.invalidateQueries({
                                    queryKey: ["workspaces"],
                                });
                            } else {
                                console.log("failed to reset");
                            }
                            setIsDirty(false);
                            navigate(`/edit-quiz/${data.id}`, {
                                replace: true,
                                state,
                            });
                        },
                    },
                );
            } else {
                saveQuizMutation.mutate(
                    {
                        quizId,
                        quiz,
                    },
                    {
                        onSuccess: () => {
                            if (firstFolderId) {
                                queryClient.invalidateQueries({
                                    queryKey: ["folder", firstFolderId],
                                });
                            } else if (workspaceId) {
                                queryClient.invalidateQueries({
                                    queryKey: ["workspaces"],
                                });
                            } else {
                                console.log("failed to reset");
                            }
                            queryClient.invalidateQueries({
                                queryKey: ["quiz", quizId],
                            });
                            setIsDirty(false);
                            openAlertMessage({
                                message: "Quiz has been saved.",
                            });
                        },
                    },
                );
            }
        }
    };

    const deleteHandler = () => {
        openMessageModal({
            isModalVisible: true,
            title: "Delete the quiz",
            body: "Are you sure you want to delete the quiz? You can’t undo this action.",
            type: "Delete",
            primaryActionHandler: () => {
                if (!quizId) return;
                tracker("Deleted Quiz");
                deleteQuizMutation.mutate(
                    { quizId, collectionsParams },
                    {
                        onSuccess: () => {
                            openAlertMessage({
                                message: "Quiz has been deleted.",
                                type: "error",
                                open: true,
                            });
                            if (firstFolderId) {
                                queryClient.invalidateQueries({
                                    queryKey: ["folder", firstFolderId],
                                });
                            } else if (workspaceId) {
                                queryClient.invalidateQueries({
                                    queryKey: ["workspaces"],
                                });
                            } else {
                                console.log("failed to reset");
                            }
                            if (collectionsParams.length) {
                                const containerPath = collectionsParams
                                    .slice(0, -1)
                                    .join("/");
                                navigate("/collections/" + containerPath, {
                                    replace: true,
                                });
                            } else {
                                navigate("/");
                            }
                        },
                    },
                );
            },
        });
    };

    const resetEditor = () => {
        setTitle("");
        setQuestions([]);
        setIsDirty(false);
    };

    const closeEditorHandler = () => {
        if (!closeEditor) return;
        tracker("Quiz Editor Closed");
        if (isDirty) {
            openMessageModal({
                isModalVisible: true,
                title: "Quiz is not saved",
                body: "Your work will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
                type: "Yes, leave the page",
                primaryActionHandler: () => {
                    resetEditor();
                    closeEditor();
                },
            });
        } else {
            closeEditor();
        }
    };
    const generateQuestionsEnabled = !!collectionId || topic.length > 2;
    const dirtyCurrier = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        originalFunction: Dispatch<SetStateAction<any>>,
    ) => {
        return (arg0?: unknown) => {
            if (!isDirty) setIsDirty(true);
            originalFunction(arg0);
        };
    };
    const contextValue = {
        quizData: {
            id: quizId,
            type: "QUIZ",
            title,
            topic,
            questions,
            suggestedSource,
        },
        title,
        setTitle: dirtyCurrier(setTitle),
        topic,
        setTopic: dirtyCurrier(setTopic),
        suggestedSource,
        setSuggestedSource: dirtyCurrier(setSuggestedSource),
        multipleChoice,
        setMultipleChoice,
        trueOrFalse,
        setTrueOrFalse,
        numberOfQuestions,
        setNumberOfQuestions,
        questions,
        setQuestions,
        generateQuestionsEnabled,
        isGenerating: generateQuestionsMutation.isLoading,
        generateQuestions,
        isSaving: createQuizMutation.isLoading || saveQuizMutation.isLoading,
        saveHandler,
        deleteHandler,
        showQuizDetails,
        setShowQuizDetails,
        isDirty,
        setIsDirty,
        questionsInEditMode,
        setQuestionsInEditMode,
        closeEditorHandler,
    };
    return (
        <QuizEditorContext.Provider value={contextValue}>
            <CollectionNavBar
                pageTitle={title}
                previousCrumbs={[
                    {
                        label: translate(language, "Editor"),
                        pathname: location.pathname,
                    },
                ]}
            />
            {children}
        </QuizEditorContext.Provider>
    );
};

export default QuizEditorContext;
