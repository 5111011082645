import {
    faArrowUpFromBracket,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./SaveButton.module.scss";
import useScreenSizes from "hooks/useScreenSizes";

import Translate from "components/Translate/Translate";

const SaveButton = ({
    saveHandler,
    isSaving,
    isDisabled,
}: {
    saveHandler?: () => void;
    isSaving?: boolean;
    isDisabled?: boolean;
}) => {
    const { isTabletScreen } = useScreenSizes();
    if (!saveHandler) return <></>;
    return (
        <Button
            variant="contained"
            className={styles.saveButton}
            disabled={isDisabled}
            onClick={saveHandler}
        >
            <FontAwesomeIcon
                icon={isSaving ? faSpinner : faArrowUpFromBracket}
                fontSize={12}
                spin={isSaving}
            />
            {!isTabletScreen && (
                <Typography variant="button-text-size-xSmall-bold">
                    <Translate text="Save" />
                </Typography>
            )}
        </Button>
    );
};

export default SaveButton;
