import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { axiosAPI } from "./axiosAPI";
import { Collection, ContentElement, Folder, Quiz } from "types";

export const useSelectedShareFolders = () => {
    const shareParams = useShareParams();
    const { data: allFolders } = useAllCachedShareFoldersQuery();
    if (!allFolders || !shareParams || shareParams.length === 0) return [];
    const findItemsWithIds = (
        data: Folder[],
        params: { id: number; hash: string }[],
    ) => {
        const foundItems: Folder[] = [];
        const findItemsRecursive = (node: Folder) => {
            if (params.some((param) => param.id === node.id)) {
                foundItems.push(node);
                if (node.children && node.children.length > 0) {
                    node.children.forEach(
                        (el) => el.type === "FOLDER" && findItemsRecursive(el),
                    );
                }
            }
        };
        data.forEach((item) => findItemsRecursive(item));
        return foundItems;
    };
    const selectedFolders = findItemsWithIds(allFolders.children, shareParams);
    return selectedFolders;
};

export const useShareElementQuery = (params?: {
    id?: string | number;
    hash?: string;
}) => {
    return useQuery<Folder | Collection | Quiz, AxiosError>(
        ["share-element", params?.id],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(
                    `/content/share?shareId=${params?.id}&hash=${params?.hash}`,
                );
            return data;
        },
        { enabled: !!params && !!params.id && !!params.hash },
    );
};

export const useShareParams = (): { id: number; hash: string }[] => {
    const params = useParams();
    const nodeParams =
        params["*"] !== undefined && params["*"] !== ""
            ? params["*"].split("/")
            : [];
    const pairedArray: { id: number; hash: string }[] = nodeParams.reduce(
        (acc: { id: number; hash: string }[], curr, index, array) => {
            if (index % 2 === 0 && index + 1 < array.length) {
                acc.push({ id: parseFloat(curr), hash: array[index + 1] });
            }
            return acc;
        },
        [],
    );
    return pairedArray;
};

export const useAllCachedShareFoldersQuery = () => {
    const queryClient = useQueryClient();
    const shareParams = useShareParams();
    const rootParams = shareParams?.[0];
    const availableFoldersData = queryClient.getQueriesData<Folder>([
        "share-folder",
    ]);
    const availableFolderParams = availableFoldersData.filter(
        (el) => !!el || el[1] !== undefined,
    );
    const filteredFolderParams = availableFolderParams
        .map((el) => {
            if (el && el.length > 1 && el[1]?.id && el[1]?.hash) {
                return { id: el[1]?.id, hash: el[1]?.hash };
            }
        })
        .filter((el) => !!el);
    const allNeededFolderParams = [
        ...shareParams.filter(
            (el) =>
                !!el &&
                !!el.id &&
                !filteredFolderParams.some((el2) => el2.id === el.id),
        ),
        ...filteredFolderParams,
    ];

    const allFetchedFolders = useQueries<Folder[]>({
        queries: allNeededFolderParams.map((param) => ({
            queryKey: ["share-folder", param.id],
            queryFn: async () => {
                const { data } = await axiosAPI
                    .getInstance()
                    .get(
                        `/content/share?shareId=${param.id}&hash=${param.hash}`,
                        {
                            params: {
                                includeChildren: true,
                            },
                        },
                    );
                return data;
            },
            staleTime: 1000 * 20,
        })),
    });
    //allFetchedFolders[0].data.id
    const rootFolder = allFetchedFolders.find(
        (el) => el.data?.id === rootParams?.id,
    );

    const enhancedFolders = useMemo(() => {
        if (!rootFolder || !allFetchedFolders) {
            return [];
        }

        const updatedFolders = rootFolder.data.children.map(
            (element: Folder | ContentElement) => {
                if (element.type !== "FOLDER") return element;
                const folder = element;
                const matchingFetched = allFetchedFolders.find(
                    (fetchedFolder) => {
                        const flattenedFetchedFolder =
                            fetchedFolder?.data?.data || fetchedFolder?.data;
                        return flattenedFetchedFolder?.id === folder.id;
                    },
                );
                const returningFolder = matchingFetched?.data ?? folder;
                const newChildren = returningFolder.children.map((child) => {
                    const matchingChild = allFetchedFolders.find(
                        (fetchedFolder) => {
                            const flattenedFetchedFolder =
                                fetchedFolder?.data?.data ||
                                fetchedFolder?.data;
                            return flattenedFetchedFolder?.id === child.id;
                        },
                    );
                    if (matchingChild) {
                        const flattenedMatchedChild =
                            matchingChild?.data?.data || matchingChild?.data;

                        return {
                            ...flattenedMatchedChild,
                            hasBeenFetched: true,
                        };
                    }
                    return { ...child, hasBeenFetched: false };
                });
                folder.children = newChildren;
                return folder;
            },
        );

        rootFolder.data.children = updatedFolders;
        return rootFolder;
    }, [allFetchedFolders, rootFolder]);

    return { data: enhancedFolders?.data ?? [], status: rootFolder?.status };
};
