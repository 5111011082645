import { Typography } from "@mui/material";

import starSVG from "./assets/star.svg";
import useScreenSizes from "hooks/useScreenSizes";

import BackgroundPattern from "components/BackgroundPattern/BackgroundPattern";
import Translate from "components/Translate/Translate";

interface Props {
    score: number;
    totalNumberOfQuestions: number;
}

const ResultScreen = ({ score, totalNumberOfQuestions }: Props) => {
    const { isMobileScreen } = useScreenSizes();
    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                paddingTop: 240,
                alignItems: "center",
            }}
        >
            <BackgroundPattern colourState="neutral" height="320px" />
            <div
                style={{
                    display: "flex",
                    width: isMobileScreen ? "320px" : "340px",
                    height: isMobileScreen ? "320px" : "340px",
                    padding: "40px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "180px",
                    border: "4px solid #F7BF6D",
                    background:
                        "linear-gradient(180deg, #BA29B5 0%, #9B1DE8 100%)",
                    zIndex: 1,
                    position: "relative",
                }}
            >
                <div>
                    <Typography variant="text-size-xxLarge" color="white">
                        <Translate text="Your score" />
                    </Typography>
                </div>
                <div
                // style={{
                //     position: "relative",
                //     width: "100%",
                //     height: "100%",
                //     display: "flex",
                //     flexDirection: "column",
                //     justifyContent: "center",
                //     alignItems: "center",
                // }}
                >
                    <span
                        style={{
                            color: "#FFF",
                            textAlign: "center",
                            fontSize: "80px",
                            fontStyle: "normal",
                            fontWeight: 500,
                        }}
                    >
                        {score}
                    </span>
                    <span
                        style={{
                            color: "#FFF",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: 500,
                        }}
                    >
                        / {totalNumberOfQuestions}
                    </span>
                </div>
                <img
                    src={starSVG}
                    style={{
                        position: "absolute",
                        bottom: "-10px",
                        left: "-10px",
                        width: "120px",
                    }}
                />
            </div>
        </div>
    );
};

export default ResultScreen;
