import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

import { useAppContext } from "contexts/AppContext";
import {
    useCollectionQuery,
    useViewItemsInCollectionQuery,
} from "hooks/api/collections";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import QuizAvailableFooter from "./QuizAvailablePanel/QuizAvailableFooter";
import QuizAvailablePanel from "./QuizAvailablePanel/QuizAvailablePanel";
import CollectionViewer from "components/CollectionViewer/CollectionViewer";
import Loading from "components/Loading/Loading";
import ShareLoadFailed from "components/ShareLoadFailed/ShareLoadFailed";
import TitleHeader from "components/TitleHeader/TitleHeader";

const CollectionShareViewer = () => {
    const { isDesktopScreen } = useScreenSizes();
    const { collectionId, hash } = useParams();
    const { config, updateConfig } = useAppContext();
    const { data: collectionData, isFetched: collectionIsFetched } =
        useCollectionQuery(collectionId, hash);
    const {
        data: collectionItems,
        isFetched: collectionItemsIsFetched,
        status: collectionItemsStatus,
    } = useViewItemsInCollectionQuery({ id: collectionId, hash });
    const [tracked, setTracked] = useState(false);
    useEffect(() => {
        if (
            !!collectionData &&
            collectionData.language &&
            config.language !== collectionData.language.toLowerCase()
        ) {
            updateConfig("language", collectionData.language.toLowerCase());
        }
    }, [collectionData, config.language, updateConfig]);
    useEffect(() => {
        if (tracked || collectionItemsStatus !== "success") return;
        tracker("Viewed Collection", { collectionId });
        setTracked(true);
    }, [collectionId, collectionItemsStatus, tracked]);
    const quizAvailableFooterRef = useRef<HTMLDivElement>(null);
    const quizAvailableFooterHeight = quizAvailableFooterRef.current
        ?.clientHeight
        ? `0 0 ${quizAvailableFooterRef.current?.clientHeight}px 0`
        : undefined;
    const openQuizHandler = () => {
        window.open(
            new URL(window.location.href).origin +
                `/quiz/${collectionData?.quiz?.id}/${collectionData?.quiz?.hash}`,
            "_blank",
        );
    };
    if (!collectionIsFetched) return <Loading />;
    return (
        <>
            <TitleHeader collectionName={collectionData?.title} />
            {!collectionItems || !collectionItems.length ? (
                <ShareLoadFailed collectionExists={Boolean(collectionId)} />
            ) : (
                <Grid
                    container
                    spacing={2}
                    margin={"20px 40px"}
                    padding={quizAvailableFooterHeight}
                >
                    <Grid lg={6} xs={12} padding={"0px"}>
                        <CollectionViewer
                            items={collectionItems ?? []}
                            isFetched={collectionItemsIsFetched}
                            viewAs={collectionData?.view ?? "GRID"}
                            collectionResultsModalEnabled={
                                !!collectionData?.resultModal
                            }
                        />
                    </Grid>
                    {isDesktopScreen && (
                        <>
                            <Grid xs={0} sm={3}></Grid>
                            <Grid sm={3}>
                                {collectionData?.quiz && (
                                    <QuizAvailablePanel
                                        quizTitle={collectionData?.quiz.title}
                                        openHandler={openQuizHandler}
                                    />
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
            {!isDesktopScreen && collectionData?.quiz && (
                <QuizAvailableFooter
                    ref={quizAvailableFooterRef}
                    quizTitle={collectionData?.quiz?.title}
                    openHandler={openQuizHandler}
                />
            )}
        </>
    );
};

export default CollectionShareViewer;
