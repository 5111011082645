import { Typography } from "@mui/material";

import * as styles from "./QuestionViewerCard.module.scss";
import { Question } from "types";

import AnswerViewer from "./AnswerViewer";

interface Props {
    question: Question;
}

const QuestionViewerCard = ({ question }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Typography
                    variant="text-size-Base-bold"
                    color="$text-colors-color-text-dark.main"
                >
                    {question.question}
                </Typography>
            </div>
            <div className={styles.answers}>
                {question.answers.map((answer) => (
                    <AnswerViewer
                        key={answer.id}
                        answer={answer}
                        isCorrectAnswer={answer.id === question.correctAnswerId}
                    />
                ))}
            </div>
        </div>
    );
};

export default QuestionViewerCard;
