import { Dialog } from "@mui/material";

import { useCollectionQuery } from "hooks/api/collections";
import { useFullFolderQuery } from "hooks/api/folders";
import { useQuizQuery } from "hooks/api/quizzes";
import { ContentElement, Folder } from "types";

import Loading from "components/Loading/Loading";
import ShareModal from "components/ShareButton/ShareModal/ShareModal";

const ShareElementDialog = ({
    element,
    isVisible,
    setIsVisible,
}: {
    element: ContentElement | Folder;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const isCollection = element.type === "COLLECTION";
    const isFolder = element.type === "FOLDER";
    const isQuiz = element.type === "QUIZ";
    const isTutor = element.type === "TUTOR";
    const { data: collectionData, status: collectionStatus } =
        useCollectionQuery(isCollection ? element.id : undefined);
    const { data: folderData, status: folderDataStatus } = useFullFolderQuery(
        isFolder ? element.id : undefined,
    );
    const { data: quizData, status: quizStatus } = useQuizQuery(
        isQuiz ? element.id : undefined,
    );
    const isLoading = isCollection
        ? collectionStatus === "loading" ||
          !collectionData ||
          !collectionData.embedLink
        : isFolder
          ? folderDataStatus === "loading" ||
            !folderData ||
            !folderData.embedLink
          : isQuiz
            ? quizStatus === "loading" || !quizData || !quizData.embedLink
            : isTutor
              ? false
              : true;
    const sourceData = isCollection
        ? collectionData
        : isFolder
          ? folderData
          : isQuiz
            ? quizData
            : isTutor
              ? element
              : undefined;
    return (
        <Dialog
            open={isVisible}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onClose={() => {
                setIsVisible(false);
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        maxWidth: "100%", // Set your width here
                        margin: 0,
                        borderRadius: "15px",
                    },
                },
            }}
        >
            {isLoading ? (
                <div
                    style={{
                        width: 900,
                        height: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loading />
                </div>
            ) : (
                <ShareModal
                    embedUrl={sourceData?.embedLink as string}
                    linkUrl={sourceData?.shareLink}
                    closeHandler={() => {
                        setIsVisible(false);
                    }}
                    inCollectionEditor={true}
                />
            )}
        </Dialog>
    );
};

export default ShareElementDialog;
