import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { axiosAPI } from "./axiosAPI";
import { useFullWorkspacesQuery } from "./folders";
import { useSearchContext } from "contexts/SearchContext";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import { useQuizEditorContext } from "features/QuizEditor/state/QuizEditorContext";
import {
    AllSearchResultTypes,
    AllSearchSuggestionTypes,
    Collection,
    ResultType,
} from "types";

type PaginatedSearch<T> = {
    results: Array<T>;
    pagination: {
        startPage: number;
        pageSize: number;
        totalPages: number;
        totalResults: number;
        returnedResults: number;
    };
    query: {
        query: string;
        language: Array<"EN" | "NL">;
        startPage: number;
        pageSize: number;
        embeddableOnly: boolean;
        filters: {
            workspaces: number[];
            readingLevels: [1 | 2 | 3 | 4 | 5];
            resultTypes: Array<ResultType>;
        };
    };
};

export const useSearchInfiniteQuery = () => {
    const {
        queryTerm,
        embeddable,
        resultTypes,
        readingLevels,
        workspaces,
        subjects,
    } = useSearchContext();
    const { state } = useLocation();
    const { collection } = useContext(CollectionContext);
    const collectionContext = { id: collection.id, type: "COLLECTION" };

    const fetchSearch = async ({ pageParam = 1 }) => {
        const { data } = await axiosAPI.getInstance().post(`/content/search`, {
            query: queryTerm,
            context: collection.id !== 0 ? collectionContext : undefined,
            startPage: pageParam ? pageParam : 1,
            pageSize: 10, // default value
            embeddableOnly: embeddable,
            filters: {
                resultTypes: Array.from(resultTypes),
                readingLevels: Array.from(readingLevels),
                workspaces: Array.from(workspaces),
                subjects: Array.from(subjects),
            },
            ...(state?.searchLanguage
                ? { language: [state.searchLanguage.toLowerCase()] }
                : {}),
        });

        // Covers up a bug in BE where non-embeddables are returned when they should be filtered
        if (embeddable)
            return {
                ...data,
                results: data.results.filter(
                    (el: AllSearchResultTypes) =>
                        !("embeddable" in el) || el.embeddable,
                ),
            };
        return data;
    };
    return useInfiniteQuery<PaginatedSearch<AllSearchResultTypes>>({
        queryKey: [
            "search-infinite",
            queryTerm,
            embeddable,
            Array.from(resultTypes),
            Array.from(readingLevels),
            Array.from(workspaces),
            Array.from(subjects),
        ],
        queryFn: fetchSearch,
        getNextPageParam: (lastPage, _pages) =>
            lastPage.pagination.startPage
                ? lastPage.pagination.startPage !=
                  lastPage.pagination.totalPages
                    ? lastPage.pagination.startPage + 1
                    : undefined
                : 1,
        enabled: !!queryTerm,
    });
};

export const useCollectionSearchInfiniteQuery = () => {
    const { state } = useLocation();
    const collectionId = state?.collectionId;
    const { topic } = useQuizEditorContext();
    const { data: allWorkspaces } = useFullWorkspacesQuery();
    const workspaces = allWorkspaces.map((workspace) => workspace.id);
    const fetchSearch = async ({ pageParam = 1 }) => {
        const { data } = await axiosAPI.getInstance().post(`/content/search`, {
            query: topic,
            startPage: pageParam ? pageParam : 1,
            pageSize: 10,
            embeddableOnly: false,
            filters: {
                resultTypes: ["COLLECTION"],
                readingLevels: [],
                workspaces: workspaces,
                subjects: [],
            },
            ...(state?.searchLanguage
                ? { language: [state.searchLanguage.toLowerCase()] }
                : {}),
        });
        return data;
    };
    return useInfiniteQuery<PaginatedSearch<Collection>>({
        queryKey: [
            "collection-search-infinite",
            topic,
            false,
            ["COLLECTION"],
            [],
            workspaces,
            [],
        ],
        queryFn: fetchSearch,
        getNextPageParam: (lastPage, _pages) =>
            lastPage.pagination.startPage
                ? lastPage.pagination.startPage !=
                  lastPage.pagination.totalPages
                    ? lastPage.pagination.startPage + 1
                    : undefined
                : 1,
        enabled: !collectionId && !!topic,
    });
};

export const useSearchSuggestionsQuery = (
    searchTerm: string | undefined,
    // delayPassed: boolean,
    paramsObject?: object,
) => {
    return useQuery<Array<AllSearchSuggestionTypes>, AxiosError>(
        ["search-suggestions", searchTerm, paramsObject], // keys need the params setting here too, otherwise it'll not update
        async () => {
            const { data }: { data: Array<AllSearchSuggestionTypes> } =
                await axiosAPI
                    .getInstance()
                    .post(`/content/search/suggestions`, paramsObject);
            return data;
        },
        { enabled: !!searchTerm }, // && delayPassed },
    );
};
