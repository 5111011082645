import { formatYoutubeEmbedUrl, isSrcYouTube } from "./oldhelpers";
import { AppConfig } from "contexts/AppContext";
import {
    CollectionItem,
    CollectionResourceResult,
    OldResult,
    ResultType,
    UserSettings,
} from "types";

export const convertAppConfigTypeToUserSettingsType = (
    appConfig: AppConfig,
) => {
    return {
        resultTarget: appConfig.resultModal ? "EMBED" : "BLANK",
        resultSize: appConfig.numberOfResults,
        embeddable: appConfig.embeddable,
        teacherApproved: appConfig.pinnedOnly,
        languageId: appConfig.language === "nl" ? 90 : 29,
        displayDrafts: appConfig.showDrafts,
        completedTours: appConfig.completedTours,
    };
};

export const convertUserSettingsTypeToAppConfigType = (
    userSettings: UserSettings,
) => {
    return {
        resultModal: userSettings.resultTarget === "EMBED",
        numberOfResults: userSettings.resultSize,
        embeddable: userSettings.embeddable,
        pinnedOnly: userSettings.teacherApproved,
        language: userSettings.language,
        showDrafts: userSettings.displayDrafts,
        completedTours: userSettings.completedTours,
    };
};

export const convertResultTypeToItemType = (
    result: CollectionResourceResult,
    order: number,
): CollectionItem & { clickUrl: string; embedUrl: string | null } => {
    const newDocType: "page" | "video" =
        result.resultType === "TEXT" ? "page" : "video";

    return {
        id: order,
        indexId: result.id,
        title: result.title,
        description: result.description,
        url: result.clickUrl ?? result.embedUrl,
        sourceUrl: result.source.name ?? "",
        thumbnail: result.thumbnail ?? "",
        audiences: result.readingLevel
            ? [
                  {
                      label: result.readingLevel.toString(),
                      name: result.readingLevel.toString(),
                      level: result.readingLevel,
                  },
              ]
            : [],
        collectionAccessType: "publicCollection",
        item_order: order,
        documentTypes: [newDocType],
        metadata: "", //result.metadata,
        options: { embeddable: result.embeddable, feedbackProvided: false },
        sourceLogo: result.source.thumbnail ?? "",
        teacherApproved: result.teacherApproved,
        isUserProvided: false,
        lastVerified: null,
        pageState: "LIVE",
        redirectedTo: null,
        createdDate: result.createdDate,
        createdBy: result.createdBy,
        createdByName: result.reatedByName,
        clickUrl: result.clickUrl ?? "",
        embedUrl: result.embedUrl,
    };
};

export const convertItemTypeToResultType = (
    item: CollectionItem,
): CollectionResourceResult => {
    const embedUrl = !!item.embedUrl
        ? item.embedUrl
        : isSrcYouTube(item.url)
          ? formatYoutubeEmbedUrl(item.url)
          : item.url;

    const newResultType: ResultType =
        item.documentTypes[0] === "page" ? "TEXT" : "VIDEO";

    const newSourceType = {
        paidCollection: "PAID",
        publicCollection: "PUBLIC",
        privateCollection: "PRIVATE",
    }[item.collectionAccessType] as CollectionResourceResult["source"]["type"];
    const newReadingLevel = item.audiences?.[0]?.level;

    if (newResultType === "TEXT") {
        return {
            id: item.indexId,
            title: item.title,
            description: item.description,
            readingLevel: newReadingLevel,
            resultType: newResultType,
            thumbnail: item.thumbnail,
            source: {
                thumbnail: item.sourceLogo,
                name: item.sourceUrl,
                type: newSourceType,
            },
            clickUrl: item.url,
            embedUrl: embedUrl,
            teacherApproved: item.teacherApproved,
            isUserProvided: item.isUserProvided,
            pageState: item.pageState,
            createdDate: item.dateCreated,
            createdBy: item.createdBy,
            createdByName: item.createdByName,
            lastUpdated: item.lastVerified,
            metadata: item.metadata,
            embeddable: item.options.embeddable,
        };
    } else {
        return {
            id: item.indexId,
            title: item.title,
            description: item.description,
            readingLevel: newReadingLevel,
            resultType: newResultType,
            thumbnail: item.thumbnail,
            source: {
                thumbnail: item.sourceLogo,
                name: item.sourceUrl,
                type: newSourceType,
            },
            clickUrl: item.url,
            embedUrl: embedUrl,
            teacherApproved: item.teacherApproved,
            isUserProvided: item.isUserProvided,
            pageState: item.pageState,
            createdDate: item.dateCreated,
            createdBy: item.createdBy,
            createdByName: item.createdByName,
            lastUpdated: item.lastVerified,
            metadata: item.metadata,
            embeddable: item.options.embeddable,
        };
    }
};

export const convertOldResultToCollectionResourceResult = (
    oldResult: OldResult,
): CollectionResourceResult => {
    const newDocType: "page" | "video" = oldResult.documentTypes.includes(
        "video",
    )
        ? "video"
        : "page";
    const newResultType: CollectionResourceResult["resultType"] =
        newDocType === "page" ? "TEXT" : "VIDEO";

    const newSourceType = {
        paidCollection: "PAID",
        publicCollection: "PUBLIC",
        privateCollection: "PRIVATE",
    }[
        oldResult.collectionAccessType
    ] as CollectionResourceResult["source"]["type"];
    const newReadingLevel = oldResult.audiences?.[0]?.level;

    const resultBase = {
        id: oldResult.id,
        title: oldResult.title,
        description: oldResult.summaryText,
        readingLevel: newReadingLevel,
        resultType: newResultType,
        thumbnail: oldResult.thumbnail,
        source: {
            thumbnail: oldResult.sourceLogoThumbnail,
            name: oldResult.sourceUrl,
            type: newSourceType,
        },
        clickUrl: oldResult.clickUrl,
        embedUrl: oldResult.embedUrl,
        teacherApproved: oldResult.teacherApproved,
        isUserProvided: oldResult.isUserProvided,
        pageState: "LIVE" as CollectionResourceResult["pageState"],
        lastUpdated: oldResult.lastVerified ?? null,
        metadata: {},
        embeddable: oldResult.options.embeddable,
        feedbackProvided: oldResult.options.feedbackProvided,
    };
    if (newResultType === "TEXT") return { ...resultBase, resultType: "TEXT" };

    return {
        ...resultBase,
        metadata: {
            caption: oldResult.videoMetadata?.caption ?? false,
            duration: oldResult.videoMetadata?.duration ?? "",
        },
    };
};
