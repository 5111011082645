import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    faClipboardQuestion,
    faEdit,
    faPlay,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import variables from "styles/_variables.module.scss";
import { useMessagingContext } from "contexts/MessagingContext";
import { useDeleteQuizMutation, useQuizQuery } from "hooks/api/quizzes";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import Panel from "components/Panel/Panel";
import Translate from "components/Translate/Translate";
import QuizTaker from "features/QuizTaker/QuizTaker";

const PreviewQuizButton = ({
    onClickHandler,
}: {
    onClickHandler: () => void;
}) => {
    const isAtleast1250Width = useMediaQuery("(min-width:1250px)");
    const { isDesktopScreen } = useScreenSizes();
    const showLabel = !isDesktopScreen || isAtleast1250Width;
    return (
        <Button
            style={{
                height: "30px",
                padding: "8px",
                alignItems: "center",
                borderRadius: "4px",
                background: "#F0F6FF",
                verticalAlign: "middle",
            }}
            onClick={onClickHandler}
        >
            {showLabel && (
                <Typography variant="text-size-Small-regular">
                    <Translate text="Preview the quiz" />
                </Typography>
            )}
            <FontAwesomeIcon
                icon={faPlay}
                style={
                    showLabel ? { marginLeft: "10px", marginBottom: -1 } : {}
                }
                fontSize={14}
            />
        </Button>
    );
};

const EditQuizButton = ({
    onClickHandler,
    disabled,
}: {
    onClickHandler: () => void;
    disabled: boolean;
}) => (
    <Button
        aria-label="edit quiz"
        variant="outlined"
        style={{ padding: 8 }}
        onClick={onClickHandler}
        disabled={disabled}
    >
        <FontAwesomeIcon icon={faEdit} color="primary" fontSize={14} />
    </Button>
);

const DeleteQuizButton = ({
    onClickHandler,
    disabled,
}: {
    onClickHandler: () => void;
    disabled: boolean;
}) => (
    <Button
        aria-label="delete quiz"
        variant={disabled ? "outlined" : "text"}
        color="error"
        style={{ background: disabled ? "none" : "#FDEBED", padding: 8 }}
        onClick={onClickHandler}
        disabled={disabled}
    >
        <FontAwesomeIcon icon={faTrash} color="error" fontSize={14} />
    </Button>
);

const QuizPanel = ({ quizId }: { quizId: number }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const collectionsParams = useCollectionsParams();
    const { openMessageModal, openAlertMessage } = useMessagingContext();
    const { data: quizData, status: quizStatus } = useQuizQuery(quizId);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const deleteQuizMutation = useDeleteQuizMutation();
    if (quizStatus !== "success") return <></>;
    const previewQuiz = () => {
        if (quizData.questions.length === 0) {
            openAlertMessage({
                message:
                    "Quiz contains no questions and so can't be previewed.",
                open: true,
                type: "error",
            });
        } else {
            tracker("Preview quiz opened");
            setIsPreviewOpen(true);
        }
    };
    const closeHandler = () => {
        tracker("Preview quiz closed");
        setIsPreviewOpen(false);
    };
    const editQuiz = () => {
        tracker("Edit quiz opened");
        navigate(`/edit-quiz/${quizId}`, {
            state: {
                collectionId: quizData.parentId,
                collectionsParams,
            },
        });
    };
    const deleteQuiz = () => {
        tracker("Delete quiz dialog opened");
        openMessageModal({
            isModalVisible: true,
            title: "Delete the quiz",
            body: "Are you sure you want to delete the quiz? You can’t undo this action.",
            type: "Delete",
            primaryActionHandler: () =>
                deleteQuizMutation.mutate(
                    { quizId, collectionsParams },
                    {
                        onSuccess: () =>
                            openAlertMessage({
                                message: "Quiz has been deleted.",
                                type: "error",
                                open: true,
                            }),
                        onSettled: () =>
                            queryClient.invalidateQueries([
                                "collection",
                                quizData.parentId.toString(),
                            ]),
                    },
                ),
        });
    };
    return (
        <>
            <Panel
                styleOverrides={{
                    // flex: 1,
                    gap: "20px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    borderRadius: "15px",
                    border: "1px solid  #BA29B5",
                    background: "white",
                    boxShadow: "0px 5px 20px 0px rgba(108, 117, 125, 0.20)",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                    }}
                >
                    <FontAwesomeIcon
                        icon={faClipboardQuestion}
                        color={variables["text-colors-color-text-orange"]}
                        fontSize={20}
                    />
                    <Typography
                        variant="text-size-Medium"
                        color="$text-colors-color-text-dark.main"
                    >
                        {quizData.title ?? "Quiz"}
                    </Typography>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexFlow: "row wrap",
                        gap: "10px",
                    }}
                >
                    <PreviewQuizButton onClickHandler={previewQuiz} />
                    <div style={{ display: "flex", gap: "10px" }}>
                        <EditQuizButton
                            onClickHandler={editQuiz}
                            disabled={!quizData.permissions?.includes("UPDATE")}
                        />
                        <DeleteQuizButton
                            onClickHandler={deleteQuiz}
                            disabled={!quizData.permissions?.includes("DELETE")}
                        />
                    </div>
                </div>
            </Panel>
            <QuizTaker
                quizData={quizData}
                isOpen={isPreviewOpen}
                closeHandler={closeHandler}
                elementId={quizId}
                elementHash={quizData.hash}
                isPreview
                withCloseButton
            />
        </>
    );
};

export default QuizPanel;
