@use "../../../styles/variables.module";

.wrapper {
    position: relative;
}

.overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.shareButton {
    padding: 10px 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #9b1de8;
    border: 1px solid #9b1de880;
    @media (max-width: variables.$bp-lg) {
        padding: 10px;
        width: 30px;
        border-radius: 50%;
    }
}
