import { CSSProperties } from "react";
import { Button } from "@mui/material";

import * as styles from "./DialogFooter.module.scss";

import Translate, { TranslatableText } from "components/Translate/Translate";

export type DialogFooterProps = {
    secondaryButton?: {
        label: TranslatableText;
        clickHandler: () => void;
        disabled?: boolean;
    };
    primaryButton?: {
        label: TranslatableText;
        clickHandler?: () => void; // optional because it can be the 'submit' in a form
        disabled?: boolean;
    };
    footerStyleOverrides?: CSSProperties;
};

const DialogFooter = ({
    secondaryButton,
    primaryButton,
    footerStyleOverrides,
}: DialogFooterProps) => {
    return (
        <div className={styles.container} style={{ ...footerStyleOverrides }}>
            {secondaryButton && (
                <Button
                    variant="text"
                    className={styles.button}
                    onClick={secondaryButton.clickHandler}
                    disabled={secondaryButton.disabled}
                >
                    <Translate text={secondaryButton.label} />
                </Button>
            )}
            {primaryButton && (
                <Button
                    variant="contained"
                    data-testid="submitButton"
                    type={"submit"}
                    className={styles.button}
                    disabled={primaryButton.disabled}
                    onClick={() => {
                        primaryButton.clickHandler &&
                            primaryButton.clickHandler();
                    }}
                >
                    <Translate text={primaryButton.label} />
                </Button>
            )}
        </div>
    );
};

export default DialogFooter;
