import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import * as styles from "./QuizEditorQuestions.module.scss";
import { useQuizEditorContext } from "../../state/QuizEditorContext";
import questionMarkSVG from "images/question-mark.webp";
import { tracker } from "utils/analytics";

import AddQuestionPanel from "./components/AddQuestionPanel";
import QuestionEditorCard, {
    QuestionEditorCardSkeleton,
} from "./components/QuestionEditorCard";
import Translate from "components/Translate/Translate";

const QuizEditorQuestions = () => {
    const {
        numberOfQuestions,
        questions,
        setQuestions,
        isGenerating,
        setIsDirty,
    } = useQuizEditorContext();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const questionsIds = questions.map((question) => question.id);
            const localQuestions = arrayMove(
                questions,
                questionsIds.indexOf(active.id.toString()),
                questionsIds.indexOf(over.id.toString()),
            );
            setQuestions(localQuestions);
            setIsDirty(true);
            tracker("Questions Reordered");
        }
    };
    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <div className={styles.container}>
                {isGenerating ? (
                    <div className={styles.loading}>
                        <img
                            src={questionMarkSVG}
                            alt="Quiz Icon"
                            style={{
                                maxHeight: "60px",
                                position: "absolute",
                                left: "10px",
                            }}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faSpinner}
                                fontSize={30}
                                spin
                            />
                            <Typography
                                variant="text-size-Base"
                                color="text-colors-color-text-dark.main"
                            >
                                <Translate text="We are preparing questions for your quiz." />
                            </Typography>
                        </div>
                    </div>
                ) : (
                    <AddQuestionPanel />
                )}
                {isGenerating &&
                    new Array(numberOfQuestions)
                        .fill(1)
                        .map((index, i) => (
                            <QuestionEditorCardSkeleton key={index + i} />
                        ))}
                <SortableContext
                    items={questions}
                    strategy={verticalListSortingStrategy}
                >
                    {questions.map((question) => (
                        <QuestionEditorCard
                            key={question.id}
                            question={question}
                        />
                    ))}
                </SortableContext>
            </div>
        </DndContext>
    );
};

export default QuizEditorQuestions;
