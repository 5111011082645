import { useState } from "react";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import * as styles from "./ContainerElementCard.module.scss";
import variables from "styles/_variables.module.scss";
import { ContainerElement } from "types";
import { isAppMode } from "utils/newtools";

import ElementThreeDotContextMenu from "components/ContextMenu/ElementThreeDotContextMenu";
import Translate, { TranslatableText } from "components/Translate/Translate";
import ChangeLogFooter from "components/ChangeLogFooter/ChangeLogFooter";

interface Props {
    containerElement: ContainerElement;
    clickHandler: (containerElement: ContainerElement) => void;
    disableContextMenu?: boolean;
}

const ContainerElementCard = ({
    containerElement,
    clickHandler,
    disableContextMenu,
}: Props) => {
    const [hasImageFetchFailed, setHasImageFetchFailed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const mouseOverHandler = () => {
        setIsHovered(true);
    };
    const mouseLeaveHandler = () => {
        setIsHovered(false);
    };
    return (
        <div
            className={styles.container}
            onClick={() => clickHandler(containerElement)}
            onMouseOver={
                isAppMode &&
                !disableContextMenu &&
                containerElement.type !== "WORKSPACE"
                    ? mouseOverHandler
                    : undefined
            }
            onMouseLeave={
                isAppMode &&
                !disableContextMenu &&
                containerElement.type !== "WORKSPACE"
                    ? mouseLeaveHandler
                    : undefined
            }
        >
            {containerElement.type !== "WORKSPACE" &&
                isAppMode &&
                !disableContextMenu && (
                    <div className={styles.header}>
                        <ElementThreeDotContextMenu
                            element={containerElement}
                            isHovered={isHovered}
                        />
                    </div>
                )}
            <div className={styles.image}>
                {!hasImageFetchFailed && containerElement.imageUrl ? (
                    <img
                        src={containerElement.imageUrl}
                        alt={containerElement.title}
                        aria-hidden="true"
                        style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                        }}
                        onError={() => setHasImageFetchFailed(true)}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faFolder}
                        color={
                            containerElement.color ||
                            variables["text-colors-color-text-light-gray"]
                        }
                        fontSize={32}
                    />
                )}
            </div>
            <Typography
                variant="text-size-Base-bold"
                color="$text-colors-color-text-dark.main"
                className={styles.title}
            >
                {containerElement.type === "WORKSPACE" ? (
                    <Translate
                        text={containerElement.title as TranslatableText}
                    />
                ) : (
                    containerElement.title
                )}
            </Typography>
            {containerElement.type !== "WORKSPACE" && (
                <ChangeLogFooter
                    type="Modified by"
                    name={containerElement.lastModifiedByName}
                    date={containerElement.lastModifiedDate}
                />
            )}
        </div>
    );
};

export default ContainerElementCard;
