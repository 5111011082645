@use "../../styles/variables.module";

.container {
    padding: 10px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    background: variables.$background-colors-color-background-light-off;
    border: 1px solid variables.$border-colors-color-border-light;
    border-radius: 10px;
}

.checkbox {
    margin: 0px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 5px;
}

.logoAndTitle {
    display: flex;
    align-items: flex-start;
    gap: 5px;
}
