@use "../../styles/variables.module";

.saveButton {
    padding: 10px 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media (max-width: variables.$bp-lg) {
        padding: 10px;
        width: 30px;
        border-radius: 50%;
    }
    @media (max-width: variables.$bp-md) {
        padding: 10px 20px;
        width: 100%;
        border-radius: 5px;
    }
}
