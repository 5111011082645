import { Typography } from "@mui/material";

import * as styles from "./NoContentElementsMessage.module.scss";
import useScreenSizes from "hooks/useScreenSizes";

import Translate from "components/Translate/Translate";

const NoContentElementsMessage = () => {
    const { isTabletScreen } = useScreenSizes();
    return (
        <div
            className={styles.noCollections}
            style={isTabletScreen ? { height: "100%" } : {}}
        >
            <Typography variant="text-size-Base">
                <Translate text="You don't have any collections, quizzes or tutors yet. Once you create a collection, quiz or tutor, it will appear here." />
            </Typography>
        </div>
    );
};

export default NoContentElementsMessage;
