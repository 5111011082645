import { useContext } from "react";

import { useSearchContext } from "contexts/SearchContext";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import { useFullWorkspacesQuery } from "hooks/api/folders";
import { useSearchSuggestionsQuery } from "hooks/api/useSearchQuery";

import SuggestionsList from "./components/SuggestionsList";

const Suggestions = ({
    searchTerm,
    closeSearch,
}: {
    searchTerm: string;
    closeSearch: () => void;
}) => {
    const { inDialogBox } = useSearchContext();
    const { collection } = useContext(CollectionContext);
    const collectionContext = { id: collection.id, type: "COLLECTION" };
    const { data: fullWorkspacesData } = useFullWorkspacesQuery();
    const { data: suggestionsData } = useSearchSuggestionsQuery(searchTerm, {
        query: searchTerm,
        context: collection.id !== 0 ? collectionContext : undefined,
        filters: {
            resultTypes: inDialogBox
                ? ["TEXT", "VIDEO"]
                : ["TEXT", "VIDEO", "COLLECTION", "FOLDER"],
            workspaces: fullWorkspacesData.map((el) => el.id),
            readingLevels: [1, 2, 3, 4, 5],
        },
    });

    if (
        !suggestionsData ||
        (suggestionsData?.length ? "" : Boolean(searchTerm))
    )
        return <></>;
    return (
        <SuggestionsList
            suggestions={suggestionsData}
            closeSearch={closeSearch}
        />
    );
};

export default Suggestions;
