import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { faArrowLeft, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./EditorSubheader.module.scss";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";

import DeleteButton from "components/DeleteButton/DeleteButton";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";
import SaveButton from "components/SaveButton/SaveButton";
import CollectionShareButtonWrapper from "components/ShareButton/CollectionShareButtonWrapper/CollectionShareButtonWrapper";
import QuizShareButtonWrapper from "components/ShareButton/QuizShareButtonWrapper/QuizShareButtonWrapper";
import TutorShareButtonWrapper from "components/ShareButton/TutorShareButtonWrapper/TutorShareButtonWrapper";
import Translate from "components/Translate/Translate";

const ExitEditorButton = () => {
    const { isTabletScreen } = useScreenSizes();
    const navigate = useNavigate();
    return (
        <Button
            variant="outlined"
            className={styles.editButton}
            onClick={() => navigate(-1)}
        >
            <FontAwesomeIcon icon={faArrowLeft} fontSize={12} />
            {!isTabletScreen && (
                <Typography variant="text-size-xSmall-bold">
                    <Translate text="Exit Editor" />
                </Typography>
            )}
        </Button>
    );
};

const EditorSubheader = ({
    additionalLeftComponents,
    additionalRightComponents,
    deleteButtonProps,
    saveButtonProps,
}: {
    additionalLeftComponents?: Array<JSX.Element>;
    additionalRightComponents?: Array<JSX.Element>;
    deleteButtonProps?: { isVisible: boolean; deleteHandler: () => void };
    saveButtonProps?: {
        isDisabled: boolean;
        isSaving: boolean;
        saveHandler: () => void;
    };
}) => {
    const { isMobileScreen } = useScreenSizes();
    const location = useLocation();
    const [isToolboxPanelOpen, setIsToolboxPanelOpen] = useState(false);
    return (
        <div className={styles.container}>
            <div className={styles.editAndMobileExplorer}>
                {!isMobileScreen && <ExitEditorButton />}
                {additionalLeftComponents}
                {isMobileScreen && (
                    <>
                        <MenuButton
                            faIcon={faToolbox}
                            iconColor={variables["icon-colors-blue-100"]}
                            primaryColor={variables["icon-colors-blue-40"]}
                            secondaryColor={variables["icon-colors-blue-60"]}
                            isOpen={isToolboxPanelOpen}
                            styleOverrides={{
                                width: "30px",
                                minWidth: "30px",
                                height: "30px",
                                fontSize: "12px",
                            }}
                            onClick={() => {
                                setIsToolboxPanelOpen(true);
                            }}
                        />
                        <MenuContainer
                            isOpen={isToolboxPanelOpen}
                            closeMenu={() => setIsToolboxPanelOpen(false)}
                            containerRelativePosition="bottom-right"
                            fullWidthBreakpoint="xl"
                            fullHeightBreakpoint="xl"
                        >
                            <div className={styles.mobileContainer}>
                                <ExitEditorButton />
                                <SaveButton {...saveButtonProps} />
                                <DeleteButton {...deleteButtonProps} />
                            </div>
                        </MenuContainer>
                    </>
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                }}
            >
                {additionalRightComponents}
                {!isMobileScreen && (
                    <>
                        <DeleteButton {...deleteButtonProps} />
                        <SaveButton {...saveButtonProps} />
                    </>
                )}
                {location.pathname.includes("collection") ? (
                    <>
                        <TutorShareButtonWrapper />
                        <CollectionShareButtonWrapper />
                    </>
                ) : (
                    <QuizShareButtonWrapper />
                )}
            </div>
        </div>
    );
};
export default EditorSubheader;
