import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, Typography } from "@mui/material";
import {
    faCircle,
    faFolder,
    faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "../SuggestionsList.module.scss";
import variables from "styles/_variables.module.scss";
import { SearchSuggestionResultType } from "types";
import { tracker } from "utils/analytics";

import ExternalImageWithFallback from "components/ExternalImageWithFallback/ExternalImageWithFallback";

interface Props {
    suggestion: SearchSuggestionResultType;
    closeSearch: () => void;
}
const SuggestionsListResultItem = ({ suggestion, closeSearch }: Props) => {
    const navigate = useNavigate();
    const suggestionParents = suggestion.path?.slice(0, -1);

    const isFolderOrNode =
        suggestion.resultType === "FOLDER" ||
        suggestion.resultType === "CURRICULUM_NODE";

    const clickHandler = (e: React.MouseEvent) => {
        e.preventDefault();
        tracker("Search suggestion clicked", suggestion);
        const isCollectionResult =
            suggestion.resultType === "FOLDER" ||
            suggestion.resultType === "COLLECTION";
        const navRoot = isCollectionResult ? "collections" : "";
        const navigatePath = isCollectionResult
            ? suggestion.path.map((el) => el.id).join("/")
            : suggestion.path
                  .map((el) => el.type.toLowerCase() + "/" + el.id)
                  .join("/");

        if (!navigatePath) return;
        closeSearch();
        navigate(`${navRoot}/${navigatePath}`);
    };

    return (
        <div
            className={styles.listItem}
            onMouseDown={(e) => e.preventDefault()}
            onClick={clickHandler}
        >
            {suggestion.thumbnail && (
                <ExternalImageWithFallback
                    src={suggestion.thumbnail}
                    alt={suggestion.title + " thumbnail"}
                    hideOnError
                    className={styles.listItemImage}
                />
            )}
            <div className={styles.listItemData}>
                <div className={styles.listItemLogoAndTitle}>
                    <FontAwesomeIcon
                        icon={isFolderOrNode ? faFolder : faLayerGroup}
                        color={variables["text-colors-color-text-light-gray"]}
                        fontSize={16}
                    />
                    <Typography
                        variant="text-size-Base"
                        color="$text-colors-color-text-dark.main"
                        className={styles.title}
                    >
                        {suggestion.title}
                    </Typography>
                </div>
                <div className={styles.listItemPath}>
                    {suggestion.workspaceType === "WIZENOZE" && (
                        <Chip label={suggestion.workspaceName} />
                    )}
                    {suggestionParents.map((item, index) => (
                        <Fragment key={item.id}>
                            <Typography
                                variant="text-size-xxSmall-medium"
                                color="$icon-colors-green-60.main"
                            >
                                {item.title}
                            </Typography>
                            {index < suggestionParents.length - 1 && (
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    color={variables["icon-colors-green-60"]}
                                    fontSize={4}
                                />
                            )}
                        </Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SuggestionsListResultItem;
