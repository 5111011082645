import Grid from "@mui/material/Unstable_Grid2";

import useScreenSizes from "hooks/useScreenSizes";

import { ContentElementCardSkeleton } from "components/ContentElementCard/ContentElementCard";

const CollectionsTileSkeleton = () => {
    const { isDesktopScreen } = useScreenSizes();
    return (
        <>
            {new Array(isDesktopScreen ? 3 : 1).fill(null).map((index) => (
                <Grid key={index} lg={4} xs={12}>
                    <ContentElementCardSkeleton />
                </Grid>
            ))}
        </>
    );
};

export default CollectionsTileSkeleton;
