import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CollectionContext } from "../state/CollectionContext";
import MessagingContext from "contexts/MessagingContext";
import { tracker } from "utils/analytics";

import { GenerateCollectionQuizButton } from "./GenerateQuizButton/GenerateQuizButton";
import MobileCollectionDetailsPanel from "../DetailsPanel/MobileCollectionDetailsPanel";
import EditorSubheader from "components/EditorSubheader/EditorSubheader";
import { MessageModalProps } from "components/MessageModal/MessageModal";

const CollectionEditorSubheader = ({
    collectionTitle,
}: {
    collectionTitle: string;
}) => {
    const navigate = useNavigate();
    const { collectionId } = useParams();
    const { openMessageModal, openAlertMessage } = useContext(MessagingContext);
    const { collection, changeQueue, saveHandler, isSaving, deleteHandler } =
        useContext(CollectionContext);
    const deleteMessageModal: MessageModalProps = {
        isModalVisible: true,
        title: "Delete the collection",
        body: "Are you sure you want to delete the collection? You can't undo this action.",
        type: "Delete",
        primaryActionHandler: () => {
            deleteHandler();
            openAlertMessage({
                message: "Collection has been deleted.",
                type: "error",
                open: true,
            });
        },
    };
    const openQuizEditor = () => {
        tracker("Generate Quiz Button Clicked");
        navigate("/create-quiz", {
            state: {
                collectionId,
                collectionTitle: collectionTitle,
                collectionsParams: [parseInt(collectionId)],
            },
        });
    };
    return (
        <EditorSubheader
            additionalLeftComponents={[
                <MobileCollectionDetailsPanel key="mobilecollectiondetailspanel" />,
            ]}
            additionalRightComponents={[
                <GenerateCollectionQuizButton
                    clickHandler={openQuizEditor}
                    inEditMode
                    key="generatecollectionquizbutton"
                />,
            ]}
            deleteButtonProps={{
                isVisible: Boolean(collection.id),
                deleteHandler: () => openMessageModal(deleteMessageModal),
            }}
            saveButtonProps={{
                isDisabled: changeQueue.length === 0,
                isSaving,
                saveHandler,
            }}
        />
    );
};
export default CollectionEditorSubheader;
