import dayjs from "dayjs";
import { faCircle, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import * as styles from "./ChangeLogFooter.module.scss";
import variables from "styles/_variables.module.scss";

import Translate from "components/Translate/Translate";

interface Props {
    type: "Added by" | "Modified by";
    name: string;
    date: string;
}

const ChangeLogFooter = ({ type, name, date }: Props) => {
    return !name || !date ? (
        <></>
    ) : (
        <div className={styles.container}>
            <Typography
                variant="text-size-xSmall-regular"
                color="$text-colors-color-text-gray.main"
            >
                <Translate text={type} />
            </Typography>
            <Typography
                variant="text-size-xxSmall-medium"
                color="$icon-colors-green-100.main"
                className={styles.iconAndName}
            >
                <FontAwesomeIcon icon={faCircleUser} fontSize={14} />
                <span className={styles.name}>{name}</span>
            </Typography>
            <FontAwesomeIcon
                icon={faCircle}
                color={variables["icon-colors-green-60"]}
                fontSize={4}
            />
            <Typography
                variant="text-size-xxSmall-medium"
                color="$icon-colors-green-60.main"
            >
                {dayjs(date).format("DD/MM/YY")}
            </Typography>
        </div>
    );
};

export default ChangeLogFooter;
