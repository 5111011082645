import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { axiosAPI } from "./axiosAPI";
import { invalidateFolderCache, updateFolderCache } from "./folders";
import useCollectionsParams from "hooks/useCollectionsParams";
import { Quiz } from "types";

export const useCreateQuizMutation = () => {
    return useMutation(
        async ({
            parentId,
            quiz,
        }: {
            parentId: number | string;
            quiz: Quiz;
        }) => {
            return await axiosAPI
                .getInstance()
                .post(`/content/quiz/${parentId}/create`, {
                    ...quiz,
                });
        },
    );
};

export const useSaveQuizMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ quizId, quiz }: { quiz: Quiz; quizId: string | number }) => {
            return await axiosAPI
                .getInstance()
                .put(`/content/quiz/${quizId}/save/`, quiz);
        },
        {
            onSettled: (_, __, { quizId }) => {
                if (quizId)
                    queryClient.invalidateQueries(["quiz", quizId.toString()]);
            },
        },
    );
};
export const useMoveQuizMutation = () => {
    const queryClient = useQueryClient();
    const collectionsParams = useCollectionsParams();
    return useMutation(
        async ({ quiz, newParentId }: { quiz: Quiz; newParentId: number }) => {
            return await axiosAPI
                .getInstance()
                ?.put<Quiz>(
                    `/content/collection/move/${quiz.id}/to/${newParentId}`,
                    quiz,
                );
        },
        {
            onSuccess: ({ data }, { quiz }) =>
                updateFolderCache(
                    data,
                    collectionsParams,
                    queryClient,
                    "move",
                    quiz,
                ),
            onSettled: (_, __, { newParentId }) => {
                invalidateFolderCache(collectionsParams, queryClient);
                queryClient.invalidateQueries(["folder", newParentId]);
                queryClient.invalidateQueries(["dashboard"]);
            },
        },
    );
};

export const useDeleteQuizMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            quizId,
        }: {
            quizId: number;
            collectionsParams?: number[];
        }) => {
            return await axiosAPI
                .getInstance()
                ?.delete(`/content/quiz/${quizId}`);
        },
        {
            onSuccess: ({ data }, { collectionsParams }) =>
                collectionsParams &&
                updateFolderCache(
                    data,
                    collectionsParams,
                    queryClient,
                    "delete",
                ),
            onSettled: (_, __, { collectionsParams }) => {
                collectionsParams &&
                    invalidateFolderCache(collectionsParams, queryClient);
                queryClient.invalidateQueries(["dashboard"]);
            },
        },
    );
};

export const useDeleteQuizFromCollectionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ collectionId }: { collectionId: number | string }) => {
            return await axiosAPI
                .getInstance()
                .delete(`/content/quiz/${collectionId}/`);
        },
        {
            onSettled: (_, __, { collectionId }) =>
                queryClient.invalidateQueries([
                    "collection",
                    collectionId.toString(),
                ]),
        },
    );
};

export const useQuizQuery = (quizId?: number | string) => {
    return useQuery<Quiz, AxiosError>(
        ["quiz", quizId?.toString()],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/content/quiz/${quizId}/quiz`);
            return data;
        },
        { enabled: !!quizId },
    );
};

export const useShareQuizQuery = (
    collectionId?: number | string,
    hash?: number | string,
) => {
    return useQuery<Quiz, AxiosError>(
        ["share-quiz", collectionId, hash],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/content/share/quiz/`, {
                    params: { collectionId, hash },
                });
            return data;
        },
        { enabled: !!collectionId && !!hash },
    );
};

interface GenerateQuestionsPayload {
    title: string;
    topic: string;
    numberOfQuestions: number;
    questionTypes: Array<"MULTIPLE_CHOICE" | "TRUE_FALSE">;
    collectionId?: number;
    suggestedSourcesContentShareIds: number[];
}

interface GenerateQuestionsParams {
    title: string;
    topic: string;
    numberOfQuestions: number;
    trueOrFalse: boolean;
    multipleChoice: boolean;
    collectionId: number | undefined;
    suggestedSourcesIds: number[];
}
export const useGenerateQuestionsMutation = () => {
    return useMutation(
        async ({
            title,
            topic,
            numberOfQuestions,
            trueOrFalse,
            multipleChoice,
            collectionId,
            suggestedSourcesIds,
        }: GenerateQuestionsParams) => {
            const questionTypes: GenerateQuestionsPayload["questionTypes"] = [];
            if (trueOrFalse) questionTypes.push("TRUE_FALSE");
            if (multipleChoice) questionTypes.push("MULTIPLE_CHOICE");
            const generateQuestionsPayload: GenerateQuestionsPayload = {
                title,
                topic,
                numberOfQuestions,
                questionTypes,
                collectionId,
                suggestedSourcesContentShareIds: suggestedSourcesIds,
            };
            return await axiosAPI
                .getInstance()
                .post(
                    `/content/quiz/generate/questions`,
                    generateQuestionsPayload,
                );
        },
    );
};

type SendQuizEmailDetails = {
    studentName: string;
    numberOfCorrectAnswers: number;
    questionsFailed: Array<string>;
};
export const useSendQuizEmailMutation = (
    elementId: number | string,
    hash: string,
) => {
    return useMutation(async (sendQuizEmailDetails: SendQuizEmailDetails) => {
        await axiosAPI
            .getInstance()
            .post(
                `/content/share/${elementId}/email?shareId=${elementId}&hash=${hash}`,
                sendQuizEmailDetails,
            );
    });
};
