@use "../../../../../styles/variables.module";

.numberButton {
    width: 10%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
