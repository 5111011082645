import { useState } from "react";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { axiosAPI } from "hooks/api/axiosAPI";
import useUserQuery from "hooks/api/useUserQuery";
import { ContentElement, Folder, Role } from "types";

import DialogBox from "components/DialogBox/DialogBox";

const useChangeRoleMutation = () => {
    return useMutation(
        async ({
            elementId,
            userId,
            role,
        }: {
            elementId: string;
            userId: string;
            role: Role;
        }) => {
            return await axiosAPI
                .getInstance()
                .post(`/content/collaboration/role/grant`, {
                    userId,
                    shareId: elementId,
                    role,
                });
        },
    );
};

const UserRoleDialog = ({
    element,
    isVisible,
    setIsVisible,
}: {
    element: ContentElement | Folder;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const changeRoleMutation = useChangeRoleMutation();
    const { data: user } = useUserQuery();
    const [elementId, setElementId] = useState(element.id);
    const [userId, setUserId] = useState(user?.me.id);
    const [role, setRole] = useState<Role>("VIEWER");
    const submitHandler = () => {
        if (!userId || !elementId || !role) return;
        changeRoleMutation.mutate({
            elementId: elementId.toString(),
            userId: userId.toString(),
            role,
        });
    };
    return (
        <DialogBox
            isOpen={isVisible}
            closeHandler={() => setIsVisible(false)}
            title="Move to"
            withCloseButton
            styleOverrides={{ width: "435px", height: "unset" }}
            secondaryButton={{
                label: "Cancel",
                clickHandler: () => setIsVisible(false),
            }}
            primaryButton={{
                label: "Save",
                clickHandler: submitHandler,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    backgroundColor:
                        "$background-colors-color-background-light.main",
                }}
            >
                <div className="App">
                    <h2>Change Role</h2>
                    <div>
                        <label>UserId:</label>
                        <input
                            type="number"
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            style={{ marginLeft: 10 }}
                        />
                    </div>
                    <div>
                        <label>CollectionId:</label>
                        <input
                            type="number"
                            value={elementId}
                            onChange={(e) => setElementId(e.target.value)}
                            style={{ marginLeft: 10 }}
                        />
                    </div>
                    <div>
                        <label>Role:</label>
                        <select
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            style={{ marginLeft: 10 }}
                        >
                            <option value="VIEWER">VIEWER</option>
                            <option value="EDITOR">EDITOR</option>
                            <option value="ADMIN">ADMIN</option>
                        </select>
                    </div>
                </div>
            </Box>
        </DialogBox>
    );
};

export default UserRoleDialog;
