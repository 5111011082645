import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { faShare, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Typography } from "@mui/material";

import * as styles from "./Subheader.module.scss";
import { useFullWorkspacesQuery, useSelectedElements } from "hooks/api/folders";
import { useQuizQuery } from "hooks/api/quizzes";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { AnyElement, ContainerElement } from "types";
import { tracker } from "utils/analytics";

import EditButton from "./EditButton";
import NewElementContextMenu from "./NewElementContextMenu";
import NewFolderDialog from "./NewFolderDialog";
import QuizPreviewButton from "./QuizPreviewButton";
import SortByContextMenu from "./SortByContextMenu";
import TutorShareButton from "./TutorShareButton";
import ViewAsContextMenu from "./ViewAsContextMenu";
import MobileCollectionsExplorer from "components/CollectionsExplorerPanel/MobileCollectionsExplorer";
import ShareElementDialog from "components/ContextMenu/dialogs/ShareElementDialog";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";
import Translate from "components/Translate/Translate";
import { GenerateCollectionQuizButton } from "features/CollectionEditor/Subheader/GenerateQuizButton/GenerateQuizButton";
import QuizTaker from "features/QuizTaker/QuizTaker";

interface ShareButtonProps {
    clickHandler: () => void;
    isCollection: boolean;
    showLabel: boolean;
    disabled: boolean;
}

const ShareButton = ({
    clickHandler,
    showLabel,
    isCollection,
    disabled,
}: ShareButtonProps) => (
    <Button
        variant="outlined"
        className={styles.shareButton}
        disabled={disabled}
        onClick={clickHandler}
    >
        <FontAwesomeIcon icon={faShare} fontSize={12} />
        {showLabel && (
            <Typography variant="text-size-xSmall-bold">
                <Translate text={isCollection ? "Share Collection" : "Share"} />
            </Typography>
        )}
    </Button>
);

interface Props {
    viewAs: ContainerElement["view"];
    setViewAs: (viewAs: ContainerElement["view"]) => void;
    sortBy: ContainerElement["sortBy"];
    setSortBy: (sortBy: ContainerElement["sortBy"]) => void;
    generateQuizHandler: () => void;
}

const Subheader = ({
    viewAs,
    setViewAs,
    sortBy,
    setSortBy,
    generateQuizHandler,
}: Props) => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const collectionsParams = useCollectionsParams();
    const selectedElements = useSelectedElements();
    const selectedElement = selectedElements.at(-1) as AnyElement | undefined;
    const [isToolboxPanelOpen, setIsToolboxPanelOpen] = useState(false);
    const [isNewMenuOpen, setIsNewMenuOpen] = useState(false);
    const [isViewMenuOpen, setIsViewMenuOpen] = useState(false);
    const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isNewFolderModalVisible, setIsNewFolderModalVisible] =
        useState(false);
    const [isQuizPreviewOpen, setIsQuizPreviewOpen] = useState(false);

    const elementClickHandler = (element: AnyElement, pathString?: string) => {
        if (!pathString) return;
        tracker("Element clicked", element);
        navigate(pathString);
    };
    const { data: fullWorkspacesData } = useFullWorkspacesQuery();
    const selectedIsWorkspace =
        selectedElement && selectedElement.type === "WORKSPACE";
    const selectedIsFolder =
        selectedElement && selectedElement.type === "FOLDER";
    const selectedIsCollection =
        selectedElement && selectedElement.type === "COLLECTION";
    const selectedIsQuiz = selectedElement && selectedElement.type === "QUIZ";
    const { data: quizData } = useQuizQuery(
        selectedIsCollection ? selectedElement.quiz?.id : undefined,
    );
    const closeQuizPreviewHandler = () => {
        tracker("Preview quiz closed");
        setIsQuizPreviewOpen(false);
    };
    return (
        <div className={styles.subheader}>
            <div className={styles.subheaderGroup}>
                <div className={styles.editAndMobileExplorer}>
                    {(selectedIsCollection || selectedIsQuiz) && (
                        <EditButton
                            disabled={
                                !selectedElement.permissions?.includes("UPDATE")
                            }
                        />
                    )}
                    {!isDesktopScreen && (
                        <MobileCollectionsExplorer
                            items={fullWorkspacesData}
                            selectedIds={collectionsParams}
                            elementClickHandler={elementClickHandler}
                        />
                    )}
                </div>
                {!isMobileScreen ? (
                    (!selectedElement ||
                        selectedIsWorkspace ||
                        selectedIsFolder) && (
                        <>
                            <NewElementContextMenu
                                isVisible={isNewMenuOpen}
                                setIsVisible={setIsNewMenuOpen}
                                setIsNewFolderModalVisible={
                                    setIsNewFolderModalVisible
                                }
                                disabled={
                                    !selectedElement ||
                                    !selectedElement.permissions?.includes(
                                        "CREATE",
                                    )
                                }
                            />
                            <Divider orientation="vertical" />
                            <ViewAsContextMenu
                                viewAs={viewAs}
                                updateViewAs={(viewAs) => {
                                    setViewAs(viewAs);
                                }}
                                isVisible={isViewMenuOpen}
                                setIsVisible={setIsViewMenuOpen}
                                disabled={
                                    !selectedElement ||
                                    !selectedElement.permissions?.includes(
                                        "UPDATE",
                                    )
                                }
                            />
                            <SortByContextMenu
                                sortBy={sortBy}
                                updateSortBy={(sortBy) => {
                                    setSortBy(sortBy);
                                }}
                                isVisible={isSortMenuOpen}
                                setIsVisible={setIsSortMenuOpen}
                                disabled={
                                    !selectedElement ||
                                    !selectedElement.permissions?.includes(
                                        "UPDATE",
                                    )
                                }
                            />
                        </>
                    )
                ) : (
                    <>
                        {(!selectedElement ||
                            selectedIsWorkspace ||
                            selectedIsFolder) && (
                            <MenuButton
                                faIcon={faToolbox}
                                iconColor={variables["icon-colors-blue-100"]}
                                primaryColor={variables["icon-colors-blue-40"]}
                                secondaryColor={
                                    variables["icon-colors-blue-60"]
                                }
                                isOpen={isToolboxPanelOpen}
                                styleOverrides={{
                                    width: "30px",
                                    minWidth: "30px",
                                    height: "30px",
                                    fontSize: "12px",
                                }}
                                onClick={() => {
                                    tracker("Toolbox panel menu open");
                                    setIsToolboxPanelOpen(true);
                                }}
                            />
                        )}
                        <div style={{ display: "flex", gap: 20 }}>
                            {selectedIsCollection && (
                                <>
                                    <GenerateCollectionQuizButton
                                        clickHandler={generateQuizHandler}
                                        isDisabled={
                                            !selectedElement.permissions?.includes(
                                                "UPDATE",
                                            ) || !!quizData
                                        }
                                    />
                                    <TutorShareButton
                                        collectionId={selectedElement.id}
                                        showLabel={isDesktopScreen}
                                        disabled={
                                            !selectedElement ||
                                            selectedIsWorkspace ||
                                            !selectedElement.permissions?.includes(
                                                "SHARE",
                                            )
                                        }
                                    />
                                </>
                            )}
                            {selectedIsQuiz && (
                                <QuizPreviewButton
                                    clickHandler={() =>
                                        setIsQuizPreviewOpen(true)
                                    }
                                />
                            )}
                            <ShareButton
                                clickHandler={() => setIsShareModalOpen(true)}
                                isCollection={!!selectedIsCollection}
                                showLabel={false}
                                disabled={
                                    !selectedElement ||
                                    selectedIsWorkspace ||
                                    !selectedElement.permissions?.includes(
                                        "SHARE",
                                    )
                                }
                            />
                        </div>
                        <MenuContainer
                            isOpen={isToolboxPanelOpen}
                            closeMenu={() => setIsToolboxPanelOpen(false)}
                            containerRelativePosition="bottom-right"
                            fullWidthBreakpoint="xl"
                            fullHeightBreakpoint="xl"
                        >
                            <div className={styles.mobileContainer}>
                                <NewElementContextMenu
                                    isVisible={isNewMenuOpen}
                                    setIsVisible={setIsNewMenuOpen}
                                    setIsNewFolderModalVisible={(
                                        open: boolean,
                                    ) => {
                                        setIsToolboxPanelOpen(false);
                                        setIsNewFolderModalVisible(open);
                                    }}
                                    disabled={
                                        !selectedElement ||
                                        !selectedElement.permissions?.includes(
                                            "CREATE",
                                        )
                                    }
                                />
                                <ViewAsContextMenu
                                    viewAs={viewAs}
                                    updateViewAs={(viewAs) => {
                                        setViewAs(viewAs);
                                    }}
                                    isVisible={isViewMenuOpen}
                                    setIsVisible={setIsViewMenuOpen}
                                    disabled={
                                        !selectedElement ||
                                        !selectedElement.permissions?.includes(
                                            "UPDATE",
                                        )
                                    }
                                />
                                <SortByContextMenu
                                    sortBy={sortBy}
                                    updateSortBy={(sortBy) => {
                                        setSortBy(sortBy);
                                    }}
                                    isVisible={isSortMenuOpen}
                                    setIsVisible={setIsSortMenuOpen}
                                    disabled={
                                        !selectedElement ||
                                        !selectedElement.permissions?.includes(
                                            "UPDATE",
                                        )
                                    }
                                />
                            </div>
                        </MenuContainer>
                    </>
                )}
            </div>
            {!isMobileScreen && (
                <div style={{ display: "flex", gap: 20 }}>
                    {selectedIsCollection && (
                        <>
                            <GenerateCollectionQuizButton
                                clickHandler={generateQuizHandler}
                                isDisabled={
                                    !selectedElement.permissions?.includes(
                                        "UPDATE",
                                    ) || !!quizData
                                }
                            />
                            <TutorShareButton
                                collectionId={selectedElement.id}
                                showLabel={isDesktopScreen}
                                disabled={
                                    !selectedElement ||
                                    selectedIsWorkspace ||
                                    !selectedElement.permissions?.includes(
                                        "SHARE",
                                    )
                                }
                            />
                        </>
                    )}
                    {selectedIsQuiz && (
                        <QuizPreviewButton
                            clickHandler={() => setIsQuizPreviewOpen(true)}
                        />
                    )}
                    <ShareButton
                        clickHandler={() => setIsShareModalOpen(true)}
                        isCollection={!!selectedIsCollection}
                        showLabel={isDesktopScreen}
                        disabled={
                            !selectedElement ||
                            selectedIsWorkspace ||
                            !selectedElement.permissions?.includes("SHARE")
                        }
                    />
                </div>
            )}
            <NewFolderDialog
                isVisible={isNewFolderModalVisible}
                setIsVisible={setIsNewFolderModalVisible}
            />
            {selectedElement && !selectedIsWorkspace && (
                <ShareElementDialog
                    element={selectedElement}
                    isVisible={isShareModalOpen}
                    setIsVisible={setIsShareModalOpen}
                />
            )}
            {selectedIsQuiz && (
                <QuizTaker
                    quizData={selectedElement}
                    isOpen={isQuizPreviewOpen}
                    closeHandler={closeQuizPreviewHandler}
                    elementId={selectedElement.id}
                    elementHash={selectedElement.hash}
                    isPreview
                    withCloseButton
                />
            )}
        </div>
    );
};

export default Subheader;
