import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    faArrowRightToBracket,
    faHelicopter,
    faPen,
    faPenToSquare,
    faShareFromSquare,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

import useCollectionsParams from "hooks/useCollectionsParams";
import { ContentElement, Folder } from "types";
import { tracker } from "utils/analytics";

import ThreeDotContextMenu from "./ThreeDotContextMenu";
import EditFolderDialog from "./dialogs/EditFolderDialog";
import DeleteElementDialog from "./dialogs/DeleteElementDialog";
import MoveElementDialog from "./dialogs/MoveElementDialog";
import ShareElementDialog from "./dialogs/ShareElementDialog";
import UserRoleDialog from "./dialogs/UserRoleEditDialog";

const ElementThreeDotContextMenu = ({
    element,
    isHovered,
}: {
    element: ContentElement | Folder;
    isHovered: boolean;
}) => {
    const navigate = useNavigate();
    const collectionsParams = useCollectionsParams();
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [openDialogType, setOpenDialogType] = useState<
        "move" | "rename" | "share" | "delete" | "permissions" | undefined
    >();
    const isCollection = element.type === "COLLECTION";
    const isFolder = element.type === "FOLDER";
    const isQuiz = element.type === "QUIZ";
    const editHandler = () => {
        if (isCollection) {
            navigate(`/edit-collection/${element.id}`, {
                state: {
                    collectionsParams: collectionsParams,
                },
            });
        } else if (isQuiz) {
            navigate(`/edit-quiz/${element.id}`, {
                state: {
                    collectionsParams: collectionsParams,
                },
            });
        }
    };
    const moveToHandler = () => {
        tracker("Move Element Dialog Opened");
        setIsDialogVisible(true);
        setOpenDialogType("move");
    };
    const renameHandler = () => {
        tracker("Rename Element Dialog Opened");
        setIsDialogVisible(true);
        setOpenDialogType("rename");
    };
    const shareHandler = () => {
        tracker("Share Element Dialog Opened");
        setIsDialogVisible(true);
        setOpenDialogType("share");
    };
    const deleteHandler = () => {
        tracker("Delete Element Dialog Opened");
        setIsDialogVisible(true);
        setOpenDialogType("delete");
    };
    const permissionsHandler = () => {
        setIsDialogVisible(true);
        setOpenDialogType("permissions");
    };
    return (
        <>
            <ThreeDotContextMenu
                options={[
                    {
                        label: "Edit",
                        icon: faPenToSquare,
                        clickHandler: editHandler,
                        hidden:
                            (!isCollection && !isQuiz) ||
                            !element.permissions?.includes("UPDATE"),
                    },
                    {
                        label: "Move to",
                        icon: faArrowRightToBracket,
                        clickHandler: moveToHandler,
                        hidden: !element.permissions?.includes("MOVE"),
                    },
                    {
                        label: "Rename",
                        icon: faPen,
                        clickHandler: renameHandler,
                        hidden:
                            !isFolder ||
                            !element.permissions?.includes("UPDATE"),
                    },
                    {
                        label: "Share",
                        icon: faShareFromSquare,
                        clickHandler: shareHandler,
                        hidden: !element.permissions?.includes("SHARE"),
                    },
                    {
                        label: "Delete",
                        icon: faTrash,
                        clickHandler: deleteHandler,
                        hidden: !element.permissions?.includes("DELETE"),
                    },
                    {
                        label: "Permissions",
                        icon: faHelicopter,
                        clickHandler: permissionsHandler,
                        hidden: process.env.NODE_ENV === "production",
                    },
                ]}
                isHovered={isHovered}
            />
            {isDialogVisible && openDialogType === "move" && (
                <MoveElementDialog
                    element={element}
                    isVisible={isDialogVisible && openDialogType === "move"}
                    setIsVisible={setIsDialogVisible}
                />
            )}
            {isFolder && isDialogVisible && openDialogType === "rename" && (
                <EditFolderDialog
                    folder={element as Folder}
                    isVisible={isDialogVisible && openDialogType === "rename"}
                    setIsVisible={setIsDialogVisible}
                />
            )}
            {isDialogVisible && openDialogType === "share" && (
                <ShareElementDialog
                    element={element}
                    isVisible={isDialogVisible && openDialogType === "share"}
                    setIsVisible={setIsDialogVisible}
                />
            )}
            {isDialogVisible && openDialogType === "delete" && (
                <DeleteElementDialog
                    element={element}
                    isVisible={isDialogVisible && openDialogType === "delete"}
                    setIsVisible={setIsDialogVisible}
                />
            )}
            {process.env.NODE_ENV !== "production" &&
                isDialogVisible &&
                openDialogType === "permissions" && (
                    <UserRoleDialog
                        element={element}
                        isVisible={
                            isDialogVisible && openDialogType === "permissions"
                        }
                        setIsVisible={setIsDialogVisible}
                    />
                )}
        </>
    );
};

export default ElementThreeDotContextMenu;
