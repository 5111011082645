import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { axiosAPI } from "./axiosAPI";
import useCollectionsParams from "hooks/useCollectionsParams";
import { Collection, CollectionItem, Collections, Tag } from "types";
import { invalidateFolderCache, updateFolderCache } from "./folders";

export const useCreateCollectionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            collectionsParams,
            title,
            description,
            imageUrl,
            language,
        }: {
            collectionsParams: number[];
            title: string;
            description?: string;
            imageUrl?: string;
            language?: string;
        }) => {
            const parentId = collectionsParams[collectionsParams.length - 1];
            return await axiosAPI
                .getInstance()
                ?.post(`/content/folder/${parentId}/collection`, {
                    title,
                    description,
                    imageUrl,
                    language,
                });
        },
        {
            onSuccess: ({ data }, { collectionsParams }) =>
                updateFolderCache(data, collectionsParams, queryClient, "add"),
            onSettled: (_, __, { collectionsParams }) => {
                invalidateFolderCache(collectionsParams, queryClient);
                queryClient.invalidateQueries(["dashboard"]);
            },
        },
    );
};

export const useUpdateCollectionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            collection,
        }: {
            collection: Partial<Collection>;
            collectionsParams?: number[]; // optional to support landing page -> edit collection
        }) => {
            return await axiosAPI
                .getInstance()
                ?.put<Collection>(`/content/collection/${collection.id}`, {
                    ...collection,
                    language: collection.language,
                });
        },
        {
            onSuccess: ({ data }, { collectionsParams }) =>
                collectionsParams &&
                updateFolderCache(data, collectionsParams, queryClient, "edit"),
            onSettled: (_, __, { collectionsParams }) => {
                collectionsParams &&
                    invalidateFolderCache(collectionsParams, queryClient);
                queryClient.invalidateQueries(["dashboard"]);
                queryClient.invalidateQueries([
                    "collection",
                    collectionsParams?.at(-1),
                ]);
            },
        },
    );
};

export const useMoveCollectionMutation = () => {
    const queryClient = useQueryClient();
    const collectionsParams = useCollectionsParams();
    return useMutation(
        async ({
            collection,
            newParentId,
        }: {
            collection: Collection;
            newParentId: number;
        }) => {
            return await axiosAPI
                .getInstance()
                ?.put<Collection>(
                    `/content/collection/move/${collection.id}/to/${newParentId}`,
                    {
                        ...collection,
                        language: collection.language,
                    },
                );
        },
        {
            onSuccess: ({ data }, { collection: collection }) =>
                updateFolderCache(
                    data,
                    collectionsParams,
                    queryClient,
                    "move",
                    collection,
                ),
            onSettled: (_, __, { newParentId }) => {
                invalidateFolderCache(collectionsParams, queryClient);
                queryClient.invalidateQueries(["folder", newParentId]);
                queryClient.invalidateQueries(["dashboard"]);
            },
        },
    );
};

export const useDeleteCollectionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            collectionId,
        }: {
            collectionId: number;
            collectionsParams?: number[]; // optional to support landing page -> edit collection
        }) => {
            return await axiosAPI
                .getInstance()
                ?.delete(`/content/collection/${collectionId}`);
        },
        {
            onSuccess: ({ data }, { collectionsParams }) =>
                collectionsParams &&
                updateFolderCache(
                    data,
                    collectionsParams,
                    queryClient,
                    "delete",
                ),
            onSettled: (_, __, { collectionsParams }) => {
                collectionsParams &&
                    invalidateFolderCache(collectionsParams, queryClient);
                queryClient.invalidateQueries(["dashboard"]);
            },
        },
    );
};

export const useCollectionsPanelQuery = () => {
    return useQuery<Collections, AxiosError>(["dashboard"], async () => {
        const { data } = await axiosAPI
            .getInstance()
            .get(`/content/dashboard`, { params: { page: 1, size: 3 } });
        return data;
    });
};

export const useCollectionQuery = (
    collectionId: number | string | undefined,
    hash?: string,
) => {
    return useQuery<Collection, AxiosError>(
        ["collection", collectionId?.toString()],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(
                    `/user/collection/${
                        hash
                            ? `share?collectionId=${collectionId}&hash=${hash}`
                            : collectionId
                    }`,
                );
            return { ...data, language: data.language };
        },
        {
            enabled: !!collectionId,
        },
    );
};

/* ITEMS */

export const useItemsInCollectionQuery = (collectionId?: number | string) => {
    return useQuery<CollectionItem[], AxiosError>(
        ["items-in-collection", collectionId?.toString()],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/content/collection/${collectionId}/item`);
            return data?.results;
        },

        { enabled: !!collectionId },
    );
};

export const useViewItemsInCollectionQuery = (params?: {
    id?: number | string;
    hash?: string;
}) => {
    return useQuery<CollectionItem[], AxiosError>(
        ["items-in-collection", params?.id?.toString()],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(
                    `/user/collection/share/item?collectionId=${params?.id}&hash=${params?.hash}`,
                );
            return data?.results;
        },
        { enabled: !!params && !!params.id && !!params.hash },
    );
};

interface AddItemsToCProps {
    item: CollectionItem;
    collectionId: number | string;
}
export const useAddItemToCollectionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ item, collectionId }: AddItemsToCProps) => {
            return await axiosAPI
                .getInstance()
                ?.put<CollectionItem>(
                    `/content/collection/${collectionId}/item`,
                    item,
                );
        },
        {
            onSettled: (_, __, { collectionId }) =>
                queryClient.invalidateQueries({
                    queryKey: ["items-in-collection", collectionId.toString()],
                }),
        },
    );
};

interface UpdateOrderOfItemsProps {
    items: Array<{ id: string; item_order: number }>;
    collectionId: number | string;
}
// Note: Provide all items in the collection, even if order hasn't changed
export const useUpdateOrderOfItemsMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ items, collectionId }: UpdateOrderOfItemsProps) => {
            return await axiosAPI
                .getInstance()
                ?.put<
                    CollectionItem[]
                >(`/content/collection/${collectionId}/item/order`, {
                    items,
                });
        },
        {
            onSettled: (_, __, { collectionId }) =>
                queryClient.invalidateQueries({
                    queryKey: ["items-in-collection", collectionId.toString()],
                }),
        },
    );
};

interface DeleteItemsInCProps {
    collectionId: number | string;
    itemId: number | string;
}
export const useDeleteItemInCollectionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ collectionId, itemId }: DeleteItemsInCProps) => {
            return await axiosAPI
                .getInstance()
                ?.delete(`/content/collection/${collectionId}/item/${itemId}`);
        },
        {
            onSettled: (_, __, { collectionId }) =>
                queryClient.invalidateQueries({
                    queryKey: ["items-in-collection", collectionId.toString()],
                }),
        },
    );
};

/* TAGS */
interface CreateTagProps {
    tag: string;
}
export const useCreateTagMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ tag }: CreateTagProps) => {
            return await axiosAPI
                .getInstance()
                ?.post<Tag>(`/user/tag`, { tag });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ["tags"],
                });
            },
        },
    );
};

interface AddTagsToCProps {
    collectionId: number | string;
    tagId: number | string;
}
export const useAddTagToCollectionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ collectionId, tagId }: AddTagsToCProps) => {
            return await axiosAPI
                .getInstance()
                ?.post<Tag>(`/content/collection/${collectionId}/tag/${tagId}`);
        },
        {
            onSettled: (_, __, { collectionId }) => {
                setTimeout(
                    () =>
                        queryClient.invalidateQueries({
                            queryKey: ["collection", collectionId.toString()],
                        }),
                    100,
                );
            },
        },
    );
};

interface DeleteTagsToCProps {
    tagId: number;
    collectionId: number;
}
export const useDeleteTagFromCollectionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ tagId, collectionId }: DeleteTagsToCProps) => {
            return await axiosAPI
                .getInstance()
                ?.delete(`/content/collection/${collectionId}/tag/${tagId}`);
        },
        {
            onSettled: (_, __, { collectionId }) => {
                queryClient.invalidateQueries({
                    queryKey: ["collection", collectionId.toString()],
                });
            },
        },
    );
};

export const useGetAllTagsQuery = (userId?: number) => {
    return useQuery<Array<Tag>, AxiosError>(
        ["all-tags", userId],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/content/collection/${userId}/tag`);
            return data;
        },
        { enabled: !!userId },
    );
};
