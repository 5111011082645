@use "../../../../styles/variables.module";

.footer {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    align-self: stretch;
}

.footerButton {
    padding: 10px 20px;
    height: 30px;
    gap: 10px;
}

.footerText {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: variables.$text-colors-color-text-blue;
}
