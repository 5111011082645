import { faEye, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import * as styles from "./SourceCard.module.scss";
import { useQuizEditorContext } from "features/QuizEditor/state/QuizEditorContext";
import variables from "styles/_variables.module.scss";
import { SuggestedSource } from "types";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

interface Props {
    source: SuggestedSource;
}

export const SourceViewerCard = ({ source }: Props) => {
    return (
        <div className={styles.container}>
            <input
                type="checkbox"
                checked
                disabled
                className={styles.checkbox}
            />
            <div className={styles.body}>
                <div className={styles.logoAndTitle}>
                    <FontAwesomeIcon
                        icon={faLayerGroup}
                        color={variables["text-colors-color-text-blue"]}
                        fontSize={12}
                        style={{ paddingTop: "4px" }}
                    />
                    <Typography
                        variant="text-size-Small-bold"
                        color="$text-colors-color-text-dark.main"
                    >
                        {source.title}
                    </Typography>
                </div>
                {source.shareLink && (
                    <div
                        className={styles.logoAndTitle}
                        style={{ alignSelf: "flex-end", cursor: "pointer" }}
                        onClick={() => window.open(source.shareLink)?.focus()}
                    >
                        <FontAwesomeIcon
                            icon={faEye}
                            color={variables["text-colors-color-text-blue"]}
                            fontSize={12}
                            style={{ paddingTop: "4px" }}
                        />
                        <Typography
                            variant="text-size-Small-medium"
                            color="$text-colors-color-text-blue.main"
                            style={{ textDecoration: "underline" }}
                        >
                            <Translate text="Preview" />
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

const SourceCard = ({ source }: Props) => {
    const { suggestedSource, setSuggestedSource } = useQuizEditorContext();
    const isChecked = source.id === suggestedSource?.id;
    return (
        <div className={styles.container}>
            <input
                type="checkbox"
                checked={isChecked}
                onChange={() => {
                    tracker("Source selected", source);
                    if (isChecked) {
                        setSuggestedSource(null);
                    } else {
                        setSuggestedSource(source);
                    }
                }}
                className={styles.checkbox}
            />
            <div className={styles.body}>
                <div className={styles.logoAndTitle}>
                    <FontAwesomeIcon
                        icon={faLayerGroup}
                        color={variables["text-colors-color-text-blue"]}
                        fontSize={12}
                        style={{ paddingTop: "4px" }}
                    />
                    <Typography
                        variant="text-size-Small-bold"
                        color="$text-colors-color-text-dark.main"
                    >
                        {source.title}
                    </Typography>
                </div>
                {source.shareLink && (
                    <div
                        className={styles.logoAndTitle}
                        style={{ alignSelf: "flex-end", cursor: "pointer" }}
                        onClick={() => window.open(source.shareLink)?.focus()}
                    >
                        <FontAwesomeIcon
                            icon={faEye}
                            color={variables["text-colors-color-text-blue"]}
                            fontSize={12}
                            style={{ paddingTop: "4px" }}
                        />
                        <Typography
                            variant="text-size-Small-medium"
                            color="$text-colors-color-text-blue.main"
                            style={{ textDecoration: "underline" }}
                        >
                            <Translate text="Preview" />
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SourceCard;
