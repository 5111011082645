import { useState } from "react";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
    faCirclePlus,
    faPenToSquare,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Skeleton, Typography } from "@mui/material";

import * as styles from "./QuestionEditorCard.module.scss";
import { useQuizEditorContext } from "../../../state/QuizEditorContext";
import { useMessagingContext } from "contexts/MessagingContext";
import variables from "styles/_variables.module.scss";
import { Question } from "types";
import { tracker } from "utils/analytics";

import AnswerEditor from "./AnswerEditor";
import DragHandle from "./DragHandle";
import Translate from "components/Translate/Translate";
import EditableTitle from "features/CollectionEditor/EditableTitle/EditableTitle";

export const QuestionEditorCardSkeleton = () => {
    return (
        <Skeleton
            variant="rectangular"
            min-height="177px"
            height="177px"
            style={{ borderRadius: "15px" }}
        />
    );
};

interface Props {
    question: Question;
}

const QuestionEditorCard = ({ question }: Props) => {
    const { openMessageModal } = useMessagingContext();
    const {
        questions,
        setQuestions,
        setIsDirty,
        questionsInEditMode,
        setQuestionsInEditMode,
    } = useQuizEditorContext();
    const [inEditMode, setInEditMode] = useState(
        questionsInEditMode.includes(question.id),
    );
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: question.id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
    };
    const [localQuestion, setLocalQuestion] = useState(question);
    const enterEditQuestionHandler = () => {
        tracker("Enter Edit Question Mode");
        setQuestionsInEditMode([...questionsInEditMode, question.id]);
        setInEditMode(true);
    };
    const saveEditQuestionHandler = () => {
        if (localQuestion.question === "") {
            openMessageModal({
                isModalVisible: true,
                title: "Title cannot be empty",
                body: "You can't save a question with an empty title. Please add text to your title.",
                type: "OK",
                primaryActionHandler: () => undefined,
            });
        } else if (localQuestion.answers.some((answer) => answer.text === "")) {
            openMessageModal({
                isModalVisible: true,
                title: "Option cannot be empty",
                body: "You can't save a question with an empty option. Please add text to all your options or delete empty options before saving a question.",
                type: "OK",
                primaryActionHandler: () => undefined,
            });
        } else {
            tracker("Save Edit Question");
            setQuestionsInEditMode(
                questionsInEditMode.filter((el) => el !== localQuestion.id),
            );
            if (localQuestion !== question) {
                const auxQuestions = [...questions];
                auxQuestions[
                    auxQuestions.findIndex((el) => el.id === localQuestion.id)
                ] = localQuestion;
                setQuestions(auxQuestions);
                setIsDirty(true);
            } else {
                setLocalQuestion(question);
            }
            setInEditMode(false);
        }
    };
    const cancelEditQuestionHandler = () => {
        tracker("Cancel Edit Question");
        setQuestionsInEditMode(
            questionsInEditMode.filter((el) => el !== localQuestion.id),
        );
        setLocalQuestion(question);
        setInEditMode(false);
    };
    const deleteItemHandler = () => {
        setQuestions(questions.filter((el) => el.id !== question.id));
        setIsDirty(true);
        tracker("Question Removed");
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const answersIds = localQuestion.answers.map((answer) => answer.id);
            const localAnswers = arrayMove(
                localQuestion.answers,
                answersIds.indexOf(active.id),
                answersIds.indexOf(over.id),
            );
            setLocalQuestion({
                ...localQuestion,
                answers: localAnswers,
            });
            tracker("Options Reordered");
        }
    };
    const addOptionHandler = () => {
        setLocalQuestion({
            ...localQuestion,
            answers: [
                ...localQuestion.answers,
                { id: Date.now().toString(), text: "" },
            ],
        });
        tracker("Add option button clicked");
    };
    return (
        <div ref={setNodeRef} style={style} className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerDragAndQuestion}>
                    <DragHandle
                        draggable={{
                            attributes: attributes,
                            listeners: listeners,
                        }}
                    />
                    {inEditMode ? (
                        <EditableTitle
                            title={localQuestion.question}
                            titleChangeHandler={(title: string) =>
                                setLocalQuestion({
                                    ...localQuestion,
                                    question: title,
                                })
                            }
                            typographyVariant="text-size-Base-bold"
                            styleOverrides={{
                                margin: "unset",
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "unset",
                            }}
                        />
                    ) : (
                        <Typography
                            variant="text-size-Base-bold"
                            color="$text-colors-color-text-dark.main"
                        >
                            {question.question}
                        </Typography>
                    )}
                </div>
                <div className={styles.buttonsContainer}>
                    {!inEditMode ? (
                        <>
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                color={variables["icon-colors-blue-100"]}
                                cursor={"pointer"}
                                fontSize={14}
                                style={{
                                    padding: "5px 10px",
                                    background: "#DFECFD",
                                    borderRadius: "5px",
                                }}
                                onClick={enterEditQuestionHandler}
                            />
                            <FontAwesomeIcon
                                icon={faTrash}
                                color={
                                    variables[
                                        "message-colors-color-message-danger"
                                    ]
                                }
                                cursor={"pointer"}
                                fontSize={14}
                                style={{
                                    padding: "5px 10px",
                                    background: "#FDEBED",
                                    borderRadius: "5px",
                                }}
                                onClick={deleteItemHandler}
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                className={styles.save}
                                onClick={saveEditQuestionHandler}
                            >
                                <Typography variant="text-size-xSmall-bold">
                                    <Translate text="Save" />
                                </Typography>
                            </Button>
                            <Button
                                variant="outlined"
                                className={styles.cancel}
                                onClick={cancelEditQuestionHandler}
                            >
                                <Typography variant="text-size-xSmall-bold">
                                    <Translate text="Cancel" />
                                </Typography>
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <div className={styles.answers}>
                    <SortableContext
                        items={localQuestion.answers}
                        strategy={verticalListSortingStrategy}
                    >
                        {localQuestion.answers.map((answer) => (
                            <AnswerEditor
                                key={answer.id}
                                answer={answer}
                                localQuestion={localQuestion}
                                setLocalQuestion={setLocalQuestion}
                                inEditMode={inEditMode}
                            />
                        ))}
                    </SortableContext>
                    {inEditMode && localQuestion.answers.length < 4 && (
                        <div
                            className={styles.answer}
                            style={{ cursor: "pointer" }}
                            onClick={addOptionHandler}
                        >
                            <FontAwesomeIcon
                                icon={faCirclePlus}
                                color={variables["icon-colors-blue-100"]}
                                fontSize={17}
                            />
                            <Typography
                                variant="text-size-Small-medium"
                                color="$text-colors-color-text-blue.main"
                            >
                                <Translate text="Add option" />
                            </Typography>
                        </div>
                    )}
                </div>
            </DndContext>
        </div>
    );
};

export default QuestionEditorCard;
