@use "../../../../styles/variables.module";

.container {
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    background: rgba(243, 243, 246, 0.7);
    backdrop-filter: blur(5px);
    border: 1px solid variables.$border-colors-color-border-light;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.curiculumAndGrade {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    overflow: hidden;
}

.curriculum {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
}

.footer {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    background: rgba(243, 243, 246, 0.3);
    backdrop-filter: blur(5px);
    border-top: 1px solid variables.$border-colors-color-border-light;
}

.footerEdit {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    border-radius: 5px;
    background: #dfecfd;
}

.footerText {
    display: flex;
    gap: 10px;
    color: variables.$text-colors-color-text-blue;
}
