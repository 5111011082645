import { Typography } from "@mui/material";

import * as styles from "./QuizEditorSteps.module.scss";
import questionMarkSVG from "images/question-mark.webp";

import { CreationStepsGraphic } from "./CreationStepsGraphic";
import Translate from "components/Translate/Translate";

const EditorInfoBox = () => (
    <div className={styles.mainTitle}>
        <img
            src={questionMarkSVG}
            alt="Quiz Icon"
            style={{ maxHeight: "60px" }}
        />
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
            }}
        >
            <Typography variant="text-size-Base-bold" color="#9B1DE8">
                Wizenoze Quiz
            </Typography>
            <Typography
                variant="text-size-Base"
                color="text-colors-color-text-dark.main"
            >
                <Translate text="Interactive way to enhance learning." />
            </Typography>
        </div>
    </div>
);

const QuizEditorSteps = () => {
    return (
        <div className={styles.container}>
            <EditorInfoBox />
            <Typography
                variant="text-size-Medium"
                color="text-colors-color-text-dark.main"
            >
                <Translate text="Create a smart and interactive way to enhance learning." />
            </Typography>
            <Typography
                variant="text-size-Base"
                color="text-colors-color-text-gray.main"
            >
                <Translate text="Our quiz feature is designed to help teachers create quizzes faster and more efficiently! With our AI-powered quiz generator, you can quickly generate personalised questions based on the subject and level you're teaching. The AI automatically tailors questions to suit the needs of your students, saving you time on quiz creation while ensuring the content is relevant and challenging." />
            </Typography>
            <CreationStepsGraphic />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography
                    variant="text-size-Medium"
                    color="text-colors-color-text-dark.main"
                >
                    <Translate text="Wizenoze Quiz feature is currently in beta" />
                </Typography>
                <Typography
                    variant="text-size-Base"
                    color="text-colors-color-text-gray.main"
                >
                    <Translate text="Please be aware that this is an early beta version and as such the quality of results may vary. Like (or dislike) your experience with our new tool? Please reach out to us at" />{" "}
                    <a
                        href="mailto:support@wizenoze.com"
                        style={{ textDecoration: "none" }}
                    >
                        support@wizenoze.com
                    </a>
                    {"."}
                </Typography>
            </div>
        </div>
    );
};

export default QuizEditorSteps;
