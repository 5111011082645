import { useNavigate } from "react-router-dom";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./CreateQuizTile.module.scss";
import quizIcon from "images/question-mark.webp";
import { useFullWorkspacesQuery } from "hooks/api/folders";
import { tracker } from "utils/analytics";

import DashboardTile from "features/DashboardPage/components/DashboardTile/DashboardTile";

const CreateQuizTile = () => {
    const navigate = useNavigate();
    const { data: fullWorkspacesData } = useFullWorkspacesQuery();
    const userPrivateWorkspace = fullWorkspacesData?.find(
        (workspace) => workspace.access === "PRIVATE",
    );
    const clickHandler = () => {
        tracker("Create a quiz tile clicked");
        navigate("/create-quiz", {
            state: { collectionsParams: [userPrivateWorkspace?.id] },
        });
    };
    return (
        <DashboardTile
            headerTitle={{
                text: "Create a quiz",
            }}
            headerIcon={{
                icon: faWandMagicSparkles,
                color: "linear-gradient(180deg, #BA29B5 0%, #9B1DE8 100%)",
                backgroundColor: "#ffe3fe",
            }}
            footer="Create a quiz using our advanced AI quiz generator"
            clickHandler={clickHandler}
            styleOverrides={{
                justifyContent: "flex-start",
                background: "linear-gradient(180deg, #BA29B5 0%, #9B1DE8 100%)",
                border: "1px solid #9b1dE8",
            }}
        >
            <div className={styles.container}>
                <img
                    src={quizIcon}
                    alt="Quiz Icon"
                    style={{ maxWidth: "100%", maxHeight: "120px" }}
                />
            </div>
        </DashboardTile>
    );
};

export default CreateQuizTile;
