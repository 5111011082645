@use "../../../../styles/variables.module";

.subheader {
    padding: 10px 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: variables.$background-colors-color-background-white;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
    @media (max-width: variables.$bp-md) {
        padding: 10px;
    }
}

.subheaderGroup {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width: variables.$bp-md) {
        justify-content: space-between;
    }
}

.editAndMobileExplorer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.editButton {
    height: 30px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.newButton {
    width: 100%;
    height: 30px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.previewQuizButton {
    height: 30px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media (max-width: variables.$bp-md) {
        padding: 10px;
        width: 30px;
        color: variables.$icon-colors-blue-100;
        background: variables.$icon-colors-blue-40;
        border-radius: 50%;
        border: 1px solid variables.$icon-colors-blue-60;
    }
}

.mobileContainer {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: variables.$background-colors-color-background-white;
}

.mobileButton {
    padding: 0px;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media (max-width: variables.$bp-md) {
        padding: 10px 20px;
    }
}

.shareButton {
    padding: 10px 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media (max-width: variables.$bp-lg) {
        padding: 10px;
        width: 30px;
        border: 1px solid variables.$text-colors-color-text-blue;
        border-radius: 50%;
    }
}

.tutorShareButton {
    padding: 10px 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #9b1de8;
    border: 1px solid #9b1de880;
    @media (max-width: variables.$bp-lg) {
        padding: 10px;
        width: 30px;
        border-radius: 50%;
    }
}
