import { Dispatch, SetStateAction } from "react";

import * as styles from "./RadioListSelector.module.scss";

import SelectableList from "components/SelectableList/SelectableList";
import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    title?: TranslatableText;
    itemList: Array<{
        id: number;
        name: string;
    }>;
    itemId: number | undefined;
    setItemId: Dispatch<SetStateAction<number | undefined>>;
}

const RadioListSelector = ({ title, itemList, itemId, setItemId }: Props) => {
    return (
        <>
            {title && (
                <div className={styles.title}>
                    <Translate text={title} />
                </div>
            )}
            <div style={{ padding: "20px 0", width: "100%", overflow: "auto" }}>
                <SelectableList
                    list={itemList}
                    selectedId={itemId}
                    setSelectedId={setItemId}
                />
            </div>
        </>
    );
};

export default RadioListSelector;
