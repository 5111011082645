import { useContext, useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./Collections.module.scss";
import { useAppContext } from "contexts/AppContext";
import { TranslatableText, translate } from "components/Translate/Translate";
import { useItemsInCollectionQuery } from "hooks/api/collections";
import { useFullWorkspacesQuery, findItemsWithIds } from "hooks/api/folders";
import useScreenSizes from "hooks/useScreenSizes";
import {
    AnyElement,
    Collection,
    CollectionItem,
    ContainerElement,
} from "types";
import { tracker } from "utils/analytics";

import CollectionsExplorerPanel from "components/CollectionsExplorerPanel/CollectionsExplorerPanel";
import MobileCollectionsExplorer from "components/CollectionsExplorerPanel/MobileCollectionsExplorer";
import CollectionViewer from "components/CollectionViewer/CollectionViewer";
import ContainerElementViewer from "components/ContainerElementViewer/ContainerElementViewer";
import CollectionNavBar from "components/NavBars/CollectionNavBar";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";

const AddFromCollections = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const {
        config: { language },
    } = useAppContext();
    const { selectedItems, addItem, removeItem } =
        useContext(CollectionContext);
    const [navigationIds, setNavigationIds] = useState<Array<number[]>>([]);

    const { data: fullWorkspacesData } = useFullWorkspacesQuery();
    const selectedElements = findItemsWithIds(
        fullWorkspacesData,
        navigationIds.at(-1) ?? [],
    );
    const selectedFirstChild = selectedElements[1];
    const selectedElement = selectedElements.at(-1) as
        | ContainerElement
        | Collection
        | undefined;
    const pageTitle = selectedElement?.title
        ? selectedElement.type === "WORKSPACE"
            ? translate(language, selectedElement.title as TranslatableText)
            : selectedElement.title
        : translate(language, "Collections");

    // Folder items
    const folderItems =
        selectedElement && "children" in selectedElement
            ? selectedElement.children.filter((el) => el.type !== "QUIZ")
            : fullWorkspacesData;
    const sortedFolderItems = selectedFirstChild
        ? selectedElement?.sortBy === "TITLE"
            ? folderItems.sort((a, b) =>
                  a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
              )
            : folderItems.sort((a, b) =>
                  a.lastModifiedDate < b.lastModifiedDate ? 1 : -1,
              )
        : folderItems;

    // Collection items
    const { data: collectionItemsData, isFetched: collectionItemsIsFetched } =
        useItemsInCollectionQuery(
            selectedElement && selectedElement.type === "COLLECTION"
                ? selectedElement.id
                : undefined,
        );

    const elementClickHandler = (element: AnyElement, pathString?: string) => {
        if (!pathString) return;
        tracker("Element clicked", element);
        const newNavIds = pathString.split("/").map((el) => parseFloat(el));
        setNavigationIds([...navigationIds, newNavIds]);
    };
    const elementViewClickHandler = (element: AnyElement) => {
        const newPathString = [
            ...(navigationIds?.at(-1) ?? []),
            element.id,
        ].join("/");
        elementClickHandler(element, newPathString);
    };
    const addItemToCollection = (result: CollectionItem) => {
        addItem && addItem(result);
    };
    const removeItemFromCollection = (result: CollectionItem) => {
        removeItem && removeItem(result);
    };
    const backHandler = () => {
        setNavigationIds(navigationIds.slice(0, -1));
    };
    const homeHandler = () => {
        setNavigationIds([]);
    };
    return (
        <>
            <CollectionNavBar
                customBackbuttonOnClick={backHandler}
                customHomebuttonOnClick={homeHandler}
            />
            {!isDesktopScreen && (
                <MobileCollectionsExplorer
                    items={fullWorkspacesData}
                    elementClickHandler={elementClickHandler}
                    pathRoot={""}
                    selectedIds={navigationIds.at(-1)}
                    useOwnSubheaderContainer
                    inDialogBox
                />
            )}
            <div
                style={{
                    padding: isMobileScreen ? "20px 10px" : "20px 40px",
                    marginBottom: 10,
                    width: "100%",
                }}
            >
                <Grid container spacing={"20px"}>
                    {isDesktopScreen && (
                        <Grid lg={3} xs={0}>
                            <CollectionsExplorerPanel
                                items={fullWorkspacesData}
                                selectedIds={navigationIds.at(-1)}
                                elementClickHandler={elementClickHandler}
                            />
                        </Grid>
                    )}
                    <Grid lg={9} xs={12}>
                        <div className={styles.title}>
                            <Typography
                                variant="text-size-Medium"
                                color="$text-colors-color-text-dark.main"
                            >
                                {pageTitle}
                            </Typography>
                        </div>
                        {selectedElement &&
                        selectedElement.type === "COLLECTION" ? (
                            <CollectionViewer
                                items={collectionItemsData ?? []}
                                isFetched={collectionItemsIsFetched}
                                viewAs={"LIST"} // List view to add items to the collection
                                selectedItems={selectedItems}
                                addItemHandler={addItemToCollection}
                                removeItemHandler={removeItemFromCollection}
                                collectionResultsModalEnabled={false} // While adding resources ALWAYS open in new tab
                            />
                        ) : (
                            <ContainerElementViewer
                                elements={sortedFolderItems}
                                isFetched={
                                    selectedFirstChild &&
                                    "hasBeenFetched" in selectedFirstChild
                                        ? !!selectedFirstChild.hasBeenFetched
                                        : true
                                }
                                viewAs={selectedElement?.view ?? "GRID"}
                                sortBy={
                                    selectedElement?.sortBy ??
                                    "LAST_MODIFIED_DATE"
                                }
                                elementClickHandler={elementViewClickHandler}
                                disableContextMenu
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default AddFromCollections;
