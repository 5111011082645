import { Typography } from "@mui/material";

import * as styles from "./QuizViewerDetails.module.scss";
import { Quiz } from "types";

import { SourceViewerCard } from "components/SourceCard/SourceCard";
import Panel from "components/Panel/Panel";
import Translate from "components/Translate/Translate";

interface Props {
    quiz: Quiz;
}

const QuizViewerDetails = ({ quiz }: Props) => {
    return (
        <Panel styleOverrides={{ padding: "20px 10px", borderRadius: "15px" }}>
            <div className={styles.section}>
                <Typography
                    variant="text-size-Base-bold"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text="Quiz topic" />
                </Typography>
                <Typography
                    variant="text-size-Base"
                    color="$text-colors-color-text-dark.main"
                >
                    {quiz.topic ? (
                        quiz.topic
                    ) : (
                        <Typography
                            variant="text-size-Small"
                            color="$text-colors-color-text-dark.main"
                            style={{ fontStyle: "italic" }}
                        >
                            <Translate text="Topic is not set" />
                        </Typography>
                    )}
                </Typography>
            </div>
            <div className={styles.section}>
                <Typography
                    variant="text-size-Base-bold"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text="Sources" />
                </Typography>
                {quiz.suggestedSources && quiz.suggestedSources.length ? (
                    quiz.suggestedSources?.map((suggestedSource) => (
                        <SourceViewerCard
                            key={"element" + suggestedSource.id}
                            source={suggestedSource}
                        />
                    ))
                ) : (
                    <Typography
                        variant="text-size-Small"
                        color="$text-colors-color-text-dark.main"
                        style={{ fontStyle: "italic" }}
                    >
                        <Translate text="Sources are not added" />
                    </Typography>
                )}
            </div>
        </Panel>
    );
};

export default QuizViewerDetails;
