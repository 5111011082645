import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./DeleteButton.module.scss";
import useScreenSizes from "hooks/useScreenSizes";

import Translate from "components/Translate/Translate";

const DeleteButton = ({
    deleteHandler,
    isVisible,
}: {
    deleteHandler?: () => void;
    isVisible?: boolean;
}) => {
    const { isTabletScreen } = useScreenSizes();
    if (!isVisible || !deleteHandler) return null;
    return (
        <Button
            variant="outlined"
            color="$message-colors-color-message-danger"
            className={styles.deleteButton}
            onClick={deleteHandler}
        >
            <FontAwesomeIcon icon={faTrash} fontSize={12} />
            {!isTabletScreen && (
                <Typography variant="button-text-size-xSmall-bold">
                    <Translate text="Delete" />
                </Typography>
            )}
        </Button>
    );
};
export default DeleteButton;
