import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import {
    QuizEditorContextProvider,
    useQuizEditorContext,
} from "./state/QuizEditorContext";
import useScreenSizes from "hooks/useScreenSizes";

import MobileQuizEditorDetails from "./components/QuizEditorDetails/MobileQuizEditorDetails";
import QuizEditorDetails from "./components/QuizEditorDetails/QuizEditorDetails";
import QuizEditorQuestions from "./components/QuizEditorQuestions/QuizEditorQuestions";
import QuizEditorSteps from "./components/QuizEditorSteps/QuizEditorSteps";
import EditorSubheader from "components/EditorSubheader/EditorSubheader";
import EditableTitle from "features/CollectionEditor/EditableTitle/EditableTitle";
import QuizPreviewButton from "features/Collections/components/Subheader/QuizPreviewButton";
import QuizTaker from "features/QuizTaker/QuizTaker";

const QuizEditorMainSection = () => {
    const { isGenerating, questions } = useQuizEditorContext();
    if (questions?.length === 0 && !isGenerating) return <QuizEditorSteps />;
    return <QuizEditorQuestions />;
};

const QuizTitle = () => {
    const { title, setTitle, isSaving } = useQuizEditorContext();
    return (
        <EditableTitle
            title={title}
            titleChangeHandler={setTitle}
            editDisabled={isSaving}
        />
    );
};

const QuizEditorUI = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <QuizTitle />
            <div
                style={{
                    marginBottom: 10,
                    padding: isMobileScreen ? "20px 10px" : "20px 40px",
                }}
            >
                <Grid container height={"100%"} spacing={"20px"}>
                    {isDesktopScreen && (
                        <Grid lg={3} xs={0}>
                            <QuizEditorDetails />
                        </Grid>
                    )}
                    <Grid lg={6} xs={12}>
                        <QuizEditorMainSection />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const QuizEditorSubheader = () => {
    const {
        quizData,
        questions,
        isGenerating,
        isDirty,
        isSaving,
        saveHandler,
        deleteHandler,
    } = useQuizEditorContext();
    const [isQuizTakerOpen, setIsQuizTakerOpen] = useState(false);
    return (
        <>
            <EditorSubheader
                additionalLeftComponents={[
                    <MobileQuizEditorDetails key="mobilequizeditordetails" />,
                ]}
                additionalRightComponents={[
                    <QuizPreviewButton
                        key="quizPreviewButton"
                        clickHandler={() =>
                            setIsQuizTakerOpen(!isQuizTakerOpen)
                        }
                        isDisabled={questions.length === 0 || isGenerating}
                    />,
                ]}
                deleteButtonProps={{
                    isVisible: Boolean(quizData.id),
                    deleteHandler,
                }}
                saveButtonProps={{
                    isDisabled: !isDirty || isGenerating,
                    isSaving,
                    saveHandler,
                }}
            />
            <QuizTaker
                isOpen={isQuizTakerOpen}
                closeHandler={() => setIsQuizTakerOpen(false)}
                quizData={quizData}
                isPreview
                withCloseButton
            />
        </>
    );
};

const QuizEditor = () => {
    return (
        <QuizEditorContextProvider>
            <QuizEditorSubheader />
            <QuizEditorUI />
        </QuizEditorContextProvider>
    );
};

export default QuizEditor;
