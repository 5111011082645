import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./Subheader.module.scss";
import useScreenSizes from "hooks/useScreenSizes";

import Translate from "components/Translate/Translate";

interface Props {
    clickHandler: () => void;
    isDisabled?: boolean;
}

const QuizPreviewButton = ({ clickHandler, isDisabled }: Props) => {
    const { isMobileScreen } = useScreenSizes();
    return (
        <Button
            variant="contained"
            className={styles.previewQuizButton}
            disabled={isDisabled}
            onClick={clickHandler}
        >
            <FontAwesomeIcon icon={faPlay} fontSize={12} />
            {!isMobileScreen && (
                <Typography variant="text-size-xSmall-bold">
                    <Translate text="Preview the quiz" />
                </Typography>
            )}
        </Button>
    );
};

export default QuizPreviewButton;
