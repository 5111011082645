import Grid from "@mui/material/Unstable_Grid2";

import { useCollectionsPanelQuery } from "hooks/api/collections";
import useScreenSizes from "hooks/useScreenSizes";

import CollectionsTile from "./components/CollectionsTile/CollectionsTile";
import CreateLessonPlanTile from "./components/CreateLessonPlanTile/CreateLessonPlanTile";
import CreateQuizTile from "./components/CreateQuizTile/CreateQuizTile";
import CreateTutorTile from "./components/CreateTutorTile/CreateTutorTile";
import MyCurriculumTile from "./components/MyCurriculumTile/MyCurriculumTile";
import SearchTile from "./components/SearchTile/SearchTile";

const DashboardPage = () => {
    const { isMobileScreen } = useScreenSizes();
    const { data: collectionsPanelData, isFetched: collectionsPanelIsFetched } =
        useCollectionsPanelQuery();
    const noContentElements = !collectionsPanelData?.results?.length;
    return (
        <div
            style={{
                marginBottom: 10,
                padding: isMobileScreen ? "30px 10px" : "30px 40px",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Grid
                container
                spacing={"20px"}
                sx={{ maxWidth: isMobileScreen ? "343px" : undefined }}
            >
                <Grid lg={3} md={4} xs={12}>
                    <MyCurriculumTile />
                </Grid>
                <Grid
                    lg={collectionsPanelIsFetched && noContentElements ? 3 : 9}
                    md={collectionsPanelIsFetched && noContentElements ? 4 : 8}
                    xs={12}
                >
                    <CollectionsTile />
                </Grid>
                <Grid lg={3} md={4} xs={12}>
                    <CreateQuizTile />
                </Grid>
                <Grid lg={3} md={4} xs={12}>
                    <CreateLessonPlanTile />
                </Grid>
                <Grid lg={3} md={4} xs={12}>
                    <CreateTutorTile />
                </Grid>
                <Grid lg={3} md={4} xs={12}>
                    <SearchTile />
                </Grid>
            </Grid>
        </div>
    );
};

export default DashboardPage;
