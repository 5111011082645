import React, { useState } from "react";

import { TranslatableText } from "components/Translate/Translate";
import { useSendQuizEmailMutation } from "hooks/api/quizzes";
import useScreenSizes from "hooks/useScreenSizes";
import { Quiz } from "types";
import { tracker } from "utils/analytics";

import QuestionScreen from "./QuestionScreen";
import ResultScreen from "./ResultScreen";
import StartScreen from "./StartScreen";
import DialogBox from "components/DialogBox/DialogBox";
import Footer from "components/DialogBox/DialogFooter/DialogFooter";

interface Props {
    quizData: Quiz;
    isOpen: boolean;
    closeHandler: () => void;
    elementId?: number | string;
    elementHash?: string;
    isPreview?: boolean;
    withCloseButton?: boolean;
    isQuizShare?: boolean;
}

const QuizTaker = ({
    quizData,
    isOpen,
    closeHandler,
    elementId,
    elementHash,
    isPreview,
    withCloseButton,
    isQuizShare,
}: Props) => {
    const { isDesktopScreen } = useScreenSizes();
    const [userName, setUserName] = useState<string>(
        isPreview ? "Preview user" : "",
    );
    const [quizStage, setQuizStage] = useState<"start" | "quiz" | "results">(
        "start",
    );
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
    const [isAnswerConfirmed, setIsAnswerConfirmed] = useState<boolean>(false);
    const [correctAnswers, setCorrectAnswers] = useState<number[]>([]);
    const [incorrectAnswers, setIncorrectAnswers] = useState<string[]>([]);
    const sendQuizEmailMutation = useSendQuizEmailMutation(
        elementId,
        elementHash,
    );
    if (!quizData || !quizData.questions || quizData.questions.length === 0)
        return <></>;
    const question = quizData?.questions[currentIndex];

    const confirmAnswer = () => {
        if (selectedId == undefined) return;
        setIsAnswerConfirmed(true);
    };
    const advanceQuizStep = () => {
        if (selectedId === undefined) return;
        tracker("Quiz Taker - Advance step");
        const answerIsCorrect =
            selectedId ===
            question.answers.findIndex(
                (el) => el.id === question.correctAnswerId,
            );
        if (answerIsCorrect) {
            setCorrectAnswers([...correctAnswers, currentIndex]);
        } else {
            setIncorrectAnswers([...incorrectAnswers, question.id]);
        }
        setCurrentIndex(currentIndex + 1);
        setSelectedId(undefined);
        setIsAnswerConfirmed(false);

        if (quizData && currentIndex >= quizData?.questions.length - 1) {
            tracker("Quiz Taker - Open Results", {
                score: correctAnswers.length,
                totalNumberOfQuestions: quizData?.questions.length,
                percentage:
                    (correctAnswers.length / quizData?.questions.length) * 100,
            });
            sendQuizEmailMutation.mutate({
                studentName: userName,
                numberOfCorrectAnswers:
                    correctAnswers.length + (answerIsCorrect ? 1 : 0),
                questionsFailed: [
                    ...incorrectAnswers,
                    ...(answerIsCorrect ? [] : [question.id]),
                ],
            });
            setQuizStage("results");
        }
    };

    const resetQuiz = () => {
        tracker("Quiz Taker - Reset Quiz");
        setQuizStage(isPreview ? "start" : "quiz");
        setCurrentIndex(0);
        setCorrectAnswers([]);
        setIncorrectAnswers([]);
        setSelectedId(undefined);
        setIsAnswerConfirmed(false);
    };

    const startQuiz = () => {
        tracker("Quiz Taker - Start Quiz");
        setQuizStage("quiz");
    };
    type QuizScreenProps = {
        screen: JSX.Element;
        buttons: [
            React.ComponentPropsWithoutRef<typeof DialogBox>["primaryButton"]?,
            React.ComponentPropsWithoutRef<
                typeof DialogBox
            >["secondaryButton"]?,
        ];
    };
    const renderComponents: {
        start: QuizScreenProps;
        quiz: QuizScreenProps;
        results: QuizScreenProps;
    } = {
        start: {
            screen: (
                <StartScreen
                    buttonClickHandler={startQuiz}
                    quizTitle={quizData.title}
                    questionCount={quizData.questions.length}
                    userName={userName}
                    setUserName={setUserName}
                />
            ),
            buttons: [],
        },
        quiz: {
            screen: (
                <QuestionScreen
                    question={quizData?.questions[currentIndex]}
                    currentIndex={currentIndex}
                    totalNumberOfQuestions={quizData?.questions.length}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    isAnswerConfirmed={isAnswerConfirmed}
                />
            ),
            buttons: [
                !isDesktopScreen
                    ? {
                          label: !isAnswerConfirmed ? "Confirm answer" : "Next",
                          clickHandler: !isAnswerConfirmed
                              ? confirmAnswer
                              : advanceQuizStep,
                          disabled: selectedId === undefined,
                      }
                    : {
                          label: "Next",
                          clickHandler: advanceQuizStep,
                          disabled: selectedId === undefined,
                      },
            ],
        },
        results: {
            screen: (
                <ResultScreen
                    score={correctAnswers.length}
                    totalNumberOfQuestions={
                        quizData?.questions.length as number
                    }
                />
            ),
            buttons: [
                {
                    label: "Close",
                    clickHandler: () => {
                        tracker("Quiz Taker - Closed");
                        resetQuiz();
                        closeHandler();
                    },
                },
                {
                    label: "Take the quiz again",
                    clickHandler: resetQuiz,
                },
            ],
        },
    };
    const renderComponent = renderComponents[quizStage];

    return isQuizShare ? (
        <>
            <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
                {renderComponent.screen}
            </div>
            <Footer
                secondaryButton={renderComponent.buttons[1] ?? undefined}
                primaryButton={
                    quizStage !== "results"
                        ? renderComponent.buttons[0]
                        : undefined
                }
            />
        </>
    ) : (
        <DialogBox
            isOpen={isOpen}
            closeHandler={closeHandler}
            disableEscapeKeyDown
            title={quizData?.title as TranslatableText}
            withCloseButton={withCloseButton}
            primaryButton={renderComponent.buttons[0] ?? undefined}
            secondaryButton={renderComponent.buttons[1] ?? undefined}
        >
            {renderComponent.screen}
        </DialogBox>
    );
};

export default QuizTaker;
