import { useContext } from "react";
import { Typography } from "@mui/material";

import * as styles from "./QueryView.module.scss";
import { useAppContext } from "contexts/AppContext";
import { CurriculumContext } from "features/CurriculumNavigator/state/CurriculumContext";
import useQueryResultsQuery from "hooks/api/useQueryResultsQuery";
import useCurriculumParams from "hooks/useCurriculumParams";
import { tracker } from "utils/analytics";
import { isShareMode } from "utils/newtools";

import LogoAndTitle from "../LogoAndTitle/LogoAndTitle";
import QueryViewResultCard from "./components/QueryViewResultCard";
import Loading from "components/Loading/Loading";
import Translate from "components/Translate/Translate";

const QueryView = () => {
    const { config } = useAppContext();
    const { selectedResultsArray, setSelectedResultsArray } =
        useContext(CurriculumContext);
    const { queryId } = useCurriculumParams();
    const { data: queryData, isFetched: queryIsFetched } = useQueryResultsQuery(
        queryId,
        {
            numberOfResults: config.numberOfResults,
            pinnedOnly: true, // Figma: "Display always only the teacher approved resources until the curriculum queries are migrated to the collections"
            embeddable: config.embeddable,
        },
    );
    const selectAllHandler = () => {
        if (!queryData) return;
        if (selectedResultsArray.length > 0) {
            tracker("Deselected all results");
            setSelectedResultsArray([]);
        } else {
            tracker("Selected all results");
            setSelectedResultsArray(
                queryData.results.map((result) => result.id),
            );
        }
    };
    return (
        <>
            {!queryIsFetched ? (
                <Loading />
            ) : (
                <>
                    <LogoAndTitle />
                    <div className={styles.container}>
                        {!isShareMode && (
                            <div className={styles.selectAll}>
                                <Typography
                                    variant="text-size-Base"
                                    color="$text-colors-color-text-dark.main"
                                >
                                    <Translate text="Select resources you want to share." />
                                </Typography>
                                <Typography
                                    variant="text-size-Base"
                                    color="$text-colors-color-text-blue.main"
                                    style={{ cursor: "pointer" }}
                                    onClick={selectAllHandler}
                                >
                                    {selectedResultsArray.length > 0 ? (
                                        <Translate text="Deselect all" />
                                    ) : (
                                        <Translate text="Select all" />
                                    )}
                                </Typography>
                            </div>
                        )}
                        {queryData?.results.map((result) => (
                            <QueryViewResultCard
                                key={result.id}
                                result={result}
                                results={queryData?.results}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default QueryView;
