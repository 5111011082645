import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";

import * as styles from "./PageHeader.module.scss";
import { CurriculumContext } from "../../state/CurriculumContext";
import { useAppContext } from "contexts/AppContext";
import { useMessagingContext } from "contexts/MessagingContext";
import { useFullWorkspacesQuery } from "hooks/api/folders";
import useQueryResultsQuery from "hooks/api/useQueryResultsQuery";
import { useCreateResourcesCollectionMutation } from "hooks/api/useResourcesCollectionQuery";
import useCurriculumParams from "hooks/useCurriculumParams";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { Curriculum } from "types";
import { tracker } from "utils/analytics";
import { isShareMode } from "utils/newtools";

import CreateCollectFromSelectionButton from "./CreateCollectFromSelectionButton";
import CurriculumPanel from "../CurriculumPanel/CurriculumPanel";
import CurriculumLogo from "components/CurriculumLogo/CurriculumLogo";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";
import ShareButton from "components/ShareButton/ShareButton";
import ShareQueryButton from "components/ShareButton/ShareQueryButton";
import { GenerateCurriculumQuizButton } from "features/CollectionEditor/Subheader/GenerateQuizButton/GenerateQuizButton";

const PageHeader = ({ curriculum }: { curriculum: Curriculum }) => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const { config } = useAppContext();
    const { topicId, queryId } = useCurriculumParams();
    const { openAlertMessage, openMessageModal } = useMessagingContext();
    const { selectedResultsArray, embedUrl, linkUrl } =
        useContext(CurriculumContext);
    const [isCurriculumPanelOpen, setIsCurriculumPanelOpen] = useState(false);

    const { data: queryData } = useQueryResultsQuery(queryId, {
        numberOfResults: config.numberOfResults,
        pinnedOnly: true, // Figma: "Display always only the teacher approved resources until the curriculum queries are migrated to the collections"
        embeddable: config.embeddable,
    });
    const { data: fullWorkspacesData } = useFullWorkspacesQuery();
    const userPrivateFolder = fullWorkspacesData?.find(
        (folder) => folder.access === "PRIVATE",
    );
    const createResourcesCollectionMutation =
        useCreateResourcesCollectionMutation();
    const createCollectionFromSelection = async (withQuiz?: boolean) => {
        tracker("Create Collection from Selection button clicked");
        if (!queryData?.query.name) {
            return openAlertMessage({
                message: "Failed to create collection.",
                open: true,
                type: "error",
            });
        }
        const result = await createResourcesCollectionMutation.mutateAsync({
            ids: selectedResultsArray,
            title: queryData?.query.name,
            parentId: userPrivateFolder?.id,
        });
        if (result?.status === 200) {
            openAlertMessage({
                message: "Collection has been saved.",
                open: true,
                type: "success",
            });
            navigate(`/edit-collection/${result.data.collection.id}`, {
                state: { openQuizEditor: withQuiz },
            });
        } else {
            openAlertMessage({
                message: "Failed to create collection.",
                open: true,
                type: "error",
            });
            console.error("Failed to create collection from resources.");
            return;
        }
    };

    const createCollectionAndQuizFromSelection = () => {
        openMessageModal({
            title: "Generate Quiz (Beta)",
            body: "A new collection with the selected resources and a quiz will be created in your Private Workspace.",
            isModalVisible: true,
            type: "OK",
            primaryActionHandler: () => createCollectionFromSelection(true),
            secondaryActionHandler: () => null,
        });
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.curriculumDetails}>
                    <CurriculumLogo
                        name={curriculum?.name}
                        logoUrl={curriculum?.logoUrl}
                    />
                    <Typography variant="text-size-Medium" color="white">
                        {curriculum?.name}
                    </Typography>
                </div>
            </div>
            {(!isDesktopScreen || topicId) && (
                <div className={styles.subheader}>
                    {!isDesktopScreen && (
                        <div style={{ position: "relative" }}>
                            <MenuButton
                                dataTestId={"curriculumPanelMenuButton"}
                                faIcon={faBars}
                                iconColor={variables["icon-colors-blue-100"]}
                                primaryColor={variables["icon-colors-blue-40"]}
                                secondaryColor={
                                    variables["icon-colors-blue-60"]
                                }
                                isOpen={isCurriculumPanelOpen}
                                onClick={() => {
                                    tracker("Curriculum panel menu open");
                                    setIsCurriculumPanelOpen(true);
                                }}
                            />
                            <MenuContainer
                                isOpen={isCurriculumPanelOpen}
                                closeMenu={() =>
                                    setIsCurriculumPanelOpen(false)
                                }
                                containerRelativePosition="bottom-right"
                                fullWidthBreakpoint="xl"
                            >
                                <CurriculumPanel />
                            </MenuContainer>
                        </div>
                    )}
                    {topicId && !isShareMode && (
                        <div className={styles.shareButtonContainer}>
                            {queryId !== undefined && (
                                <>
                                    <GenerateCurriculumQuizButton
                                        clickHandler={
                                            createCollectionAndQuizFromSelection
                                        }
                                        isDisabled={
                                            selectedResultsArray.length === 0
                                        }
                                    />
                                    <CreateCollectFromSelectionButton
                                        isDisabled={
                                            selectedResultsArray.length === 0
                                        }
                                        clickHandler={
                                            createCollectionFromSelection
                                        }
                                    />
                                </>
                            )}
                            {queryId && queryData ? (
                                <ShareQueryButton
                                    hasText={!isMobileScreen}
                                    selectedIds={selectedResultsArray}
                                    queryTitle={queryData.query.name}
                                />
                            ) : (
                                <ShareButton
                                    embedUrl={embedUrl}
                                    linkUrl={linkUrl}
                                    hasText={!isMobileScreen}
                                    disabled={
                                        queryId !== undefined &&
                                        selectedResultsArray.length === 0
                                    }
                                    isNewDesign
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default PageHeader;
