import { useContext, useState } from "react";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./TutorShareButtonWrapper.module.scss";
import MessagingContext from "contexts/MessagingContext";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import useScreenSizes from "hooks/useScreenSizes";

import ShareElementDialog from "components/ContextMenu/dialogs/ShareElementDialog";
import { MessageModalProps } from "components/MessageModal/MessageModal";
import Translate from "components/Translate/Translate";

const TutorShareButtonWrapper = () => {
    const { isDesktopScreen } = useScreenSizes();
    const { openMessageModal } = useContext(MessagingContext);
    const { collection, changeQueue, selectedItems, saveHandler } =
        useContext(CollectionContext);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const okMessageModal: MessageModalProps = {
        isModalVisible: true,
        title: "Collection is empty",
        body: "You can't share an empty collection. Please add resources to the collection before sharing.",
        type: "OK",
        primaryActionHandler: () => undefined,
    };
    const saveMessageModal: MessageModalProps = {
        isModalVisible: true,
        title: "Collection is not saved",
        body: "You need to save the collection before sharing. Do you want to save the collection?",
        type: "Save",
        primaryActionHandler: saveHandler,
    };
    const shareHandler = () => {
        if (selectedItems.length === 0) {
            openMessageModal(okMessageModal);
        } else if (changeQueue.length > 0) {
            openMessageModal(saveMessageModal);
        }
    };
    return (
        <div className={styles.wrapper}>
            <Button
                variant="outlined"
                className={styles.shareButton}
                sx={{
                    "&:hover": {
                        backgroundColor: "#9b1de80a",
                        border: "1px solid #9b1de8",
                    },
                }}
                onClick={() => setIsShareModalOpen(true)}
            >
                <FontAwesomeIcon icon={faWandMagicSparkles} fontSize={12} />
                {isDesktopScreen && (
                    <Typography variant="text-size-xSmall-bold">
                        <Translate text="Share AI Tutor" />
                    </Typography>
                )}
            </Button>
            {(collection.id === 0 ||
                selectedItems.length === 0 ||
                changeQueue.length > 0) && (
                <div
                    data-testid="collection-overlay-share-button"
                    className={styles.overlay}
                    onClick={shareHandler}
                ></div>
            )}
            <ShareElementDialog
                element={{
                    type: "TUTOR",
                    // embedLink: collection.embedLink.replace(
                    //     "embed",
                    //     "embed/tutorchat",
                    // ),
                    shareLink: collection.shareLink?.replace(
                        "view",
                        "view/tutorchat",
                    ),
                }}
                isVisible={isShareModalOpen}
                setIsVisible={setIsShareModalOpen}
            />
        </div>
    );
};

export default TutorShareButtonWrapper;
