@use "../../../../styles/variables.module";

.section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    align-self: stretch;
}

.sources {
    display: inline-flex;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
}
