import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./Collections.module.scss";
import { translate } from "components/Translate/Translate";
import { useAppContext } from "contexts/AppContext";
import {
    useCollectionQuery,
    useItemsInCollectionQuery,
} from "hooks/api/collections";
import {
    useFullWorkspacesQuery,
    useEditFolderMutation,
    useSelectedElements,
} from "hooks/api/folders";
import { useQuizQuery } from "hooks/api/quizzes";
import { useEditWorkspaceMutation } from "hooks/api/workspace";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import { AnyElement, ContainerElement } from "types";
import { tracker } from "utils/analytics";

import Subheader from "./components/Subheader/Subheader";
import CollectionsExplorerPanel from "components/CollectionsExplorerPanel/CollectionsExplorerPanel";
import CollectionViewer from "components/CollectionViewer/CollectionViewer";
import ContainerElementViewer from "components/ContainerElementViewer/ContainerElementViewer";
import CollectionNavBar from "components/NavBars/CollectionNavBar";
import QuizViewer from "components/QuizViewer/QuizViewer";
import QuizPanel from "features/CollectionEditor/QuizPanel/QuizPanel";

const Collections = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const {
        config: { language },
    } = useAppContext();
    const collectionsParams = useCollectionsParams();
    const editFolderMutation = useEditFolderMutation();
    const editWorkspaceMutation = useEditWorkspaceMutation();
    const { data: fullWorkspacesData, status: fullWorkspacesStatus } =
        useFullWorkspacesQuery();
    const selectedElements = useSelectedElements();
    const selectedFirstChild = selectedElements[1];
    const selectedElement = selectedElements.at(-1) as AnyElement | undefined;
    const pageTitle = selectedElement?.title
        ? selectedElement.type === "WORKSPACE"
            ? translate(language, selectedElement?.title)
            : selectedElement.title
        : translate(language, "Collections");

    // Folder items
    const folderItems = selectedElement?.children
        ? selectedElement?.children
        : fullWorkspacesData;
    const sortedFolderItems = selectedElements[0]
        ? selectedElement?.sortBy === "TITLE"
            ? [...folderItems].sort((a, b) =>
                  a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
              )
            : [...folderItems].sort((a, b) =>
                  a.lastModifiedDate < b.lastModifiedDate ? 1 : -1,
              )
        : [...folderItems];

    // Collection
    const { data: collectionData } = useCollectionQuery(
        selectedElement && selectedElement.type === "COLLECTION"
            ? selectedElement.id
            : undefined,
    );

    // Collection items
    const { data: collectionItemsData, isFetched: collectionItemsIsFetched } =
        useItemsInCollectionQuery(
            selectedElement && selectedElement.type === "COLLECTION"
                ? selectedElement.id
                : undefined,
        );

    // Quiz
    const { data: quizData, isFetched: quizIsFetched } = useQuizQuery(
        selectedElement && selectedElement.type === "QUIZ"
            ? selectedElement.id
            : undefined,
    );

    const elementClickHandler = (element: AnyElement, pathString?: string) => {
        if (!pathString) return;
        tracker("Element clicked", element);
        navigate(pathString);
    };
    const elementViewClickHandler = (element: AnyElement) => {
        elementClickHandler(element, location.pathname + "/" + element.id);
    };
    const setViewAsHandler = (newValue: ContainerElement["view"]) => {
        if (selectedElement) {
            if (selectedElement.type === "WORKSPACE") {
                editWorkspaceMutation.mutate({
                    ...selectedElement,
                    view: newValue,
                });
            } else if (selectedElement.type === "FOLDER") {
                editFolderMutation.mutate({
                    ...selectedElement,
                    view: newValue,
                });
            }
        }
    };
    const setSortByHandler = (newValue: ContainerElement["sortBy"]) => {
        if (selectedElement) {
            if (selectedElement.type === "WORKSPACE") {
                editWorkspaceMutation.mutate({
                    ...selectedElement,
                    sortBy: newValue,
                });
            } else if (selectedElement.type === "FOLDER") {
                editFolderMutation.mutate({
                    ...selectedElement,
                    sortBy: newValue,
                });
            }
        }
    };
    return (
        <>
            <CollectionNavBar
                previousCrumbs={[
                    {
                        label: translate(language, "Collections"),
                        pathname: "/collections",
                    },
                ]}
            />
            <Subheader
                viewAs={selectedElement?.view ?? "GRID"}
                setViewAs={setViewAsHandler}
                sortBy={selectedElement?.sortBy ?? "LAST_MODIFIED_DATE"}
                setSortBy={setSortByHandler}
                generateQuizHandler={() =>
                    navigate("/create-quiz", {
                        state: {
                            collectionId: selectedElement?.id,
                            collectionTitle: selectedElement?.title,
                            collectionsParams,
                        },
                    })
                }
            />
            <div
                style={{
                    padding: isMobileScreen ? "20px 10px" : "20px 40px",
                    marginBottom: 10,
                    width: "100%",
                }}
            >
                <Grid container spacing={"20px"}>
                    {isDesktopScreen && (
                        <Grid lg={3} xs={0}>
                            <CollectionsExplorerPanel
                                items={fullWorkspacesData}
                                selectedIds={collectionsParams}
                                elementClickHandler={elementClickHandler}
                            />
                        </Grid>
                    )}
                    <Grid lg={9} xs={12}>
                        <div className={styles.title}>
                            <Typography
                                variant="text-size-Medium"
                                color="$text-colors-color-text-dark.main"
                            >
                                {pageTitle}
                            </Typography>
                        </div>
                        {selectedElement && selectedElement.type === "QUIZ" ? (
                            quizData && (
                                <QuizViewer
                                    quiz={quizData}
                                    isFetched={quizIsFetched}
                                />
                            )
                        ) : selectedElement &&
                          selectedElement.type === "COLLECTION" ? (
                            <div>
                                <Grid container spacing={"20px"} columns={9}>
                                    {!isDesktopScreen &&
                                        collectionData &&
                                        collectionData.quiz?.id && (
                                            <Grid xs={9}>
                                                <QuizPanel
                                                    quizId={
                                                        collectionData.quiz?.id
                                                    }
                                                />
                                            </Grid>
                                        )}
                                    <Grid lg={6} xs={9}>
                                        <CollectionViewer
                                            items={collectionItemsData ?? []}
                                            isFetched={collectionItemsIsFetched}
                                            viewAs={
                                                collectionData?.view ?? "LIST"
                                            }
                                            collectionResultsModalEnabled={
                                                !!collectionData?.resultModal
                                            }
                                        />
                                    </Grid>
                                    {isDesktopScreen &&
                                        collectionData &&
                                        collectionData.quiz?.id && (
                                            <Grid lg={3}>
                                                <QuizPanel
                                                    quizId={
                                                        collectionData.quiz?.id
                                                    }
                                                />
                                            </Grid>
                                        )}
                                </Grid>
                            </div>
                        ) : (
                            <ContainerElementViewer
                                elements={sortedFolderItems}
                                isFetched={
                                    selectedFirstChild &&
                                    "hasBeenFetched" in selectedFirstChild
                                        ? !!selectedFirstChild.hasBeenFetched
                                        : fullWorkspacesStatus === "success"
                                }
                                viewAs={selectedElement?.view ?? "GRID"}
                                sortBy={
                                    selectedElement?.sortBy ??
                                    "LAST_MODIFIED_DATE"
                                }
                                elementClickHandler={elementViewClickHandler}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Collections;
