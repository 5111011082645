import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./QuizViewer.module.scss";
import useScreenSizes from "hooks/useScreenSizes";
import { Quiz } from "types";

import QuestionViewerCard from "./components/QuestionViewerCard/QuestionViewerCard";
import QuizViewerDetails from "./components/QuizViewerDetails/QuizViewerDetails";
import Loading from "components/Loading/Loading";
import Translate from "components/Translate/Translate";

interface Props {
    quiz: Quiz | undefined;
    isFetched?: boolean;
}

const QuizViewer = ({ quiz, isFetched }: Props) => {
    const { isDesktopScreen } = useScreenSizes();
    if (!isFetched || !quiz) return <Loading />;
    return (
        <Grid container spacing={"20px"} columns={9}>
            {!isDesktopScreen && (
                <Grid xs={9}>
                    <QuizViewerDetails quiz={quiz} />
                </Grid>
            )}
            <Grid lg={6} xs={9}>
                <div className={styles.questions}>
                    {quiz.questions.length ? (
                        quiz.questions.map((question) => (
                            <QuestionViewerCard
                                key={question.id}
                                question={question}
                            />
                        ))
                    ) : (
                        <Typography
                            variant="text-size-Small"
                            color="$text-colors-color-text-dark.main"
                            style={{ fontStyle: "italic" }}
                        >
                            <Translate text="Questions are not created" />
                        </Typography>
                    )}
                </div>
            </Grid>
            {isDesktopScreen && (
                <Grid lg={3}>
                    <QuizViewerDetails quiz={quiz} />
                </Grid>
            )}
        </Grid>
    );
};

export default QuizViewer;
