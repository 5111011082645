import { useState } from "react";
import {
    faCircle,
    faClipboardQuestion,
    faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Skeleton, Typography } from "@mui/material";

import * as styles from "./ContentElementCard.module.scss";
import variables from "styles/_variables.module.scss";
import { ContentElement, Tag } from "types";
import { isAppMode } from "utils/newtools";

import ElementThreeDotContextMenu from "components/ContextMenu/ElementThreeDotContextMenu";
import NotificationIcon from "components/NotificationIcon/NotificationIcon";
import Translate from "components/Translate/Translate";
import ChangeLogFooter from "components/ChangeLogFooter/ChangeLogFooter";

export const ContentElementCardSkeleton = () => {
    return (
        <Skeleton
            variant="rectangular"
            height={122}
            style={{ borderRadius: "15px" }}
        />
    );
};

interface Props {
    contentElement: ContentElement;
    clickHandler: () => void;
    disableContextMenu?: boolean;
}

const ContentElementCard = ({
    contentElement,
    clickHandler,
    disableContextMenu,
}: Props) => {
    const isCollection = contentElement.type === "COLLECTION";
    const isQuiz = contentElement.type === "QUIZ";
    const [isHovered, setIsHovered] = useState(false);
    const mouseOverHandler = () => {
        setIsHovered(true);
    };
    const mouseLeaveHandler = () => {
        setIsHovered(false);
    };
    return (
        <div
            className={styles.container}
            onClick={clickHandler}
            onMouseOver={
                isAppMode && !disableContextMenu ? mouseOverHandler : undefined
            }
            onMouseLeave={
                isAppMode && !disableContextMenu ? mouseLeaveHandler : undefined
            }
        >
            {isAppMode && !disableContextMenu && (
                <div className={styles.header}>
                    <ElementThreeDotContextMenu
                        element={contentElement}
                        isHovered={isHovered}
                    />
                </div>
            )}
            <div className={styles.iconAndTitle}>
                <FontAwesomeIcon
                    icon={isCollection ? faLayerGroup : faClipboardQuestion}
                    color={
                        isCollection
                            ? variables["text-colors-color-text-blue"]
                            : variables["text-colors-color-text-orange"]
                    }
                    fontSize={15}
                />
                <Typography
                    variant="text-size-Base-bold"
                    lineHeight={"20px"}
                    color="$text-colors-color-text-blue.main"
                    className={styles.title}
                >
                    {contentElement.title}
                </Typography>
                {isCollection && contentElement.numberOfGoneItems > 0 && (
                    <div className={styles.notification}>
                        <NotificationIcon
                            tooltip={{
                                title: "Missing resource",
                                description:
                                    "One or more resources are missing from the collection. Please review the collection.",
                            }}
                        />
                    </div>
                )}
            </div>
            <div className={styles.footer}>
                {isCollection ? (
                    <>
                        {contentElement.tags?.map((tag: Tag) => (
                            <Chip key={tag.id} label={tag.value} />
                        ))}
                        {contentElement.tags?.length > 0 && (
                            <FontAwesomeIcon
                                icon={faCircle}
                                className={styles.divider}
                            />
                        )}
                        <Typography
                            variant="text-size-xxSmall-medium"
                            color="$icon-colors-green-60.main"
                        >
                            {contentElement.itemsCount}
                            {contentElement.itemsCount === 1 ? (
                                <Translate text=" resource" />
                            ) : (
                                <Translate text=" resources" />
                            )}
                        </Typography>
                    </>
                ) : isQuiz ? (
                    <>
                        {contentElement.questions && (
                            <Typography
                                variant="text-size-xxSmall-medium"
                                color="$icon-colors-green-60.main"
                            >
                                {contentElement.questions.length}
                                {contentElement.questions.length === 1 ? (
                                    <Translate text=" question" />
                                ) : (
                                    <Translate text=" questions" />
                                )}
                            </Typography>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
            <ChangeLogFooter
                type="Modified by"
                name={contentElement.lastModifiedByName}
                date={contentElement.lastModifiedDate}
            />
        </div>
    );
};

export default ContentElementCard;
