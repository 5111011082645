import { CSSProperties, forwardRef } from "react";
import { Button, Typography } from "@mui/material";

import Translate from "components/Translate/Translate";

interface Props {
    quizTitle: string;
    openHandler: () => void;
    styleOverrides?: CSSProperties;
}

const QuizAvailableFooter = forwardRef(
    ({ quizTitle: quizName, openHandler, styleOverrides }: Props, ref) => {
        return (
            <div
                ref={ref}
                style={{
                    position: "absolute",
                    bottom: "0",
                    display: "flex",
                    width: "100%",
                    height: "fit-content",
                    padding: "20px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                    flexWrap: "wrap",
                    background:
                        "linear-gradient(90deg, #1CA284 0%, #43C9AC 100%)",
                    ...styleOverrides,
                }}
            >
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        variant="text-size-Small"
                        color="$text-colors-color-text-white.main"
                        style={{ opacity: 0.7 }}
                    >
                        <Translate text="Take the quiz and see how much you know!" />
                    </Typography>
                    <Typography
                        variant="text-size-xMedium"
                        color="$text-colors-color-text-white.main"
                        // style={{ textAlign: "center" }}
                    >
                        {quizName}
                    </Typography>
                </div>
                <Button
                    style={{
                        display: "flex",
                        padding: "16px 30px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        width: "200px",
                        borderRadius: "5px",
                        border: "1px solid #F7BF6D",
                        background: "#F29103",
                    }}
                    onClick={openHandler}
                >
                    <Typography
                        variant="text-size-Base-bold"
                        color="$text-colors-color-text-white.main"
                    >
                        <Translate text="Start the quiz" />
                    </Typography>
                </Button>
            </div>
        );
    },
);
QuizAvailableFooter.displayName = "QuizAvailableFooter";
export default QuizAvailableFooter;
