import { Button, Typography } from "@mui/material";
import {
    faSpinner,
    faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./QuizEditorDetails.module.scss";
import { useQuizEditorContext } from "../../state/QuizEditorContext";
import { useMessagingContext } from "contexts/MessagingContext";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

const GeneratingButton = () => {
    return (
        <Button
            variant="disabled-button"
            className={styles.generateButton}
            style={{
                background: "white",
                border: "1px solid #770DB8",
            }}
            startIcon={
                <FontAwesomeIcon
                    icon={faSpinner}
                    style={{ marginRight: 5, fontSize: 12, color: "#770DB8" }}
                    spin
                />
            }
        >
            <Typography variant="button-text-size-xSmall-bold" color="#770DB8">
                <Translate text="Generating questions" />
            </Typography>
        </Button>
    );
};
const DisabledButton = () => {
    const { openAlertMessage } = useMessagingContext();
    const clickHandler = () => {
        openAlertMessage({
            message:
                "Please complete the quiz settings before generating questions.",
            open: true,
            type: "error",
        });
    };
    return (
        <Button
            variant="disabled-button"
            className={styles.generateButton}
            startIcon={
                <FontAwesomeIcon
                    icon={faWandMagicSparkles}
                    style={{ marginRight: 5, fontSize: 12 }}
                />
            }
            onClick={clickHandler}
        >
            <Typography variant="button-text-size-xSmall-bold">
                <Translate text="Generate questions" />
            </Typography>
        </Button>
    );
};

const EnabledButton = () => {
    const { generateQuestions, setShowQuizDetails } = useQuizEditorContext();
    const clickHandler = () => {
        tracker("Quiz Generate Questions Clicked");
        generateQuestions();
        setShowQuizDetails(false);
    };
    return (
        <Button
            variant="ai-button"
            className={styles.generateButton}
            startIcon={
                <FontAwesomeIcon
                    icon={faWandMagicSparkles}
                    style={{ marginRight: 5, fontSize: 12 }}
                />
            }
            onClick={clickHandler}
        >
            <Typography variant="button-text-size-xSmall-bold">
                <Translate text="Generate questions" />
            </Typography>
        </Button>
    );
};

export const GenerateQuestionsButton = () => {
    const { isGenerating, generateQuestionsEnabled } = useQuizEditorContext();
    if (isGenerating) return <GeneratingButton />;
    if (!generateQuestionsEnabled) return <DisabledButton />;
    return <EnabledButton />;
};
