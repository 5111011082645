@use "../../../../styles/variables.module";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.footer {
    padding: 0px 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
