import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faCircleCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./QuestionEditorCard.module.scss";
import variables from "styles/_variables.module.scss";
import { Question } from "types";

import DragHandle from "./DragHandle";
import Translate from "components/Translate/Translate";
import EditableTitle from "features/CollectionEditor/EditableTitle/EditableTitle";

interface Props {
    answer: { id: string; text: string };
    localQuestion: Question;
    setLocalQuestion: (question: Question) => void;
    inEditMode: boolean;
    initialiseTitleSelected?: boolean;
    hideDelete?: boolean;
}

const AnswerEditor = ({
    answer,
    localQuestion,
    setLocalQuestion,
    inEditMode,
    initialiseTitleSelected,
    hideDelete,
}: Props) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: answer.id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
    };
    return !inEditMode ? (
        <div key={answer.id} className={styles.answer}>
            <div className={styles.circle} />
            <Typography
                variant="text-size-Small-medium"
                color="$text-colors-color-text-dark.main"
            >
                {answer.text}
            </Typography>
            {answer.id === localQuestion.correctAnswerId && (
                <FontAwesomeIcon
                    icon={faCircleCheck}
                    color={variables["message-colors-color-message-success"]}
                    fontSize={20}
                />
            )}
        </div>
    ) : (
        <div ref={setNodeRef} style={style} className={styles.editAnswer}>
            <div className={styles.answer}>
                <DragHandle
                    draggable={{
                        attributes: attributes,
                        listeners: listeners,
                    }}
                />
                <EditableTitle
                    title={answer.text}
                    titleChangeHandler={(title: string) => {
                        const auxAnswers = [...localQuestion.answers];
                        auxAnswers[
                            auxAnswers.findIndex((el) => el.id === answer.id)
                        ] = {
                            id: answer.id,
                            text: title,
                        };
                        setLocalQuestion({
                            ...localQuestion,
                            answers: auxAnswers,
                        });
                    }}
                    typographyVariant="text-size-Small-medium"
                    initialiseTitleSelected={initialiseTitleSelected}
                    styleOverrides={{
                        margin: "unset",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "unset",
                    }}
                />
                {answer.id === localQuestion.correctAnswerId ? (
                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        color={
                            variables["message-colors-color-message-success"]
                        }
                        fontSize={20}
                    />
                ) : (
                    <Button
                        variant="outlined"
                        className={styles.markAsCorrect}
                        onClick={() => {
                            setLocalQuestion({
                                ...localQuestion,
                                correctAnswerId: answer.id,
                            });
                        }}
                    >
                        <Typography variant="text-size-xxSmall-medium">
                            <Translate text="Mark as correct" />
                        </Typography>
                    </Button>
                )}
            </div>
            {!hideDelete && (
                <FontAwesomeIcon
                    icon={faTrash}
                    color={
                        localQuestion.answers.length > 2 &&
                        answer.id !== localQuestion.correctAnswerId
                            ? variables["message-colors-color-message-danger"]
                            : variables["text-colors-color-text-light-gray"]
                    }
                    cursor={
                        localQuestion.answers.length > 2 &&
                        answer.id !== localQuestion.correctAnswerId
                            ? "pointer"
                            : undefined
                    }
                    fontSize={14}
                    onClick={() => {
                        if (
                            localQuestion.answers.length > 2 &&
                            answer.id !== localQuestion.correctAnswerId
                        ) {
                            setLocalQuestion({
                                ...localQuestion,
                                answers: localQuestion.answers.filter(
                                    (el) => el.id !== answer.id,
                                ),
                            });
                        }
                    }}
                />
            )}
        </div>
    );
};

export default AnswerEditor;
