import { isEqual } from "lodash";
import { useCallback, useContext, useState } from "react";
import { useBlocker, useBeforeUnload } from "react-router-dom";

import { TranslatableText } from "components/Translate/Translate";
import MessagingContext from "contexts/MessagingContext";

const usePreventNavigation = ({
    blocked,
    messageBody,
}: {
    blocked: boolean;
    messageBody: {
        title: TranslatableText;
        body: TranslatableText;
        type: "Save" | "Delete" | "OK" | "Yes, leave the page";
    };
}) => {
    const { openMessageModal } = useContext(MessagingContext);
    const [isDisplayingUnloadMessage, setIsDisplayingUnloadMessage] =
        useState<boolean>(false);
    const unloadHandlerBrowser = useCallback(
        (e: BeforeUnloadEvent) => {
            if (!blocked) return false;
            e.preventDefault();
        },
        [blocked],
    );
    useBeforeUnload(unloadHandlerBrowser);
    const unloadHandlerRouter = useCallback(
        ({
            currentLocation,
            nextLocation,
        }: {
            currentLocation?: { pathname: string; state?: string };
            nextLocation?: { pathname: string; state?: string };
        }) => {
            if (!blocked) return false;
            if (!currentLocation || !nextLocation) return false;
            if (isEqual(currentLocation.pathname, nextLocation.pathname))
                return false;
            if (isEqual(currentLocation.state, nextLocation.state))
                return false;
            return true;
        },
        [blocked],
    );
    const blocker = useBlocker(unloadHandlerRouter);
    if (!isDisplayingUnloadMessage && blocker.state === "blocked") {
        openMessageModal({
            isModalVisible: true,
            ...messageBody,
            primaryActionHandler: blocker.proceed,
            secondaryActionHandler: () => {
                blocker.reset();
                setIsDisplayingUnloadMessage(false);
            },
        });
        setIsDisplayingUnloadMessage(true);
    }
};

export default usePreventNavigation;
