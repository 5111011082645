import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
    faClipboardQuestion,
    faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { TextField, Typography } from "@mui/material";

import * as styles from "./QuizEditorDetails.module.scss";
import { useQuizEditorContext } from "../../state/QuizEditorContext";
import { useAppContext } from "contexts/AppContext";
import { useCollectionSearchInfiniteQuery } from "hooks/api/useSearchQuery";
import { SuggestedSource } from "types";

import { GenerateQuestionsButton } from "./GenerateQuestionsButton";
import NumberOfQuestionsField from "./NumberOfQuestionsField";
import LabeledSwitch from "components/InputFields/LabeledSwitch/LabeledSwitch";
import Panel, { PanelSection } from "components/Panel/Panel";
import SourceCard from "components/SourceCard/SourceCard";
import Translate, { translate } from "components/Translate/Translate";

const TrueFalseSwitch = () => {
    const { multipleChoice, trueOrFalse, setTrueOrFalse, isGenerating } =
        useQuizEditorContext();
    const [showTrueOrFalseTooltip, setShowTrueOrFalseTooltip] = useState(false);
    return (
        <LabeledSwitch
            label="True or false"
            keyname="trueOrFalse"
            value={trueOrFalse}
            updateHandler={() => {
                if (!multipleChoice && trueOrFalse) {
                    setShowTrueOrFalseTooltip(true);
                } else {
                    setTrueOrFalse(!trueOrFalse);
                }
            }}
            switchTooltip={"At least one question type must be selected."}
            showSwitchTooltip={showTrueOrFalseTooltip}
            hideSwitchTooltipHandler={() => setShowTrueOrFalseTooltip(false)}
            disabled={isGenerating}
        />
    );
};

const MultipleChoiceSwitch = () => {
    const { multipleChoice, setMultipleChoice, trueOrFalse, isGenerating } =
        useQuizEditorContext();
    const [showMultipleChoiceTooltip, setShowMultipleChoiceTooltip] =
        useState(false);
    return (
        <LabeledSwitch
            label="Multiple choice"
            keyname="multipleChoice"
            value={multipleChoice}
            updateHandler={() => {
                if (multipleChoice && !trueOrFalse) {
                    setShowMultipleChoiceTooltip(true);
                } else {
                    setMultipleChoice(!multipleChoice);
                }
            }}
            switchTooltip={"At least one question type must be selected."}
            showSwitchTooltip={showMultipleChoiceTooltip}
            hideSwitchTooltipHandler={() => setShowMultipleChoiceTooltip(false)}
            disabled={isGenerating}
        />
    );
};

const QuizEditorDetails = () => {
    const { state } = useLocation();
    const collectionId = state?.collectionId;
    const {
        config: { language },
    } = useAppContext();
    const { topic, setTopic, suggestedSource } = useQuizEditorContext();
    const { data: searchResultsData, status: searchResultsStatus } =
        useCollectionSearchInfiniteQuery();
    const [localTopic, setLocalTopic] = useState<string>(topic);
    const sources: SuggestedSource[] =
        searchResultsStatus === "success" &&
        searchResultsData.pages[0].results.length > 0
            ? [
                  ...(suggestedSource ? [suggestedSource] : []),
                  ...searchResultsData.pages[0].results.filter(
                      (result) => result.id !== suggestedSource?.id,
                  ),
              ]
            : suggestedSource
              ? [suggestedSource]
              : [];
    return (
        <Panel
            styleOverrides={{
                flex: 1,
                gap: "30px",
                overflowY: "auto",
            }}
        >
            <PanelSection title="Topic and context" icon={faClipboardQuestion}>
                <Typography
                    variant="text-size-Base-bold"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text="Topic" />
                    {!collectionId && (
                        <span style={{ color: "#E30613" }}> *</span>
                    )}
                </Typography>
                <TextField
                    style={{ marginTop: "10px" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    required={!collectionId}
                    value={localTopic}
                    onBlur={(e) => setTopic(e.target.value)}
                    onChange={(e) => setLocalTopic(e.target.value)}
                    multiline
                    minRows={5}
                    error={!collectionId && localTopic.length < 3}
                    helperText={
                        !collectionId && localTopic.length < 3
                            ? translate(
                                  language,
                                  "Enter a topic, learning objective or description of the quiz.",
                              )
                            : ""
                    }
                />
            </PanelSection>
            {!collectionId && sources.length > 0 && (
                <PanelSection title="Sources" icon={faLayerGroup}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                        }}
                    >
                        {sources.map((source) => (
                            <SourceCard
                                key={"element" + source.id}
                                source={source}
                            />
                        ))}
                    </div>
                </PanelSection>
            )}
            <PanelSection title="Quiz options" icon={faClipboardQuestion}>
                <div className={styles.questionTypes}>
                    <Typography
                        variant="text-size-Base-bold"
                        color="$text-colors-color-text-dark.main"
                    >
                        <Translate text="Question types" />
                    </Typography>
                    <MultipleChoiceSwitch />
                    <TrueFalseSwitch />
                </div>
                <NumberOfQuestionsField />
            </PanelSection>
            <GenerateQuestionsButton />
        </Panel>
    );
};

export default QuizEditorDetails;
