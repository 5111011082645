import { useEffect, useState } from "react";

import { useMessagingContext } from "contexts/MessagingContext";
import { useDeleteCollectionMutation } from "hooks/api/collections";
import { useDeleteFolderMutation } from "hooks/api/folders";
import { useDeleteQuizMutation } from "hooks/api/quizzes";
import useCollectionsParams from "hooks/useCollectionsParams";
import { ContentElement, Folder } from "types";
import { tracker } from "utils/analytics";

import { AlertMessageProps } from "components/AlertMessage/AlertMessage";
import { MessageModalProps } from "components/MessageModal/MessageModal";
import Translate from "components/Translate/Translate";

const DeleteElementDialog = ({
    element,
    isVisible,
    setIsVisible,
}: {
    element: ContentElement | Folder;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const { openAlertMessage, openMessageModal } = useMessagingContext();
    const collectionsParams = useCollectionsParams();
    const deleteCollectionMutation = useDeleteCollectionMutation();
    const deleteFolderMutation = useDeleteFolderMutation();
    const deleteQuizMutation = useDeleteQuizMutation();
    const isCollection = element.type === "COLLECTION";
    const isFolder = element.type === "FOLDER";
    const [isLatched, setIsLatched] = useState(false);
    useEffect(() => {
        const deleteAlertMessage: AlertMessageProps = {
            message: isCollection
                ? "Collection has been deleted."
                : isFolder
                  ? "Folder has been deleted."
                  : "Quiz has been deleted.",
            type: "error",
            open: true,
        };
        const deleteMessageModal: MessageModalProps = {
            isModalVisible: true,
            title: isCollection
                ? "Delete the collection"
                : isFolder
                  ? "Delete the folder"
                  : "Delete the quiz",
            body: isCollection ? (
                "Are you sure you want to delete the collection? You can't undo this action."
            ) : isFolder ? (
                <div>
                    <Translate text="Are you sure you want to delete the folder? All collections and sub-folders will be deleted." />
                    <Translate text="You can’t undo this action." />
                </div>
            ) : (
                "Are you sure you want to delete the quiz? You can’t undo this action."
            ),
            type: "Delete",
            primaryActionHandler: isCollection
                ? () => {
                      tracker("Deleted Colelction");
                      deleteCollectionMutation.mutate(
                          { collectionId: element.id, collectionsParams },
                          {
                              onSuccess: () =>
                                  openAlertMessage(deleteAlertMessage),
                              onSettled: () => setIsVisible(false),
                          },
                      );
                  }
                : isFolder
                  ? () => {
                        tracker("Delete Folder");
                        deleteFolderMutation.mutate(element, {
                            onSuccess: () =>
                                openAlertMessage(deleteAlertMessage),
                            onSettled: () => setIsVisible(false),
                        });
                    }
                  : () => {
                        tracker("Deleted Quiz");
                        deleteQuizMutation.mutate(
                            { quizId: element.id, collectionsParams },
                            {
                                onSuccess: () =>
                                    openAlertMessage(deleteAlertMessage),
                                onSettled: () => setIsVisible(false),
                            },
                        );
                    },
        };
        if (isVisible && !isLatched) {
            setIsLatched(true);
            openMessageModal(deleteMessageModal);
        } else if (!isVisible && isLatched) {
            setIsLatched(false);
        }
    }, [
        collectionsParams,
        deleteCollectionMutation,
        deleteFolderMutation,
        deleteQuizMutation,
        element,
        isCollection,
        isFolder,
        isLatched,
        isVisible,
        openAlertMessage,
        openMessageModal,
        setIsVisible,
    ]);
    return <></>;
};

export default DeleteElementDialog;
