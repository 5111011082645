import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    faArrowRight,
    faEdit,
    faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import * as styles from "./MyCurriculumTile.module.scss";
import curriculumBackground from "./curriculum-card-bckg.webp";
import useCurriculumQuery, {
    useCurriculumsQuery,
} from "hooks/api/useCurriculumQuery";
import useGradesQuery from "hooks/api/useGradeQuery";
import useUserQuery from "hooks/api/useUserQuery";
import {
    useUserCurrentLayerMutation,
    useUserCurrentLayerQuery,
} from "hooks/api/useUserSettingsQuery";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import CurriculumLogo from "components/CurriculumLogo/CurriculumLogo";
import DialogBox from "components/DialogBox/DialogBox";
import Loading from "components/Loading/Loading";
import RadioListSelector from "components/RadioListSelector/RadioListSelector";
import Translate, { TranslatableText } from "components/Translate/Translate";
import DashboardTile from "features/DashboardPage/components/DashboardTile/DashboardTile";

const MyCurriculumTile = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data: userData } = useUserQuery();
    const { data: userCurrentLayer, status: userCurrentLayerStatus } =
        useUserCurrentLayerQuery();
    const userCurrentLayerMutation = useUserCurrentLayerMutation();
    const { data: curriculumData } = useCurriculumQuery();
    const { data: gradesData } = useGradesQuery();
    const selectedGrade = userCurrentLayer?.gradeId
        ? gradesData?.find(
              (el) => el.id === parseInt(userCurrentLayer?.gradeId),
          )
        : undefined;
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
    const { data: curriculums, status: curriculumsStatus } =
        useCurriculumsQuery();
    const [curriculumId, setCurriculumId] = useState<number | undefined>(
        undefined,
    );
    const { data: grades, status: gradesStatus } = useGradesQuery(curriculumId);
    const [gradeId, setGradeId] = useState<number | undefined>(undefined);
    let renderTitle: TranslatableText = "Select your curriculum";
    let renderComponent;
    if (isDialogVisible && userCurrentLayerStatus === "success") {
        if (curriculumsStatus !== "success") {
            renderComponent = <></>;
        } else {
            if (!curriculumId) {
                renderComponent = (
                    <RadioListSelector
                        itemList={curriculums}
                        itemId={curriculumId}
                        setItemId={(value) => {
                            tracker("set default curriculum");
                            setCurriculumId(value);
                            queryClient.setQueryData(
                                ["user-current-layers", userData?.me.id],
                                { ...userCurrentLayer, curriculumId: value },
                            );
                        }}
                    />
                );
            } else {
                const foundCurriculum = curriculums.find(
                    (el) => el.id === curriculumId,
                );
                if (foundCurriculum?.structure.includes("GRADE") && !gradeId) {
                    renderTitle = "Select your grade";
                    if (gradesStatus !== "success") {
                        renderComponent = <Loading />;
                    } else {
                        renderComponent = (
                            <RadioListSelector
                                itemList={grades}
                                itemId={gradeId}
                                setItemId={(value) => {
                                    tracker("set default grade");
                                    setGradeId(value);
                                    queryClient.setQueryData(
                                        [
                                            "user-current-layers",
                                            userData?.me.id,
                                        ],
                                        { ...userCurrentLayer, gradeId: value },
                                    );
                                }}
                            />
                        );
                    }
                } else {
                    const isGradeTopicQueryStructure =
                        JSON.stringify(foundCurriculum?.structure) ===
                        '["GRADE","TOPIC","QUERY"]';
                    const navState = {
                        curriculumId: curriculumId
                            ? curriculumId.toString()
                            : userCurrentLayer.curriculumId,
                        ...(gradeId
                            ? { gradeId: gradeId.toString() }
                            : userCurrentLayer?.gradeId
                              ? { gradeId: userCurrentLayer?.gradeId }
                              : null),
                        ...(isGradeTopicQueryStructure
                            ? { topicListId: gradeId?.toString() }
                            : null),
                    };
                    userCurrentLayerMutation.mutate(navState);
                    setIsDialogVisible(false);
                }
            }
        }
    }
    const clickHandler = () => {
        tracker("My curriculum tile clicked");
        selectedGrade
            ? navigate(
                  `/curriculum/${curriculumData?.id}/grade/${selectedGrade?.id}`,
              )
            : navigate(`/curriculum/${curriculumData?.id}`);
    };
    return (
        <>
            <DashboardTile
                headerTitle={{
                    text: "My curriculum",
                }}
                headerIcon={{
                    icon: faGraduationCap,
                    color: "linear-gradient(180deg, #69bfac 0%, #368271 100%)",
                    backgroundColor: "#e1f2ee",
                }}
                footer={
                    <div className={styles.footer}>
                        <div
                            className={styles.footerEdit}
                            onClick={(e) => {
                                e.stopPropagation();
                                setCurriculumId(undefined);
                                setGradeId(undefined);
                                setIsDialogVisible(true);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                color={
                                    variables["border-colors-color-border-blue"]
                                }
                                fontSize={12}
                            />
                        </div>
                        <div className={styles.footerText}>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                fontSize={12}
                                style={{ padding: "4px 0px" }}
                            />
                            <Typography variant="text-size-xSmall-bold">
                                <Translate text="View curriculum" />
                            </Typography>
                        </div>
                    </div>
                }
                clickHandler={clickHandler}
                styleOverrides={{
                    background: `url(${curriculumBackground}) lightgray 50% / cover no-repeat`,
                }}
            >
                <div className={styles.container}>
                    <CurriculumLogo
                        name={curriculumData?.name}
                        logoUrl={curriculumData?.logoUrl}
                    />
                    <div className={styles.curiculumAndGrade}>
                        <Typography
                            variant="text-size-Base-bold"
                            color="$text-colors-color-text-dark.main"
                            className={styles.curriculum}
                        >
                            {curriculumData?.name}
                        </Typography>
                        {selectedGrade && (
                            <Chip
                                variant="outlined"
                                label={selectedGrade?.name}
                                style={{ width: "fit-content" }}
                            />
                        )}
                    </div>
                </div>
            </DashboardTile>
            <DialogBox
                isOpen={isDialogVisible}
                closeHandler={() => {
                    return;
                }}
                title={renderTitle}
                styleOverrides={{
                    minWidth: "320px",
                    maxWidth: "640px",
                    margin: 0,
                }}
            >
                {renderComponent}
            </DialogBox>
        </>
    );
};

export default MyCurriculumTile;
