import { useState } from "react";
import { Box } from "@mui/material";

import { useMessagingContext } from "contexts/MessagingContext";
import { useMoveCollectionMutation } from "hooks/api/collections";
import {
    recursivelyFilterWorkspaces,
    useFullWorkspacesQuery,
    useFolderTreeQuery,
    useMoveFolderMutation,
} from "hooks/api/folders";
import { useMoveQuizMutation } from "hooks/api/quizzes";
import { ContentElement, Folder, Permission } from "types";
import { tracker } from "utils/analytics";

import DialogBox from "components/DialogBox/DialogBox";
import TreeExplorer from "components/TreeExplorer/TreeExplorer";

const MoveElementDialog = ({
    element,
    isVisible,
    setIsVisible,
}: {
    element: ContentElement | Folder;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const { openAlertMessage } = useMessagingContext();
    const moveCollectionMutation = useMoveCollectionMutation();
    const moveFolderMutation = useMoveFolderMutation();
    const moveQuizMutation = useMoveQuizMutation();
    const { data: fullWorkspacesData } = useFullWorkspacesQuery();
    const allFolders = recursivelyFilterWorkspaces(
        fullWorkspacesData ?? [],
        element.id,
    );
    const [newParentId, setNewParentId] = useState<number>();
    const [newParentPermissions, setNewParentPermissions] = useState<
        Permission[]
    >([]);
    const [newParentPathParams, setNewParentPathParams] = useState<string[]>(
        [],
    );
    const isCollection = element.type === "COLLECTION";
    const isFolder = element.type === "FOLDER";
    const isQuiz = element.type === "QUIZ";
    const moveElementHandler = () => {
        if (!newParentId) return;
        if (isCollection) {
            moveCollectionMutation.mutate(
                {
                    collection: element,
                    newParentId,
                },
                {
                    onSuccess: () => {
                        openAlertMessage({
                            message: "Collection moved successfully.",
                            type: "success",
                            open: true,
                        });
                        tracker("Moved collection");
                        setNewParentId(undefined);
                        setNewParentPermissions([]);
                        setNewParentPathParams([]);
                        setIsVisible(false);
                    },
                    onError: (error) => {
                        openAlertMessage({
                            message: "Error",
                            type: "error",
                            open: true,
                        });
                        console.error(error);
                        setNewParentId(undefined);
                        setNewParentPermissions([]);
                        setNewParentPathParams([]);
                        setIsVisible(false);
                    },
                },
            );
        } else if (isFolder) {
            moveFolderMutation.mutate(
                {
                    folder: element,
                    newParentId,
                },
                {
                    onSuccess: () => {
                        openAlertMessage({
                            message: "Folder moved successfully.",
                            type: "success",
                            open: true,
                        });
                        tracker("Moved folder");
                        setNewParentId(undefined);
                        setNewParentPermissions([]);
                        setNewParentPathParams([]);
                        setIsVisible(false);
                    },
                    onError: (error) => {
                        openAlertMessage({
                            message: "Error",
                            type: "error",
                            open: true,
                        });
                        console.error(error);
                        setNewParentId(undefined);
                        setNewParentPermissions([]);
                        setNewParentPathParams([]);
                        setIsVisible(false);
                    },
                },
            );
        } else if (isQuiz) {
            moveQuizMutation.mutate(
                {
                    quiz: element,
                    newParentId,
                },
                {
                    onSuccess: () => {
                        openAlertMessage({
                            message: "Quiz moved successfully.",
                            type: "success",
                            open: true,
                        });
                        tracker("Moved quiz");
                        setNewParentId(undefined);
                        setNewParentPermissions([]);
                        setNewParentPathParams([]);
                        setIsVisible(false);
                    },
                    onError: (error) => {
                        openAlertMessage({
                            message: "Error",
                            type: "error",
                            open: true,
                        });
                        console.error(error);
                        setNewParentId(undefined);
                        setNewParentPermissions([]);
                        setNewParentPathParams([]);
                        setIsVisible(false);
                    },
                },
            );
        }
        return;
    };
    const [fetchId, setFetchId] = useState<string>();
    useFolderTreeQuery(fetchId); // don't use the return as its managed via the allFolders query
    const fetchFolderHandler = (folderId: string | string[]) => {
        if (Array.isArray(folderId)) return;
        setFetchId(folderId);
    };
    return (
        <DialogBox
            isOpen={isVisible}
            closeHandler={() => setIsVisible(false)}
            title="Move to"
            withCloseButton
            styleOverrides={{ width: "435px", height: "unset" }}
            secondaryButton={{
                label: "Cancel",
                clickHandler: () => setIsVisible(false),
            }}
            primaryButton={{
                label: "Save",
                clickHandler: moveElementHandler,
                disabled: !newParentPermissions.includes("CREATE"),
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    backgroundColor:
                        "$background-colors-color-background-light.main",
                }}
            >
                {allFolders && (
                    <TreeExplorer
                        selectedNodes={newParentPathParams}
                        pathRoot=""
                        data={allFolders}
                        onNodeClick={(_e, nodeId) => fetchFolderHandler(nodeId)}
                        elementClickHandler={(element, pathString) => {
                            pathString &&
                                setNewParentPathParams(pathString.split("/"));
                            setNewParentId(element.id);
                            setNewParentPermissions(element.permissions);
                        }}
                        withPermissions
                    />
                )}
            </Box>
        </DialogBox>
    );
};

export default MoveElementDialog;
