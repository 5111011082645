import { useState } from "react";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./Subheader.module.scss";
import { useCollectionQuery } from "hooks/api/collections";

import ShareElementDialog from "components/ContextMenu/dialogs/ShareElementDialog";
import Translate from "components/Translate/Translate";

interface Props {
    collectionId: number;
    showLabel: boolean;
    disabled: boolean;
}

const TutorShareButton = ({ collectionId, showLabel, disabled }: Props) => {
    const { data: collectionData } = useCollectionQuery(collectionId);
    const [isTutorShareModalOpen, setIsTutorShareModalOpen] = useState(false);
    return (
        <>
            <Button
                variant="outlined"
                className={styles.tutorShareButton}
                disabled={disabled}
                sx={{
                    "&:hover": {
                        backgroundColor: "#9b1de80a",
                        border: "1px solid #9b1de8",
                    },
                }}
                onClick={() => setIsTutorShareModalOpen(true)}
            >
                <FontAwesomeIcon icon={faWandMagicSparkles} fontSize={12} />
                {showLabel && (
                    <Typography variant="text-size-xSmall-bold">
                        <Translate text="Share AI Tutor" />
                    </Typography>
                )}
            </Button>
            <ShareElementDialog
                element={{
                    type: "TUTOR",
                    // embedLink: collectionData?.embedLink?.replace(
                    //     "embed",
                    //     "embed/tutorchat",
                    // ),
                    shareLink: collectionData?.shareLink?.replace(
                        "view",
                        "view/tutorchat",
                    ),
                }}
                isVisible={isTutorShareModalOpen}
                setIsVisible={setIsTutorShareModalOpen}
            />
        </>
    );
};

export default TutorShareButton;
