import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faList,
    faTableCellsLarge,
} from "@fortawesome/free-solid-svg-icons";

import * as styles from "./Subheader.module.scss";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import ContextMenu from "components/ContextMenu/ContextMenu";
import Translate from "components/Translate/Translate";
import { ContainerElement } from "types";

const ViewAsContextMenu = ({
    viewAs,
    updateViewAs,
    isVisible,
    setIsVisible,
    disabled,
}: {
    viewAs: ContainerElement["view"];
    updateViewAs: (viewAs: ContainerElement["view"]) => void;
    isVisible: boolean;
    setIsVisible: (open: boolean) => void;
    disabled?: boolean;
}) => {
    const { isMobileScreen } = useScreenSizes();
    const viewAsLabel = viewAs.toLowerCase() as Lowercase<typeof viewAs>;
    return (
        <ContextMenu
            options={[
                {
                    label: "Grid",
                    icon: faTableCellsLarge,
                    clickHandler: () => {
                        tracker("View as grid clicked");
                        updateViewAs("GRID");
                    },
                },
                {
                    label: "List",
                    icon: faList,
                    clickHandler: () => {
                        tracker("View as list clicked");
                        updateViewAs("LIST");
                    },
                },
            ]}
            onClose={() => setIsVisible(false)}
            disabled={disabled}
            styleOverrides={{ width: isMobileScreen ? "100%" : undefined }}
        >
            <Button
                variant={isMobileScreen ? "outlined" : "text"}
                className={styles.mobileButton}
                onClick={() => setIsVisible(true)}
            >
                <Typography variant="text-size-xSmall-bold">
                    <Translate text={`View as ${viewAsLabel}`} />
                </Typography>
                <FontAwesomeIcon
                    icon={isVisible ? faChevronUp : faChevronDown}
                    fontSize={12}
                />
            </Button>
        </ContextMenu>
    );
};

export default ViewAsContextMenu;
