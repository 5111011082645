import { useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { faCircleUp, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Rating,
    TextareaAutosize,
    Typography,
} from "@mui/material";
import Markdown from "markdown-to-jsx";

import * as styles from "./TutorChat.module.scss";
import { useChatMutation, useInitChatMutation } from "./api/chat";
import { useFeedbackMutation } from "./api/feedback";
import iconChatBot from "./assets/icon-chat-bot.webp";
import iconChatUser from "./assets/icon-chat-user.webp";
import { ChatData } from "./types";
import { useAppContext } from "contexts/AppContext";
import {
    useCollectionQuery,
    useItemsInCollectionQuery,
    useViewItemsInCollectionQuery,
} from "hooks/api/collections";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { isEmbedMode } from "utils/newtools";

import BackgroundPattern from "components/BackgroundPattern/BackgroundPattern";
import DialogBox from "components/DialogBox/DialogBox";
import { useMessagingContext } from "contexts/MessagingContext";
import Translate, { translate } from "components/Translate/Translate";

const SourcesPanel = ({
    collectionId,
    hashId,
    hoverId,
    displayingSources,
}: {
    collectionId?: number;
    hashId?: string;
    hoverId?: number;
    displayingSources: Set<number>;
}) => {
    const { isMobileScreen } = useScreenSizes();
    const { data: sourcesApp } = useItemsInCollectionQuery(
        hashId ? undefined : collectionId,
    );
    const { data: sourcesShare } = useViewItemsInCollectionQuery({
        id: collectionId?.toString(),
        hash: hashId,
    });
    const sources = sourcesApp ?? sourcesShare;
    if (!sources || displayingSources.size === 0) return <></>;
    const sanitiseText = (originalText: string) =>
        originalText.replace(/(<([^>]+)>)/gi, "");

    return (
        <div
            style={{
                padding: isMobileScreen ? 10 : 20,
                minWidth: 200,
                maxWidth: isMobileScreen ? "100%" : "20%",
                minHeight: "106px",
                maxHeight: isMobileScreen ? "106px" : "none",
                display: "flex",
                flexDirection: "column",
                gap: 10,
                background: "#fff",
                border: "1px solid #CBCBCC",
                borderRadius: 10,
                overflowX: "hidden",
                overflowY: "auto",
                zIndex: 1,
            }}
        >
            <Typography
                variant="text-size-Medium-bold"
                color="$text-colors-color-text-dark.main"
            >
                <Translate text="Sources" />
            </Typography>
            {sources.map((source, index) => {
                if (!displayingSources.has(index + 1))
                    return <React.Fragment key={source.id} />;

                return (
                    <div
                        key={source.id}
                        style={{
                            padding: 10,
                            display: "flex",
                            flexDirection: "column",
                            border: "2px solid grey",
                            borderRadius: 10,
                            boxShadow:
                                hoverId === index
                                    ? "0 8px 16px 0 rgba(0, 0, 0, 0.5)"
                                    : "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                            transition: "box-shadow 0.3s ease-in-out",
                        }}
                    >
                        <div>
                            <Typography variant="text-size-Base-bold">
                                <a
                                    href={source.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {index + 1 + ": " + source.title}
                                </a>
                            </Typography>
                        </div>
                        {!isMobileScreen && (
                            <div
                                style={{
                                    maxHeight: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 3,
                                }}
                            >
                                <Markdown>
                                    {sanitiseText(source.description)}
                                </Markdown>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

const ChatFeedbackModal = ({
    botId,
    userId,
    sessionId,
    isVisible,
    hideModal,
    conversation,
}: {
    botId: string;
    userId: string;
    sessionId: string;
    isVisible: boolean;
    hideModal: () => void;
    conversation: ChatData[];
}) => {
    const [qualityRating, setQualityRating] = useState(2.5);
    const [accuracyRating, setAccuracyRating] = useState(2.5);
    const [commentText, setCommentText] = useState("");
    const chatFeedbackMutation = useFeedbackMutation();
    const submitFeedback = () => {
        chatFeedbackMutation.mutate(
            {
                botId,
                userId,
                sessionId,
                accuracy: accuracyRating,
                quality: qualityRating,
                comment: commentText,
                conversation,
            },
            {
                onSuccess: hideModal,
            },
        );
    };
    return (
        <DialogBox
            title="Tutor Feedback"
            isOpen={isVisible}
            closeHandler={hideModal}
            styleOverrides={{
                width: "450px",
                height: "fit-content",
                justifyContent: "flex-end",
            }}
            containerStyleOverrides={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
            }}
        >
            <div
                style={{
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div>
                    Thanks for using this Tutor Chatbot. Please provide your
                    review using the stars below, and provide any additional
                    feedback in the textbox.
                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                        gap: 20,
                    }}
                >
                    <div>Quality</div>
                    <Rating
                        name="half-rating"
                        defaultValue={2.5}
                        precision={0.5}
                        value={qualityRating}
                        onChange={(_e, value) =>
                            value && setQualityRating(value)
                        }
                    />
                    <div>Accuracy</div>
                    <Rating
                        name="half-rating"
                        defaultValue={2.5}
                        precision={0.5}
                        value={accuracyRating}
                        onChange={(_e, value) =>
                            value && setAccuracyRating(value)
                        }
                    />
                </div>
                <TextareaAutosize
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    style={{ minHeight: "50px", marginBottom: 10 }}
                />
                <Button onClick={submitFeedback} variant="outlined">
                    Submit Feedback
                </Button>
            </div>
        </DialogBox>
    );
};

const ChatBubble = ({
    data,
    setHoverId,
}: {
    data: ChatData;
    setHoverId: (id: number | null) => void;
}) => {
    const { isMobileScreen } = useScreenSizes();
    return (
        <div style={{ display: "flex", gap: 10 }}>
            {data.role === "SYSTEM" ? (
                <FontAwesomeIcon
                    icon={faWarning}
                    color="red"
                    fontSize={20}
                    style={{
                        width: isMobileScreen ? 20 : 40,
                        height: isMobileScreen ? 20 : 40,
                        flexShrink: 0,
                    }}
                />
            ) : (
                <img
                    src={data.role === "ASSISTANT" ? iconChatBot : iconChatUser}
                    style={{
                        width: isMobileScreen ? 20 : 40,
                        height: isMobileScreen ? 20 : 40,
                    }}
                    alt={
                        data.role === "ASSISTANT" ? "assistantIcon" : "userIcon"
                    }
                />
            )}
            <div style={{ borderLeft: "2px solid grey", paddingLeft: 10 }}>
                <Markdown
                    options={{
                        overrides: {
                            sup: {
                                component: CustomSupComponent,
                                props: {
                                    setHoverId: setHoverId,
                                },
                            },
                        },
                    }}
                >
                    {data.message}
                </Markdown>
                {/* {data.references && (
                    <>
                        <Typography
                            variant="text-size-Base-bold"
                            component="div"
                            style={{ marginTop: "10px" }}
                        >
                            Sources
                        </Typography>
                        <Markdown
                            options={{
                                overrides: {
                                    a: {
                                        component: CustomAnchorComponent,
                                        props: {
                                            setHoverId: setHoverId,
                                        },
                                    },
                                    code: {
                                        component: "span",
                                    },
                                },
                            }}
                        >
                            {data.references}
                        </Markdown>
                    </>
                )} */}
            </div>
        </div>
    );
};
const ProcessingBubble = () => {
    const { isMobileScreen } = useScreenSizes();
    return (
        <div style={{ display: "flex", gap: 10 }}>
            <img
                src={iconChatBot}
                style={{
                    width: isMobileScreen ? 20 : 40,
                    height: isMobileScreen ? 20 : 40,
                }}
                alt="chatIcon"
            />
            <div style={{ borderLeft: "2px solid grey", paddingLeft: 10 }}>
                <CircularProgress size={30} thickness={2} />
            </div>
        </div>
    );
};

const CustomAnchorComponent = ({ children, ...props }) => {
    return (
        <a {...props} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
};

const CustomSupComponent = ({ children, setHoverId, ...props }) => {
    const onMouseOver = () => {
        setHoverId(parseInt(children) - 1);
    };
    const onMouseLeave = () => {
        setHoverId(null);
    };
    return (
        <sup onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} {...props}>
            {children}
        </sup>
    );
};

const TutorChat = () => {
    const { isMobileScreen } = useScreenSizes();
    const {
        config: { language },
    } = useAppContext();
    const params = useParams();
    const { openAlertMessage } = useMessagingContext();
    const collectionId = params.collectionId
        ? parseFloat(params.collectionId)
        : undefined;
    const hashId = params.hashId;
    const { data: collection } = useCollectionQuery(collectionId, hashId);
    const botId = params.botId ?? collectionId;
    const userId = params.userId ?? "mvp-test-user";
    const sessionId = useRef(crypto.randomUUID()).current;
    const chatMuation = useChatMutation();
    const initChatMuation = useInitChatMutation();
    const [chatData, setChatData] = useState<Array<ChatData>>([]);
    const [inputValue, setInputValue] = useState("");
    const [searchParams] = useSearchParams();
    const enabledAutomatedFeedback = searchParams.get("feedback") === "true";
    const urlFeedbackTime = searchParams.get("feedbackTime");
    const feedbackTime = urlFeedbackTime
        ? parseInt(urlFeedbackTime)
        : 7.5 * 60 * 1000;
    const triggerFeedbackModal = () => {
        setIsFeedbackModalVisible(true);
        setIsFeedbackButtonVisible(true);
    };
    const chatRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        chatRef.current?.scrollTo({ top: chatRef.current.scrollHeight });
    });
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
    const [isFeedbackButtonVisible, setIsFeedbackButtonVisible] =
        useState(false);
    const hideModalHandler = () => {
        openAlertMessage({
            message: "Feedback received, thanks!",
            type: "success",
            open: true,
        });
        setIsFeedbackModalVisible(false);
    };
    const shareUrl = collection?.shareLink;
    const [hoverId, setHoverId] = useState<number | null>(null);
    const [displayingSources, setDisplayingSources] = useState<Set<number>>(
        new Set(),
    );
    const submitMessage = () => {
        if (
            !collectionId ||
            chatMuation.status === "loading" ||
            inputValue === ""
        )
            return;

        const newChatData: ChatData[] = [
            ...chatData,
            { id: chatData.length, message: inputValue, role: "USER" },
        ];
        setChatData(newChatData);
        chatMuation.mutate(
            {
                sessionId,
                botId,
                userId,
                collectionId,
                conversation: newChatData,
            },
            {
                onSuccess: ({ data }) => {
                    setChatData([
                        ...newChatData,
                        {
                            id: chatData.length + 1,
                            message: data.response,
                            references: data.references,
                            role: "ASSISTANT",
                        },
                    ]);
                    const newDisplayingSources = new Set([
                        ...displayingSources,
                        ...Object.keys(data.references).map((el) =>
                            parseInt(el),
                        ),
                    ]);

                    setDisplayingSources(newDisplayingSources);
                    if (enabledAutomatedFeedback && chatData.length == 0) {
                        setTimeout(triggerFeedbackModal, feedbackTime);
                    }
                },
                onError: (error) => {
                    console.error(error);
                    setChatData([
                        ...newChatData,
                        {
                            id: chatData.length + 1,
                            message: "AN ERROR OCCURRED",
                            role: "SYSTEM",
                        },
                    ]);
                },
            },
        );
        setInputValue("");
    };

    const [isInit, setIsInit] = useState(false);
    useEffect(() => {
        if (isInit) return;
        initChatMuation.mutate(
            {
                sessionId,
                botId,
                userId,
                collectionId,
            },
            {
                onSuccess: ({ data }) =>
                    setChatData([
                        {
                            id: 1,
                            message: data.response,
                            references: data.references,
                            role: "ASSISTANT",
                        },
                    ]),
            },
        );
        setIsInit(true);
    }, [botId, collectionId, initChatMuation, isInit, sessionId, userId]);
    return (
        <div
            style={{
                margin: 10,
                height: isEmbedMode
                    ? window.innerHeight - 20
                    : window.innerHeight - 91,
                display: "flex",
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    background: "#FFF",
                    border: "1px solid  #CBCBCC",
                    borderRadius: "15px",
                    boxShadow: "0px 5px 20px 0px rgba(108, 117, 125, 0.20)",
                    overflow: "hidden",
                }}
            >
                <div className={styles.headerContainer}>
                    <Typography
                        variant="text-size-Medium"
                        color="$text-colors-color-text-dark.main"
                    >
                        Chatbot: {collection?.title}
                    </Typography>
                    {!hashId && shareUrl && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    shareUrl.replace("view", "view/tutorchat") +
                                        "?feedback=true",
                                );
                                alert("Copy Link Clicked");
                            }}
                        >
                            Copy share link
                        </Button>
                    )}
                    {isFeedbackButtonVisible && (
                        <Button
                            onClick={() => setIsFeedbackModalVisible(true)}
                            variant="outlined"
                            size="small"
                        >
                            Provide Additional Feedback
                        </Button>
                    )}
                </div>
                <div
                    style={{
                        padding: isMobileScreen ? "10px" : "40px 40px 20px",
                        width: "100%",
                        display: "flex",
                        flexDirection:
                            isMobileScreen && displayingSources.size !== 0
                                ? "column-reverse"
                                : "row",
                        gap: isMobileScreen ? 10 : 20,
                        flexGrow: 1,
                        position: "relative",
                        overflow: "hidden",
                    }}
                >
                    <BackgroundPattern colourState="ai" height="140px" />
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            background: "#fff",
                            border: "1px solid #CBCBCC",
                            borderRadius: 10,
                            overflow: "hidden",
                            zIndex: 1,
                        }}
                    >
                        <div
                            style={{
                                padding: isMobileScreen
                                    ? "10px"
                                    : "40px 20px 20px 20px",
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "space-between",
                                gap: 20,
                                overflow: "hidden",
                            }}
                        >
                            <div
                                ref={chatRef}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 20,
                                    overflowY: "auto",
                                }}
                            >
                                {chatData.map((el) => (
                                    <ChatBubble
                                        key={el.id}
                                        data={el}
                                        setHoverId={setHoverId}
                                    />
                                ))}
                                {chatMuation.status === "loading" && (
                                    <ProcessingBubble />
                                )}
                            </div>
                            <div style={{ display: "flex", flexGrow: 0 }}>
                                <OutlinedInput
                                    placeholder={translate(
                                        language,
                                        "Type your question",
                                    )}
                                    value={inputValue}
                                    onChange={(e) =>
                                        setInputValue(e.target.value)
                                    }
                                    fullWidth
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            submitMessage();
                                        }
                                    }}
                                    multiline
                                    minRows={1}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={submitMessage}
                                                edge="end"
                                                sx={{
                                                    "&": {
                                                        marginRight: "0px",
                                                        padding: "0px",
                                                    },
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleUp}
                                                    fontSize={15}
                                                    color={
                                                        variables[
                                                            "text-colors-color-text-light-gray"
                                                        ]
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    sx={{
                                        "&": {
                                            padding: "10px",
                                            minHeight: "45px",
                                        },
                                    }}
                                />
                            </div>
                            <ChatFeedbackModal
                                botId={botId}
                                userId={userId}
                                sessionId={sessionId}
                                isVisible={isFeedbackModalVisible}
                                hideModal={hideModalHandler}
                                conversation={chatData}
                            />
                        </div>
                    </div>
                    <SourcesPanel
                        collectionId={collectionId}
                        hashId={hashId}
                        hoverId={hoverId}
                        displayingSources={displayingSources}
                    />
                </div>
            </div>
        </div>
    );
};

export default TutorChat;
