import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import * as styles from "./QuestionViewerCard.module.scss";
import variables from "styles/_variables.module.scss";

interface Props {
    answer: { id: string; text: string };
    isCorrectAnswer: boolean;
}

const AnswerViewer = ({ answer, isCorrectAnswer }: Props) => {
    return (
        <div className={styles.answer}>
            <div className={styles.circle} />
            <Typography
                variant="text-size-Small-medium"
                color="$text-colors-color-text-dark.main"
            >
                {answer.text}
            </Typography>
            {isCorrectAnswer && (
                <FontAwesomeIcon
                    icon={faCircleCheck}
                    color={variables["message-colors-color-message-success"]}
                    fontSize={20}
                />
            )}
        </div>
    );
};

export default AnswerViewer;
