@use "../../../../styles/variables.module";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px 40px 10px 40px; // Padding top 40px to give CurriculumNavBar space
    background-size: cover;
    background-image: url("./assets/background.png");
}

.curriculumLogo {
    background-color: white;
    border-radius: 100%;
    fill: #fff;
    stroke-width: 1px;
    stroke: var(--border-colors-color-border-primary, #cbcbcc);
    width: 60px;
    height: 60px;
    padding: 10px;
    display: flex;
    justify-items: center;
}

.curriculumMissingLogo {
    background-color: variables.$wizenoze-brand-color-wizenoze-green;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 100%;
    height: 100%;
}

.curriculumDetails {
    display: flex;
    align-items: center;
    text-shadow: 0px 1px 2px rgba(53, 53, 53, 0.45);
    gap: 20px;
    img {
        width: 62px;
    }
}

.subheader {
    height: 50px;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    background-color: white;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
}

.shareButtonContainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.createCollectionButton {
    padding: 10px 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media (max-width: variables.$bp-md) {
        padding: 10px;
        width: 30px;
        border-radius: 50%;
    }
}

.shareButton {
    padding: 10px 20px;
    height: 30px;
    gap: 10px;
}

@media (max-width: variables.$bp-lg) {
    .subheader {
        justify-content: space-between;
    }
}

@media (max-width: variables.$bp-md) {
    .container {
        padding: 50px 10px 10px 10px; // Padding top 40px to give CurriculumNavBar space
    }
    .subheader {
        padding: 10px 10px;
    }
}
