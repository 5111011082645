import React, { CSSProperties, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./DialogBox.module.scss";
import { useZendeskContext } from "contexts/ZendeskContext";
import useScreenSizes from "hooks/useScreenSizes";

import DialogFooter, { DialogFooterProps } from "./DialogFooter/DialogFooter";
import Translate, { TranslatableText } from "components/Translate/Translate";

type Props = Partial<DialogFooterProps> & {
    isOpen: boolean;
    closeHandler: () => void;
    disableEscapeKeyDown?: boolean;
    title: TranslatableText | JSX.Element;
    withCloseButton?: boolean;
    styleOverrides?: CSSProperties;
    containerStyleOverrides?: CSSProperties;
    children: React.ReactNode;
};

const DialogBox = ({
    isOpen,
    closeHandler,
    disableEscapeKeyDown,
    title,
    withCloseButton,
    styleOverrides,
    containerStyleOverrides,
    children,
    primaryButton,
    secondaryButton,
    footerStyleOverrides,
}: Props) => {
    const { showZendesk, hideZendesk } = useZendeskContext();
    useEffect(() => {
        if (isOpen) hideZendesk();
        else showZendesk();
    }, [hideZendesk, isOpen, showZendesk]);
    const { isMobileScreen } = useScreenSizes();
    const isFooterEnabled = primaryButton || secondaryButton;
    return (
        <Dialog
            onClick={(e) => {
                e.stopPropagation();
            }}
            open={isOpen}
            onClose={closeHandler}
            disableEscapeKeyDown={disableEscapeKeyDown}
            sx={{
                "& .MuiDialog-container": {
                    ...containerStyleOverrides,
                    "& .MuiPaper-root": {
                        width: "100%",
                        height: "100%",
                        maxWidth: "1440px",
                        margin: "40px",
                        borderRadius: "15px",
                        position: { xs: "absolute", sm: "relative" },
                        top: { xs: 0 },
                        background: (theme) =>
                            theme.palette[
                                "$background-colors-color-background-light"
                            ].main,
                        ...styleOverrides,
                    },
                },
            }}
        >
            <DialogTitle className={styles.title}>
                <Typography
                    variant={
                        !isMobileScreen
                            ? "text-size-Medium"
                            : "text-size-xSmall-bold"
                    }
                    color={"$text-colors-color-text-dark.main"}
                >
                    {typeof title === "string" ? (
                        <Translate text={title} />
                    ) : (
                        title
                    )}
                </Typography>
                {withCloseButton && (
                    <FontAwesomeIcon
                        icon={faXmark}
                        className={styles.closeButton}
                        onClick={closeHandler}
                    />
                )}
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 0,
                }}
                dividers
            >
                {children}
            </DialogContent>
            {isFooterEnabled && (
                <DialogFooter
                    secondaryButton={secondaryButton}
                    primaryButton={primaryButton}
                    footerStyleOverrides={footerStyleOverrides}
                />
            )}
        </Dialog>
    );
};

export default DialogBox;
