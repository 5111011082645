@use "../../../../styles/variables.module";

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.mainTitle {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
    border: 1px solid #770db8;
    background: variables.$background-colors-color-background-white;
}
