import React from "react";
import { CSSProperties } from "react";
import {
    faSquareFull,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import * as styles from "./DashboardTile.module.scss";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    children: React.ReactElement | React.ReactElement[];
    headerTitle: { text: TranslatableText; color?: string };
    headerIcon: {
        icon: IconDefinition;
        color: string;
        backgroundColor: string;
    };
    footer: TranslatableText | JSX.Element;
    clickHandler: () => void;
    styleOverrides?: CSSProperties;
}

const DashboardTile = ({
    children,
    headerTitle,
    headerIcon,
    footer,
    clickHandler,
    styleOverrides,
}: Props) => {
    return (
        <div
            className={styles.container}
            style={styleOverrides}
            onClick={clickHandler}
        >
            <div className={styles.header}>
                <Typography
                    variant="text-size-Base-bold"
                    color={
                        headerTitle.color ??
                        "$text-colors-color-text-white.main"
                    }
                >
                    <Translate text={headerTitle.text} />
                </Typography>
                {headerIcon && (
                    <div
                        className={styles.iconCircle}
                        style={{ background: headerIcon.backgroundColor }}
                    >
                        <div
                            className={styles.icon}
                            style={{ background: headerIcon.color }}
                        >
                            <FontAwesomeIcon
                                icon={headerIcon.icon}
                                color={headerIcon.backgroundColor}
                                fontSize={28}
                                mask={faSquareFull}
                                transform="shrink-3"
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.body}>{children}</div>
            {footer &&
                (typeof footer === "string" ? (
                    <div className={styles.footer}>
                        <Typography
                            variant="text-size-Base"
                            color="$text-colors-color-text-white.main"
                        >
                            <Translate text={footer} />
                        </Typography>
                    </div>
                ) : (
                    footer
                ))}
        </div>
    );
};

export default DashboardTile;
