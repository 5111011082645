import { useState } from "react";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./NotifyMeButton.module.scss";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

interface Props {
    trackerEvent: string;
}

const NotifyMeButton = ({ trackerEvent }: Props) => {
    const [showThumbsUp, setShowThumbsUp] = useState(false);
    const clickHandler = (e) => {
        e.stopPropagation();
        if (!showThumbsUp) {
            setShowThumbsUp(true);
            tracker(trackerEvent);
            setTimeout(() => setShowThumbsUp(false), 5000);
        }
    };
    return (
        <Button
            variant="outlined"
            color="$text-colors-color-text-white"
            className={styles.buttonStyle}
            onClick={clickHandler}
        >
            {showThumbsUp ? (
                <FontAwesomeIcon icon={faThumbsUp} fontSize={15} />
            ) : (
                <Typography variant="text-size-Base-bold">
                    <Translate text="Notify me" />
                </Typography>
            )}
        </Button>
    );
};

export default NotifyMeButton;
