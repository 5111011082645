import { useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { baseContainer } from "./SearchInput.module.scss";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import FullscreenSearchInput from "./FullscreenSearchInput";
import MenuButton from "components/MenuButton/MenuButton";

const HeaderSearchInput = () => {
    const [isOpen, setIsOpen] = useState(false);
    const closeHandler = () => {
        tracker("Search bar closed");
        setIsOpen(false);
    };
    return (
        <div data-testid="baseContainer" className={baseContainer}>
            <MenuButton
                dataTestId={"searchMenuButton"}
                faIcon={faMagnifyingGlass}
                iconColor={variables["text-colors-color-text-gray"]}
                primaryColor={
                    variables["background-colors-color-background-white"]
                }
                secondaryColor={variables["border-colors-color-border-primary"]}
                isOpen={isOpen}
                onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && <FullscreenSearchInput closeHandler={closeHandler} />}
        </div>
    );
};

export default HeaderSearchInput;
