@use "../../../../../styles/variables.module";

.container {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    border-radius: 15px;
    background: variables.$background-colors-color-background-white;
    box-shadow:
        0px 1px 2px 0px rgba(108, 117, 125, 0.24),
        0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.header {
    min-height: 25px;
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    gap: 5px;
}

.headerDragAndQuestion {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.dragHandle {
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    cursor: move;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.answers {
    display: flex;
    padding: 10px 0px 10px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.answer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.editAnswer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
}

.circle {
    min-width: 17px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid variables.$border-colors-color-border-primary;
}

.save {
    height: 25px;
    padding: 5px 10px;
    border-radius: 5px;
}

.cancel {
    height: 25px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid variables.$border-colors-color-border-blue;
}

.markAsCorrect {
    height: 20px;
    padding: 5px 8px;
    border-radius: 5px;
    border: 1px solid variables.$border-colors-color-border-blue;
    background: variables.$background-colors-color-background-light-blue;
}
